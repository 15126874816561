import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import React, {useEffect, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {toast} from "react-toastify";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {send_to_preview} from "../ContestEditorFunctions";
export default function ContestMinAge(props) {
    const {contest,setContest} = props;
    const [min_age,set_min_age] = useState(contest.restrictions.age_verify);

    let ages = [<MenuItem key={0} value={0}>No minimum age</MenuItem>];
    for(let i = 12;i<=30;i++){
        ages.push(<MenuItem  key={i} value={i}>{i} and older</MenuItem>);
    }
    const handleChange = async (event)=>{
        set_min_age(event.target.value);
        if(event.target.value===0)
            event.target.value = false;
        setContest("restrictions.age_verify",event.target.value);
        toast("Minimum age set to "+event.target.value);
    }
    const showPopup = ()=>{
        send_to_preview('show-settings-popup',{popup:'age-verify',action:'show'});
    }
    const hidePopup = ()=>{
        send_to_preview('show-settings-popup',{popup:'age-verify',action:'hide'});
    }
    return (<>

                <FormControl fullWidth={true}>
                    <InputLabel id="min-age-select-label">Minimum age to enter</InputLabel>
                    <Select
                        labelId="min-age-select-label"
                        id="min-age-select"
                        value={min_age}
                        label="Minimum age to enter"
                        onChange={handleChange}
                    >
                        {ages}
                    </Select>
                </FormControl>
                <ButtonGroup fullWidth={true} className={"mt-3"}  variant="outlined" aria-label="outlined primary button group">
                    <Button onClick={showPopup}>Show popup</Button>
                    <Button  onClick={hidePopup}>Hide popup</Button>
                </ButtonGroup>

            <CardActions>
                This will add an age verification to your contest.
            </CardActions>
        </>
    );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton, TwitterIcon, LineIcon, TelegramIcon, LinkedinIcon, EmailIcon, RedditIcon
} from "react-share";


export default function ShareAndEmbed(props) {
    const {contest, setContest} = props;
    const [tab, setTab] = React.useState(0);
    let shareLink = `https://rf3-contest-host.pages.dev/c/${contest.contest_id}`;
    let embedCode = `<iframe src="${shareLink}" style="width: 100%; height: 100%; border: none;"></iframe>`;
    let embedWpCode = `[contest-embed contest_id=${contest.contest_id}]`;
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const clickToCopy = (e) => {
        e.target.select();
        navigator.clipboard.writeText(e.target.value);
        console.log("click to copy");
    }
    return (
        <Card>
            <Divider/>
            <CardContent>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Share URL" {...a11yProps(0)} />
                            <Tab label="Embed Anywhere" {...a11yProps(1)} />
                            <Tab label="Embed WordPress" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tab} index={0}>
                        <TextField id="outlined-basic" className={'w-100'} onClick={clickToCopy} readOnly={true}
                                   label="Click to copy" value={shareLink} variant="outlined"/>
                        <div className={'text-center p-3 share-buttons'}>
                                <FacebookShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>

                                <EmailShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <EmailIcon size={32} round/>
                                </EmailShareButton>

                                <RedditShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <RedditIcon size={32} round/>
                                </RedditShareButton>

                                <TwitterShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>

                                <LinkedinShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <LinkedinIcon size={32} round/>
                                </LinkedinShareButton>

                                <TelegramShareButton
                                    url={shareLink}
                                    quote={"Contest"}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={32} round/>
                                </TelegramShareButton>
                        </div>








                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        Item Three
                    </TabPanel>
                </Box>
            </CardContent>
            <CardActions>
                Contests can be shared and embedded anywhere on the web.
            </CardActions>
        </Card>

    );
}


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


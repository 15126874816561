import {fetcher} from 'itty-fetcher';
export const base_url = 'https://rf3_server.rewardsfuel.workers.dev';//'http://127.0.0.1:8787';//;todo remove when  going to prod
export const base_contest_host = 'https://rf3-contest-host.pages.dev/c/';//'http://localhost:3001/c/';//;//todo remove when  going to prod
//let base_url = process.env.REACT_SAPP_API_HOST;


export const time = () => {
    return Math.floor(Date.now() / 1000);
}
export const log = (subject, data) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(subject, data);
    } else {
        //todo add persistent logging and alerting
    }
}
export const req = async (method, url, data = false) => {


    try {
        let options = {
            base: base_url,
            transformRequest(req) {
                req.headers['Authorization'] =  localStorage.getItem('_auth');//todo improve to use storage with cookie backup
                return req
            },
        }
        if (url?.includes("http"))
            delete (options.base);

        let f = new fetcher(options);
        method = method.toUpperCase();
        if (data)
            return await f[method](url, data).catch((e) => {
                log("itty fetcher error with data", e);
                return false
            });
        else
            return await f[method](url).catch((e) => {
                log("itty fetcher error", e);
                return false
            });
    } catch (e) {
        log("req error", e);
        return false;
    }
}
export const upload = async (url, file,contest_id,use) => {
    if(!url.includes("http"))
        url = base_url + url;
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', url);

    return new Promise(function (resolve, reject) {
        xhr.onload = () => {
            try {
                if (xhr.status === 403) {
                    return ({message: 'HTTP Error: ' + xhr.status, remove: true});
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    return ('HTTP Error: ' + xhr.status);
                }
                const json = JSON.parse(xhr.responseText);
                if (!json || typeof json.location != 'string') {
                    return ('Invalid JSON: ' + xhr.responseText);
                }
                resolve(json);
            } catch (e) {
                reject(false);
            }
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('use', use);
        formData.append('contest_id',contest_id);
        xhr.send(formData);
    });

}
export const form_obj = (form) => {
    const form_data = new FormData(form);
    const form_data_obj = {};
    form_data.forEach((value, key) => (form_data_obj[key] = value));
    return form_data_obj;
}

export class Storage {
    // Constructor function
    constructor() {
        // Empty
    }

    // Method to set a value in local storage with an optional expiration
    set(name, value, expiration) {
        // Set the value in local storage
        localStorage.setItem(name, value);

        // If expiration is specified, set the expiration date
        if (expiration) {
            const parts = expiration.match(/(\d+)([hdm])/);
            const amount = parseInt(parts[1], 10);
            const unit = parts[2];
            let milliseconds;
            if (unit === "h") {
                milliseconds = amount * 60 * 60 * 1000;
            } else if (unit === "d") {
                milliseconds = amount * 24 * 60 * 60 * 1000;
            } else if (unit === "m") {
                milliseconds = amount * 60 * 1000;
            }
            const date = new Date();
            date.setTime(date.getTime() + milliseconds);
            localStorage.setItem(name + "_expiration", date);
        }

        // Set the value in a cookie as a backup
        document.cookie = `${name}=${value}`;
    }

    // Method to get a value from local storage or a cookie if local storage is not available
    get = name => {
        // Get the value from local storage
        let storedValue = localStorage.getItem(name);

        // Check if the value has expired and remove it if it has
        const expirationDate = localStorage.getItem(name + "_expiration");
        if (expirationDate && new Date() > new Date(expirationDate)) {
            localStorage.removeItem(name);
            localStorage.removeItem(name + "_expiration");
            storedValue = null;
        }

        // If local storage is not available or the value is not set, try to get it from a cookie
        if (!storedValue) {
            const cookies = document.cookie.split(";");
            for (const cookie of cookies) {
                const parts = cookie.split("=");
                if (parts[0] === name) {
                    storedValue = parts[1];
                    break;
                }
            }
        }

        // Return the stored value
        return storedValue;
    }
    delete = name => {
        // Delete the value from local storage
        localStorage.removeItem(name);
        localStorage.removeItem(name + "_expiration");

        // Delete the value from a cookie
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    };
}

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import {toast} from "react-toastify";
import {useState} from "react";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import GoogleLogin from "react-google-login";
const C = require("../../CommonLibrary");
export default function SignUpForm(props){
    const {setLoading,theme,setShowSignIn} = props;
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [showMessage, setShowMessage] = useState(false);

    const handleClose = () => {
        setShowMessage(false );
    };


    const HandleSignUp = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form_data_obj = C.form_obj(e.target);
        //test email
        //test passwords match and are over 8 characters

        function isValidEmail(email) {
            return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
        }
        if(!isValidEmail(form_data_obj.email)){
            toast.error("Invalid email address");
            setLoading(false);
            return;
        }
        if(form_data_obj.password.length < 8){
            toast.error("Password must be at least 8 characters");
            setLoading(false);
            return;
        }
        if(form_data_obj.password !== form_data_obj.password_confirm){
            toast.error("Passwords do not match");
            setLoading(false);
            return;
        }
        let response = false;
        try {
            response = await C.req('POST', '/auth/sign_up/', form_data_obj);
        } catch (e) {
            //todo add toast here
            setLoading(false);
            return;
        }
        if(!response.result){
            setLoading(false);
            toast.error(response.message);
            return;
        }
        delete response.result;
        setLoading(false);
        if (signIn({
            token: response.token,
            expiresIn: response.expiresIn,
            tokenType: response.tokenType,
            authState: response.authState,
            refreshToken: response.refreshToken,                    // Only if you are using refreshToken feature
            refreshTokenExpireIn: response.refreshTokenExpireIn
        })) { // Only if you are using refreshToken feature
            navigate("/s/home");
        } else {
            //Throw error
            setShowMessage({
                open: true,
                vertical: 'top',
                horizontal: 'center',
            });
            console.log("signed in error")
        }

    }
    const swap_sign_in = (e) => {
        e.preventDefault();
        setShowSignIn(true);
    }
    const responseGoogle = (response) => {
        console.log(response);
        //todo make this work
    }
    return(<> <Typography component="h1" variant="h5">
        Sign up
    </Typography>
        <Box component="form" noValidate onSubmit={HandleSignUp} >
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                minlenght="8"
                autoComplete="current-password"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password_confirm"
                label="Confirm password"
                type="password"
                minlenght="8"
            />
            <Button
                type="submit"
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                color={"primary"}
            >
                Sign Up
            </Button>
            <GoogleLogin
                clientId="220993552061-i66itbni8dqgjcgbrkh1no8al9ikb2gf.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                fullWidth={true}
                className={"w-100 text-center"}
                cookiePolicy={'single_host_origin'}
                theme={theme.name==='dark'?'dark':'light'}
            />
            <Snackbar
                open={showMessage}
                onClose={handleClose}
                anchorOrigin={{ 'vertical':'top', 'horizontal':'center' }}
                autoHideDuration={6000}
                message="Sorry we could not log you in"
                TransitionComponent={Fade}
            />
            <Grid container>

                <Grid item>
                    <Button onClick={swap_sign_in} variant="body2">
                        Already have an account? Sign in
                    </Button>
                </Grid>
            </Grid>
        </Box></>)
}

import { req } from "../../CommonLibrary";
import MUIDataTable from "mui-datatables";
import { useState,useEffect } from "react";

export default function AccountContestants(props) {
    const [contestants, setContestants] = useState([]);
    //todo update columns after adding contestants
    const columns = ["Name", "Company", "City", "State"];

    const data = [
        ["Joe James", "Test Corp", "Yonkers", "NY"],
        ["John Walsh", "Test Corp", "Hartford", "CT"],
        ["Bob Herm", "Test Corp", "Tampa", "FL"],
        ["James Houston", "Test Corp", "Dallas", "TX"],
    ];

    const options = {
        filterType: 'checkbox',
    };
    const get_contestants = async () => {
        const response = await req("GET", "/account/get_contestants");
        console.log("get_contestants", response);
        setContestants(response);
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            get_contestants();
        }
    }, [])

    return (<>
        {contestants.length > 0 ?
           <MUIDataTable
                title={"Contestants List"}
                data={data}
                columns={columns}
                options={options}/>
            : <>
            <h4>You have no contestants yet.</h4>
            <div>When people enter your contest they will appear here.
                You can then contact these people when you run your next contest or send them contest reminders.</div>
            </>}
    </>);
}

import Grid from "@mui/material/Grid";

export default function ContestSummary(props) {
    const { entries, contestants, otherData } = props;
    const style = {
        width: "100%",
        height: "400px",
        border: "1px solid rgba(81, 81, 81, 1)",
        borderRadius: "4px",
        paddingTop: "1rem",
        margin: "0",
        textAlign: "center",
    };

    function sumCountValues(array) {
        return array.reduce((sum, current) => sum + parseInt(current.count), 0);
    }
    function formatPercentage(float) {
        return <>{(float * 100).toFixed(1)}<sup>%</sup></>;
    }
    let views = sumCountValues(otherData.traffic_over_time);
    let avgEntriesPerContestant =
        contestants.length === 0 ? 0 : parseFloat(entries.length / contestants.length).toFixed(2);
    let entriesPerView = views === 0 ? 0 : entries.length / views;

    return (
        <Grid sx={style} container={true} fullWidth={true} spacing={2}>
            <Grid item={true} xs={12} md={4}>
                <div className={"stats-summary-number"}>{entries.length}</div>
                <div className={"stats-summary-label"}>Entries</div>
            </Grid>
            <Grid item={true} xs={12} md={4}>
                <div className={"stats-summary-number"}>{contestants.length}</div>
                <div className={"stats-summary-label"}>Contestants</div>
            </Grid>
            <Grid item={true} xs={12} md={4}>
                <div className={"stats-summary-number"}>{views}</div>
                <div className={"stats-summary-label"}>Views</div>
            </Grid>
            <Grid item={true} xs={6}>
                <div className={"stats-summary-number"}>{avgEntriesPerContestant}</div>
                <div className={"stats-summary-label"}>Avg entries / contestant</div>
            </Grid>
            <Grid item={true} xs={6}>
                <div className={"stats-summary-number"}>{formatPercentage(entriesPerView)}</div>
                <div className={"stats-summary-label"}>Entries/Views</div>
            </Grid>
        </Grid>
    );
}

import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import SignInForm from "../components/SignIn/SignInForm";
import SignUpForm from "../components/SignIn/SignUpForm";

export default function SignInSide(props) {
    const {theme} =  props;
    const [showSignIn, setShowSignIn] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = "Sign In to Rewards Fuel";
    },[]);
    return (

            <Grid container component="main"  sx={{ minHeight: '90vh' }}>
                <Grid
                    item
                    xs={0}
                    sm={0}
                    md={7}
                    /*sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}*/
                />
                <Grid item xs={12} sm={12} md={5} theme={theme} elevation={6}  position={"relative"}>
                    <Paper className={"pt-2 pb-2"}>
                    <Box  sx={{
                            padding:'1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color:'theme.primary'
                        }}>
                        {loading && (
                            <Backdrop
                                sx={{ color:  theme.primary , zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>)}
                        <Avatar sx={{ bgcolor: 'theme.primary' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        {showSignIn ?
                            <SignInForm setShowSignIn={setShowSignIn} loading={loading} setLoading={setLoading} theme={theme}/>:
                            <SignUpForm  setShowSignIn={setShowSignIn} loading={loading} setLoading={setLoading} theme={theme}/>}

                    </Box>
                    </Paper>
                </Grid>
            </Grid>

    );
}

import {useEffect,  useState} from "react";
import GoogleMap from "google-maps-react-markers";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import {DataGrid} from "@mui/x-data-grid";

export default function StatsMap(props) {
    const {geo_data} = props;
    const apiKey = "AIzaSyAFrjiZUcJdVgXyg2YfnkaDH5P3I3SY_wk";
    const [locations, setLocations] = useState([]);
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [zoom, setZoom] = useState(0);
    const [arrayOfMarkers, setArrayOfMarkers] = useState([]);
    const columns = [

        {field: 'country', headerName: 'Country', minWidth: 30, flex: .25},
        {field: 'region', headerName: 'Region', minWidth: 30, flex: .25},
        {field: 'city', headerName: 'City', minWidth: 30, flex: .25},
        {field: 'count', headerName: 'Count', minWidth: 30, flex: .25},
    ];
    let rows = geo_data.map((location, index) => ({
        id: index,
        country: location.country,
        region: location.region,
        city: location.city,
        count: parseInt(location.count),
    }));
    const getCoordinates = async () => {
        const coordinatesPromises = geo_data.map(async (location) => {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    `${location.city}, ${location.region}, ${location.country}`
                )}&key=${apiKey}`
            );
            const result = await response.json();
            return result.results[0].geometry.location;
        });
        const coordinates = await Promise.all(coordinatesPromises);
        setLocations(
            geo_data.map((location, index) => ({
                ...location,
                lat: coordinates[index].lat,
                lng: coordinates[index].lng,
            }))
        );
    };
    useEffect(() => {
        getCoordinates();
    }, [geo_data]);
    //todo add map clustering in https://github.com/istarkov/google-map-clustering-example
    // https://github.com/giorgiabosello/google-maps-react-markers
    useEffect(() => {
        let markers = locations.map((location, index) => (
            <PersonPinIcon className={'map-icon'} key={index} lat={location.lat} lng={location.lng} markerId={location.count} />
        ));
        setArrayOfMarkers(markers);
    }, [locations]);
    useEffect(() => {
        if (locations.length > 0) {
            // Calculate the minimum and maximum latitude and longitude values of the markers
            const minLat = Math.min(...locations.map((location) => location.lat));
            const maxLat = Math.max(...locations.map((location) => location.lat));
            const minLng = Math.min(...locations.map((location) => location.lng));
            const maxLng = Math.max(...locations.map((location) => location.lng));

            // Calculate the center of the markers
            const centerLat = (minLat + maxLat) / 2;
            const centerLng = (minLng + maxLng) / 2;

            // Calculate the zoom level needed to see all the markers
            const latDelta = maxLat - minLat;
            const lngDelta = maxLng - minLng;

            // Make sure latDelta and lngDelta are not 0, otherwise the map will not be displayed
            const zoomed = latDelta === 0 || lngDelta === 0 ?
                Math.max(Math.log(360 / 0.1) / Math.LN2, Math.log(640 / 0.1) / Math.LN2) :
                Math.min(
                    Math.log(360 / latDelta) / Math.LN2,
                    Math.log(640 / lngDelta) / Math.LN2
                );

            setCenter({lat: centerLat, lng: centerLng});
            setZoom(Math.floor(zoomed));
        }
    }, [locations]);
    //const mapRef = useRef(null);

    /**
     * @description This function is called when the map is ready
     * @param {Object} map - reference to the map instance
     * @param {Object} maps - reference to the maps library
     */


    if(zoom === 0){
        return<>Loading map...</>
    }
    return (
        <>
            <div style={{minHeight: 310, width: '100%'}}>
                <GoogleMap
                    className={'rounded-map'}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    apiKey={apiKey}
                    mapMinHeight="300px"
                    onChange={(map) => console.log("Map moved", map)}
                >
                    {arrayOfMarkers}
                </GoogleMap>

            </div>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}

                />
            </div>
        </>
    );
};


import { DataGrid } from '@mui/x-data-grid';
import {useEffect,useState} from "react";
import {req} from "../../../CommonLibrary";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


export default function Lists(props) {
    const {contest} = props;
    //const [columns,setColumns] = useState([]);
    //let rows = [];
    const [rows,setRows] = useState([]);
    const [originalRows,setOriginalRows] =useState([]);
    //list is all events
    const columns = [
        { field: 'data', headerName: 'All data',minWidth: 150,flex: 1 },

    ];
    const [searched, setSearched] = useState("");


    const requestSearch = (event) => {
        let searchval = event.target.value;
        setSearched(searchval);
        const filteredRows = rows.filter((row) => {
            if(row.data.toLowerCase().includes(searchval.toLowerCase()))
            return row
        });
        setRows(filteredRows);
    }
    const cancelSearch = () => {
        setSearched("");
        setRows(originalRows);
    };
    const populate_list = async function() {
        let data = await req("get","/contest/stats_get/?type=list&contest_id="+contest.contest_id);
        data = data.map((elm,ind) => {
            elm.id = ind;
            elm.data = JSON.stringify(elm)
            return elm;
        });
        setOriginalRows(data);
        setRows(data);
        //setLoading(false);
    }
    let statsMounted = false;
    useEffect( () => {
        if(statsMounted)
            return
        statsMounted = true;
        populate_list();
    },[])

    return(  <div  style={{ height: 700, width: '100%' }} >
        <div className={'table-name'}>Raw log data</div>
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={8}>
                <TextField id="outlined-basic" label="Search" variant="outlined"
                    value={searched}
                           fullWidth={true}
                    onChange={(searchVal) => requestSearch(searchVal)}
                />
                </Grid>
            <Grid item={true} xs={12} sm={4}>
                <Button variant="outlined" color="primary" onClick={cancelSearch}>Reset</Button>
            </Grid>
        </Grid>
        {rows.length && <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
        />}
    </div>)
}

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import * as React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import {send_to_preview} from "../../ContestEditorFunctions";

export default function ElementDraggableAccordion(props) {
    const {
        contest,
        setContest,
        id,
        index,
        title,
        elementToShowHide,
        content,
        elementToFocus
    } = props;
    const onSwitchChange = async (event, element) => {
        //setHideTitle(event.target.checked);
        setContest(element, event.target.checked);
        event.stopPropagation();
    }
    const setIframeFocus = (element,focus) => {
        console.log("get position of element from iframe so we know if we need to scroll in parent")
        send_to_preview("editor-focus",{element:element,focus:focus});
    }
    return (<Accordion
        data-index={index}
        key={id}
        onChange={(event, isExpanded) => {setIframeFocus(elementToFocus,isExpanded)}}
    >
        <AccordionSummary
            data-index={index}
            aria-controls={`${id}bh-content`}
            id={`${id}bh-header`}
            expandIcon={<ExpandMoreIcon/>}

        >
            <Typography sx={{width: "7%", flexShrink: 0}}>
                <Typography component="div">
                    <DragHandleIcon
                        data-movable-handle
                        style={{cursor: "s-resize"}}
                    />
                </Typography>
            </Typography>
            <Typography sx={{width: "62%", flexShrink: 0}}>{title}</Typography>
            <div sx={{width: "30%", flexShrink: 0}} onClick={(e)=>e.stopPropagation()} >
                <FormGroup>
                    <FormControlLabel onChange={(e) => {
                        onSwitchChange(e, elementToShowHide); e.preventDefault();
                    }} control={<Switch  checked={contest[elementToShowHide]}/>} label={"Hide"}/>
                </FormGroup>
            </div>
        </AccordionSummary>
        <AccordionDetails  >
            {content}
        </AccordionDetails>
    </Accordion>);
}

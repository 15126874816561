import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import  { useEffect, useState } from 'react';
import {req} from "../../CommonLibrary";
import { Button } from '@mui/material';
let options = false
export default function CreditCard(props) {
    const [creditCardReady, setCreditCardReady] = useState(false);
    const handleSubmit = async (event) => {
        /*
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://example.com/order/123/complete",
          },
        });
    
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.log(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
        */
      };
    
    const stripePromise = loadStripe('pk_test_ppOV92JHNpq1sjx4ehhVeTmm');
    let creditCardMounted = false;
    const getClicentSecret = async () => {
        const response = await req("GET","/payments/create_stripe_client_secret");
        console.log("getClicentSecret",response);
        options = {clientSecret: response.client_secret};
        setCreditCardReady(true);
    }
    useEffect(() => {
        if (creditCardMounted) {
            return;
        }
        creditCardMounted = true;
        getClicentSecret();

    }, [])
    if(options ===false)
        return <>Loading...</>;
    return (<Elements stripe={stripePromise} options={options}>
        <form  onSubmit={handleSubmit}>
            {/* todo if not logged in ask for email and implicity create or sign in to account */}
            {/* todo reduce fields as much as possible */}
            <PaymentElement />
            <Button className="mt-2 w-100" variant="outlined" color="primary">Upgrade</Button>
        </form>
    </Elements>
    );
}
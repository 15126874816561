import React, {useEffect} from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from "recharts";

export default function VisitorsOverTime(props) {
    const {entries,otherData} = props;
    const traffic_over_time = otherData.traffic_over_time;

    const [chartData, setChartData] = React.useState([]);
    let entriesAndVisits = addEntries(entries, traffic_over_time);
    function formatData(data) {
        // Create a map to store the counts and entries for each date
        const countsByDate = new Map();
        data.forEach(({ dateHour, count, entries = 0 }) => {
            // Extract the date from the timestamp
            const date = new Date(dateHour).toISOString().slice(0, 10);
            // If the date is already in the map, add the count and entries to the existing values
            // Otherwise, add the date to the map with the count and entries as the values
            if (countsByDate.has(date)) {
                countsByDate.set(date, {
                    visits: countsByDate.get(date).visits + Number(count),
                    entries: countsByDate.get(date).entries + Number(entries),
                });
            } else {
                countsByDate.set(date, { visits: Number(count), entries: Number(entries) });
            }
        });
        // Convert the map to an array of objects and sort by date
        return [...countsByDate].map(([date, { visits, entries }]) => ({ date, visits, entries })).sort((a, b) => a.date.localeCompare(b.date));
    }


    function addEntries(entries, array) {
        return [...array, ...entries.map((entry) => ({ dateHour: entry.time_of_entry, count: 0, entries: 1 }))];
    }
    const [width, setWidth] = React.useState(0);
    let mounted = false;
    useEffect(() => {
        if(mounted)return;
        mounted = true;
        let formattedData =  formatData(entriesAndVisits);
        setChartData(formattedData);
        setWidth(document.getElementById('stats-over-time').offsetWidth-16);
    },[]);
    return (
        <div>

                <AreaChart
                    width={width}
                    height={200}
                    data={chartData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <Tooltip/>
                    <Area type="monotone" dataKey="visits" name={"Visitors"} stroke="#000000" fill="#333333"/>
                    <Area type="monotone" dataKey="entries" name={"Entries"} stroke="#444444" fill="#222222"/>
                </AreaChart>

        </div>
    );
}

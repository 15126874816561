import React from "react";
import ContestTimer from "./settings/ContestTimer";
import ContestLanguages from "./settings/ContestLanguages";
import ContestMinAge from "./settings/ContestMinAge";
import ContestTermsFirst from "./settings/ContestTermsFirst";
import ContestLocations from "./settings/ContestLocations";
import ShareAndEmbed from "./settings/ShareAndEmbed";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import ShareIcon from '@mui/icons-material/Share';
import PolicyIcon from '@mui/icons-material/Policy';
import TranslateIcon from '@mui/icons-material/Translate';
import KeyIcon from '@mui/icons-material/Key';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WinnersSettings from "./settings/WinnersSettings";
export default function ContestSettingsAccordion(props) {
    const [expanded, setExpanded] = React.useState(false);
    const {contest} = props;
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                   <Typography sx={{ width: '66%', flexShrink: 0 }}>
                        Start &amp; end dates of contest
                    </Typography>
                    <Typography  sx={{ color: 'text.secondary', width: '33%', flexShrink: 0,paddingRight:'1rem' }} align="right"><span className={"mobile-hidden"}>{new Date(contest.end_time).toLocaleDateString()} </span><AccessTimeIcon/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContestTimer {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>Locations</Typography>
                    <Typography sx={{ width:'33%', color: 'text.secondary',paddingRight:'1rem' }} align="right">
                        <span className={"mobile-hidden"}>Allow or block </span><PublicIcon/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContestLocations {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>
                       Language
                    </Typography>
                    <Typography align="right" sx={{ width:'33%',color: 'text.secondary', textTransform: 'capitalize',paddingRight:'1rem' }}>
                        {contest.language} <TranslateIcon/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContestLanguages {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>Contest URL &amp; sharing</Typography>
                    <Typography align="right" sx={{ width:'33%',color: 'text.secondary' ,paddingRight:'1rem'}}>
                        <ShareIcon/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ShareAndEmbed {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>Age restrictions</Typography>
                    <Typography align={"right"} sx={{ width:'33%',color: 'text.secondary' ,paddingRight:'1rem'}}>18+ <KeyIcon/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContestMinAge {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>Terms and conditions</Typography>
                    <Typography align={"right"}  sx={{ color: 'text.secondary', width: '33%', flexShrink: 0,paddingRight:'1rem' }} justify="flex-end"><PolicyIcon/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContestTermsFirst {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '66%', flexShrink: 0 }}>Winners settings</Typography>
                    <Typography align={"right"}  sx={{ color: 'text.secondary', width: '33%', flexShrink: 0,paddingRight:'1rem' }} justify="flex-end"><EmojiEventsIcon/></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <WinnersSettings {...props}/>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

import {Editor} from "@tinymce/tinymce-react";
import React from "react";
import { useTheme } from '@mui/material/styles';
export default function ContestWysiwyg(props){
    const {initial_value,change_handler,contest_id} = props;
    const handleEditorChange = (event, editor) => {
        if(change_handler!==undefined)
            change_handler(editor.getContent());
    }
    const image_upload_handler = (blobInfo, progress) => new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', "https://router.rewardsfuel.workers.dev/contest/upload/"); //todo update to final url
        xhr.onload = () => {
            if (xhr.status === 403) {
                reject({message: 'HTTP Error: ' + xhr.status, remove: true});
                return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                reject('HTTP Error: ' + xhr.status);
                return;
            }

            const json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != 'string') {
                reject('Invalid JSON: ' + xhr.responseText);
                return;
            }

            resolve(json.location);
        };

        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        formData.append('use', "description");
        formData.append('contest_id', contest_id);
        xhr.send(formData);
    });
    const theme = useTheme();
    let skin = 'oxide';
    let content_css = 'light';
    if(theme.name==='dark'){
        skin = 'oxide-dark';
        content_css = 'dark';
    }
    return(
        <Editor
            apiKey={`82dohfal8hpot222bvny8fpcrudq2gro5gqfqhkcixjsqpua`}
            initialValue={initial_value}
            onChange={handleEditorChange}
            init={{
                height: 300,
                menubar: true,
                config: {},
                skin: skin,
                content_css: content_css,
                images_upload_handler: image_upload_handler,
                images_upload_credentials: true,
                plugins: [
                    'image',
                    'link',
                    'fullscreen',
                    'table'
                ],
                toolbar:
                    `undo redo| link code image |  bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat |`,
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image'
            }}

        />
    );
}

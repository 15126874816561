//import axios from 'axios'
import {createRefresh} from 'react-auth-kit'
import {req} from './CommonLibrary'
const refreshApi = createRefresh({
    interval:10,   // Refreshes the token in every 5 minutes
    refreshApiCallback: async (
        {
            authToken,
            authTokenExpireAt,
            refreshToken,
            refreshTokenExpiresAt,
            authUserState
        }) => {
        let url = `/auth/refresh/`;
        let data = await req("POST",url, {
            refreshToken: refreshToken,
            oldAuthToken: authToken
        });
        if(data.isSuccess) {
            return {
                isSuccess: true,  // For successful network request isSuccess is true
                newAuthToken: data.newAuthToken,
                newAuthTokenExpireIn: data.newAuthTokenExpireIn
                // You can also add new refresh token ad new user state
            }
        }else{
            console.log("refresh failed");
            return {
                isSuccess: false,  // For failed network request isSuccess is false
                error: data.error
            }
        }
    }
})

export default refreshApi

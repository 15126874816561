import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';

export default function Referrers(props) {
    const {referrer_data} = props;
    const renderReferralLink = (params) => {
        let referrer = params.row.pageReferrer;
        function isValidUrl(str) {
            try {
                new URL(str);
                return true;
            } catch (error) {
                return false;
            }
        }
        if(isValidUrl(referrer))
            return <a href={referrer} target="_blank" className="table-ellipsis">{referrer}</a>
        return (<>referrer</>);

    }
    const columns = [
        {field: 'pageReferrer', headerName: 'URL', minWidth: 70, flex: .8,renderCell:renderReferralLink},
        {field: 'count', headerName: 'Referrals', minWidth: 70, flex: .2},
    ];

    let rows = referrer_data.map((referrer, index) => {
        //todo add in Facebook multiple urls etc
        return {
            id: index,
            pageReferrer: referrer.pageReferrer===''?'Direct':referrer.pageReferrer,
            count: referrer.count,
        }
    });

    return (
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}

            />
        </div>
    );

}

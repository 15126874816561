import Button from "@mui/material/Button";
import React, {useState} from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import { send_to_preview } from '../../ContestEditorFunctions';
import { CheckBox } from "@material-ui/icons";

export default function Colors(props){
    const{colors, setColors,contest,setContest} = props;
    const [selectedTheme, setSelectedTheme] = useState(contest.layout.use_color);

    const setTheme = async (themeId) => {
        //const themeId = event.target.dataset.theme;
       // let theme = colors.find((color) => parseInt(color.id) === parseInt(themeId));
        //console.log("set color theme",theme,themeId,colors);
        await setContest('layout.custom_colors', 0);
        await setContest('layout.use_color', themeId);
        let tempContest = contest;//because the update can take time
        tempContest.layout.custom_colors = 0;
        tempContest.layout.use_color = themeId;
        setSelectedTheme(themeId);
        await send_to_preview('update-css',tempContest);
        //send to preview frame
    }
    const previewTheme= async(event)=>{
        const themeId = event.target.dataset.id;
        await send_to_preview('preview-color',themeId);
    }
    const favoriteTheme = (theme) => {

    }
    const columns = [
        {
            field: 'name', headerName: 'Colors', minWidth: 100,flex: 1,
            renderCell: (value) => {
                return (<>
                <Button onMouseEnter={previewTheme}  data-id={value.row.id} className={'w-100'}>
                <img  className={'color-preview-img'} src={value.row.image}/>
                <span>{value.row.name}</span>
                <span> {selectedTheme === value.row.id && <CheckBox/>}</span>
                </Button>
                </>);
            }
        }
    ]

    return (<>
        Update the colors, hover to preview, click to use it.
        <Box sx={{height: 400, width: '100%'}}>
            <DataGrid
                rows={colors}
                columns={columns}
                onRowClick={(event) => {
                    console.log("row click",event,event.row);
                    setTheme(event.row.id)
                }}
                componentsProps={{
                    row: {
                      onContextMenu: previewTheme
                    },
                  }}
                onCellEnter={(event) => {
                    console.log("row hover",event,event.row);
                    previewTheme(event.row.id)
                }}
            />
        </Box>
    </>);
    return(<>
        <h4>Colors</h4>
        <p>You may choose a pre-existing color pallet, use it and customize it with your own CSS.</p>
    </>);
}
import { PayPalButton } from "react-paypal-button-v2";

export default function PayPal() {
    //todo pass in plan id to get PayPal Plan
    let options = {
        'client-id': 'AXJ5WS2dwCQ3cr2OrNseJBI5BEBKPiTIOrjIxprcm8skmlvwYgQomc_yXZMV5oYKhyIc3M2RXVb1Khme',
        'vault': true
    }
    return (
        <PayPalButton
        options={options}
        style={{ 'layout': 'horizontal', 'size': 'responsive', 'color': 'blue'}}
          createSubscription={(data, actions) => {
            //todo create plans
            return actions.subscription.create({
              plan_id: 'P-9VT77248BD1788829MPNLPXI'
            });
          }}
          onApprove={(data, actions) => {
            // Capture the funds from the transaction
            return actions.subscription.get().then(function(details) {
              // Show a success message to your buyer
              alert("Subscription completed");
  
              // OPTIONAL: Call your server to save the subscription
              return fetch("/paypal-subscription-complete", {
                method: "post",
                body: JSON.stringify({
                  orderID: data.orderID,
                  subscriptionID: data.subscriptionID
                })
              });
            });
          }}
        />
      );
 
}

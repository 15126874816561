import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import GoogleLogin from 'react-google-login';
import {toast} from "react-toastify";
import {useState} from "react";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
const C = require("../../CommonLibrary");
export default function SignInForm(props){
    const {setShowSignIn, setLoading,theme} = props;
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [showMessage, setShowMessage] = useState(false);
    const [email, setEmail] = useState('');
    const handleClose = () => {
        setShowMessage(false );
    };

    const HandleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form_data_obj = C.form_obj(e.target);
        try {
            let return_data = await C.req('POST', '/auth/sign_in/', form_data_obj);
            handle_login_response(return_data);
        } catch (e) {
            //todo add toast here
            setLoading(false);
            return;
        }


    }
    const handle_login_response = (response) => {
        if(!response.result){
            setLoading(false);
            toast.error(response.message);
            return;
        }
        delete response.result;
        setLoading(false);
        if (signIn({
            token: response.token,
            expiresIn: response.expiresIn,
            tokenType: response.tokenType,
            authState: response.authState,
            refreshToken: response.refreshToken,                    // Only if you are using refreshToken feature
            refreshTokenExpireIn: response.refreshTokenExpireIn
        })) { // Only if you are using refreshToken feature
            window.location.href ="/s/home";
        } else {
            //Throw error
            setShowMessage({
                open: true,
                vertical: 'top',
                horizontal: 'center',
            });
            console.log("signed in error")
        }
    }
    const forgotPass = async(e) => {
        e.preventDefault();
        if(!email.length){
            toast("Please enter your email address");
            return;
        }
        let result = await C.req('POST', '/auth/forgot_password/', {email: email});
        if(result.result){
            toast("Password reset email sent");
        }
    }
    const swap_sign_in = (e) => {
        e.preventDefault();
        setShowSignIn(false);
    }
    const responseGoogle = async (response) => {
        setLoading(true);
        let return_data = await C.req('POST', '/auth/handle_google_login/', response);
        handle_login_response(return_data);
    }
    return(<> <Typography component="h1" variant="h5">
        Sign in
    </Typography>
    <Box  component="form" noValidate onSubmit={HandleLogin} >
        <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            autoFocus
        />
        <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
        />

        <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            color={"primary"}
        >
            Sign In
        </Button>
        <GoogleLogin
            clientId="220993552061-i66itbni8dqgjcgbrkh1no8al9ikb2gf.apps.googleusercontent.com"
            buttonText="Sign in with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            fullWidth={true}
            className={"w-100 text-center"}
            cookiePolicy={'single_host_origin'}
            theme={theme.name==='dark'?'dark':'light'}
        />
        <Snackbar
            open={showMessage}
            onClose={handleClose}
            anchorOrigin={{ 'vertical':'top', 'horizontal':'center' }}
            autoHideDuration={6000}
            message="Sorry we could not log you in"
            TransitionComponent={Fade}
        />
        <Grid container>
            <Grid item xs>
                <Button onClick={forgotPass} variant="body2">
                    Forgot password?
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={swap_sign_in} variant="body2">
                    Don't have an account? Sign Up
                </Button>
            </Grid>
        </Grid>
    </Box></>)
}

import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import React, {useEffect, useState} from "react";
import Location_auto_complete from "./Location_auto_complete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {set_contest_item} from "../ContestEditorFunctions";
import Button from "@mui/material/Button";

export default function ContestLocations(props) {
    const {contest,setContest} = props;
    let initial_action = contest?.restrictions?.geo?.allow_or_block || "blocked";
    let initial_locations = contest?.restrictions?.geo?.locations || [];
    const [selected_locations,set_selected_locations] = useState(initial_locations);
    const [locations_to_save,set_locations_to_save] = useState(false);
    const [blocker_action,set_blocker_action] = useState(initial_action);//todo update on  load
    const saveGeoRestrictions = async (e) => {
        e.preventDefault();
        let structured_restrictions = {
            "allow_or_block": blocker_action,
            "locations": selected_locations
        }
        await setContest("restrictions.geo",structured_restrictions)
        set_locations_to_save(false);
    }
    const handle_blocker_change = (e) => {
        set_locations_to_save(true);
        set_blocker_action(e.target.value);
    }
    useEffect(() => {
        set_locations_to_save(true);
    },[selected_locations])
    return (<><Card>

            <Divider/>
            <CardContent>
                <FormControl className={"mb-3"} fullWidth={true}>
                    <InputLabel id="geo-blocker-action">Visitors from the following will be:</InputLabel>
                    <Select
                        labelId="geo-blocker-action"
                        value={blocker_action}
                        label="Visitors from the following will be:"
                        onChange={handle_blocker_change}
                    >
                        <MenuItem value={"allowed"}>Allowed</MenuItem>
                        <MenuItem value={"blocked"}>Blocked</MenuItem>
                    </Select>
                </FormControl>
               <Location_auto_complete set_selected_locations={set_selected_locations} selected_locations={selected_locations} {...props}/>

                {locations_to_save &&
                <Button  fullWidth={true} type={'button'}  variant={'outlined'} color={'primary'}   onClick={saveGeoRestrictions}>Save</Button>
                }
            </CardContent>

            <CardActions>
                <div><b>Block</b> or <b>Allow</b> specific locations to enter your contest. Locations are based on IP address, larger areas like countries, states/provinces are more accurate than cities.</div>
            </CardActions>
        </Card></>
    );
}

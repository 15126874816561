import { Button } from "@mui/material";
import PaymentsModal from "../../components/Payments/PaymentsModal";
export default function PaymentsTab(props) {
    //display current plan as well as sign up and cancel buttons
    return (<>
    <h3>Payments & plans</h3>
    <p>Your current plan is free</p>
    <Button variant="contained" color="primary" className="payment-modal-open" data-plan="2">Upgrade</Button>
    <Button variant="contained" color="secondary" href="/s/account/payments">Cancel</Button>
    <PaymentsModal />
    </>);
}
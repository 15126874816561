import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Slider from '@mui/material/Slider';
import SquareIcon from '@mui/icons-material/Square';
import CircleIcon from '@mui/icons-material/Circle';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import FormGroup from "@mui/material/FormGroup";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

let style_object = false;
let style_changes = false;

export function EntryMethods(props) {
    const {contest, setContest} = props;
    const [borderRadius, setBorderRadius] = React.useState(contest.layout?.entry_method_style?.border_radius || .25);
    const [fontSize, setFontSize] = React.useState(contest.layout?.entry_method_style?.font_size || 1);
    const [showIcon, setShowIcon] = React.useState(contest.layout?.entry_method_style?.show_icon || 1);
    const [showLabel, setShowLabel] = React.useState(contest.layout?.entry_method_style?.show_label || 1);
    const [showPoints, setShowPoints] = React.useState(contest.layout?.entry_method_style?.show_points || 1);
    const [defaultSettings, setDefaultSettings] = React.useState(true);
    //updates contest.layout.entry_method_style = false is just use CSS
    let default_style_object = {//todo pull in value from contest.layout.entry_method_style
        font_size: 1,
        border_radius: 4,
        show_icon: true,
        show_points: true,
        show_label: true,
    }
    setInterval(() => {
        if (style_changes) {
            style_changes = false;
            setDefaultSettings(0);
            setContest('layout.entry_method_style', style_object);
        }
    }, 2000)
    const updateStyle = (name, value) => {
        style_object[name] = value;
        //SetContest('layout.entry_method_style', new_style);
        setContest('layout.entry_method_style', style_object);
        setDefaultSettings(0);
    }
    const handleRadiusChange = (event, newValue) => {
        setBorderRadius(newValue);
        style_object.border_radius = newValue;
        style_changes = true;

        //todo update updateStyle
    };
    const handleFontChange = (event, newValue) => {
        setFontSize(newValue);
        style_object.font_size = newValue;
        style_changes = true;
    };
    const handleSwitchChange = (event) => {
        if (event.target.name === 'show_icon') {
            setShowIcon(event.target.checked);
        }
        if (event.target.name === 'show_label') {
            setShowLabel(event.target.checked);
        }
        if (event.target.name === 'show_points') {
            setShowPoints(event.target.checked);
        }
        updateStyle(event.target.name, event.target.checked);
    };
    const resetStyles = () => {
        setContest('layout.entry_method_style', false);
        style_object = false;
        style_changes = false;
        style_object = default_style_object;
        setShowPoints(true);
        setShowLabel(true);
        setShowIcon(true);
        setFontSize(1);
        setBorderRadius(.25);
        setDefaultSettings(1);
    }
    let mounted = false
    useEffect(() => {
        if (mounted) return;
        mounted = true;
        style_object = default_style_object;
        if (contest.layout?.entry_method_style) {
            style_object = contest.layout.entry_method_style;
            setDefaultSettings(0);
        }
    }, []);
    return (
        <Grid container={true} spacing={2} >
            <Grid item={true} xs={6}>
                <div className={"text-center p-2"}>Button radius</div>
                <Grid container={true}>
                    <Grid item={true} xs={1}><SquareIcon/></Grid>
                    <Grid item={true} xs={10}><Slider onChange={handleRadiusChange} value={borderRadius} step={.125}
                                                      min={0} max={7} value={borderRadius} aria-label="Default"
                                                      valueLabelDisplay="Border-radius"/></Grid>
                    <Grid item={true} xs={1}><CircleIcon/></Grid>
                </Grid>
            </Grid>
            <Grid item={true} xs={6}>
                <div  className={"text-center p-2"}>Font size</div>
                <Grid spacing={2} container={true}>
                    <Grid item={true} xs={1}><TextDecreaseIcon/></Grid>
                    <Grid item={true} xs={10}><Slider onChange={handleFontChange} min={.5} step={.1} max={5}
                                                      value={fontSize}
                                                      aria-label="Default"
                                                      valueLabelDisplay="Font size"/></Grid>
                    <Grid item={true} xs={1}><TextIncreaseIcon/></Grid>
                </Grid>
            </Grid>
            <Grid item={true} className={"pt-4"} xs={4}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch name={"show_icon"} checked={showIcon} onChange={handleSwitchChange}/>}
                        label="Show icon"/>
                </FormGroup>
            </Grid>
            <Grid item={true} className={"pt-4"} xs={4}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch name={"show_label"} checked={showLabel} onChange={handleSwitchChange}/>}
                        label="Show label"/>
                </FormGroup>
            </Grid>
            <Grid item={true} className={"pt-4"} xs={4}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch name={"show_points"} checked={showPoints} onChange={handleSwitchChange}/>}
                        label="Show points"/>
                </FormGroup>
            </Grid>
            {!defaultSettings &&
                <Grid className={"pt-4 pb-2"} item={true} xs={12}><Button fullWidth={true} onClick={resetStyles}
                                                                          variant={"outlined"}>Reset styles</Button>
                </Grid>}
        </Grid>);
}

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {req} from "../../CommonLibrary";
import {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";





export default function Kv(props) {
    const searchServer = (e) => {
        let prefix = document.getElementById("prefix").value;
        setRows([]);
        get_keys(prefix);
        //update data from tank object
    }
    const [rows,setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const get_keys = async (prefix) => {
        if(!prefix)
            prefix = '';
        let result = await req("GET","/tank/get_kv/?prefix="+prefix);
        const updatedArray = result.map((item, index) => {
            return { id: index, ...item };
        });
        setRows(updatedArray);
    }
    const deleteSelected = async () => {
        if(!window.confirm("Sure?"))
            return;
        let keys = rows.filter((item) => selectedRow.includes(item.id));
        let data = {delete: keys};
        setRows([])
        await req("POST","/tank/delete_kv/",data);
        get_keys();
    }
    const columns = [
        {
            field: 'key',
            headerName: 'Key',
            minWidth: 50,
            flex: .2,
        },
        {
            field: 'data',
            headerName: 'Value',
            minWidth: 350,
            flex: .8,
            editable: true,
        }];



    let mounted = false;
    useEffect(() => {
        if(mounted)return;
        mounted = true;
        get_keys();

    }, []);

    return (<Grid spacing={2} container={1}>
        <Grid item={1} xs={10}>
            <TextField size={'small'} fullWidth={1} id="prefix" label="Search server keys" variant="outlined"/>
        </Grid>
        <Grid item={1} xs={2}>
            <Button fullWidth={1} size='large' onClick={searchServer} variant={'contained'}>Search</Button>
        </Grid>
    <Grid item={1} xs={12}>
        <Button f size='large' onClick={deleteSelected} variant={'contained'}>Delete</Button>
        <div style={{ height: 500, width: '100%' }}>
        {rows.length >0 &&<DataGrid
            title={"KV List"}
            rows={rows}
            columns={columns}
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel,details,c) => {
                setSelectedRow(newSelectionModel);
            }}
            checkboxSelecFeatures={{ newEditingApi: true }}
            onCellEditCommit={async (params, event) => {
                let update = {
                    key: params.row.key,
                    value: params.value
                };
                let confirmed = window.confirm("update "+update.key+" to "+update.value+"?");
                if(confirmed){
                    await req("POST","/tank/update_kv",update);
                    get_keys();
                }
            }}
        />}
        </div>
    </Grid>
</Grid>)
}

const C = require('../../CommonLibrary');
let contest = false;
let updatesAllowed = false;
setTimeout(()=>{updatesAllowed=true;},3000);
export const baseUrl = "http://localhost:3001";
async function init_set_contest(contest_id){
    let get_url = `/contest/get_contest/?contest_id=${contest_id}`;
    contest = await C.req("GET",get_url);
    return contest;
}
export async function get_contest(contest_id) {
    if(contest !== false)
        return contest;
    return await init_set_contest(contest_id);
}
export const send_to_preview=(topic,message)=>{
    try {
        let iframe = document.getElementById('preview-frame');
        let frame_message = {type: topic, payload: message};
        //console.log("send_to_preview",topic,message);
        iframe.contentWindow.postMessage(frame_message, "*");
    }catch (e) {

    }
}
export async function set_contest_item(prop, value,contest,update_server=true) {
    //should only being getting called from ClassicContest setContest function
    if(typeof contest !== 'object'){
        alert("something is calling set_contest_item without passing contest object, check console");
        console.log("something is calling set_contest_item without passing contest object",prop, value,contest);
    }
    if(prop===undefined || value===undefined)
        return;
    //console.log("CONTEST EDITOR FUNCTIONS set_contest_item",prop,value);
    const setProperty = (obj, path, value) => {
        const [head, ...rest] = path.split('.')
        return {
            ...obj,
            [head]: rest.length
                ? setProperty(obj[head], rest.join('.'), value)
                : value
        }
    }
    let new_contest = contest;
    if(typeof prop === "string")//updating single property
        new_contest = setProperty(contest,prop,value);
    else{//updating multiple properties
        for(let i=0;i<prop.length;i++){
            new_contest = setProperty(new_contest,prop[i],value[i]);
        }
    }
    if(new_contest !== false)
        send_to_preview('update-preview',new_contest);
    if(update_server && updatesAllowed) {//only entry methods doesn't need to update server
        let update_url = `/contest/set_contest/`;
        new_contest = await C.req("POST", update_url, new_contest);
    }
    return new_contest;
}
export async function set_contest_tab(tab,content,body=false){
    let data = {
        html:content,
        body,
        tab
    };
    send_to_preview('set-tab-content',data);
}
export async function set_contest_content(contest_id,type,content){
    let post_vars = {
        contest_id:contest.contest_id,
        type:type,
        content:  content
    };
    let result = await C.req("POST",`/contest/set_content/`,post_vars);
    console.log("Saving content result",result,post_vars);
}


import React, {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import {send_to_preview, set_contest_tab} from "../../ContestEditorFunctions";
import {req} from "../../../../CommonLibrary";
import ContestWysiwyg from "../../ContestWysiwyg";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
export default function DetailsRulesEditor(props) {
    const {contest, setContest} = props;

    const [detailsTitle, setDetailsTitle] = useState(contest.layout.tab_details);
    const [rulesTitle, setRulesTitle] = useState(contest.layout.tab_rules);
    let TabDetailsMounted = false;
    useEffect(() => {
        if (TabDetailsMounted)
            return;
        TabDetailsMounted = true;
        getContent();
    }, []);
    let [detailsContent, setDetailsContent] = useState('loading...');
    const [rulesContent, setRulesContent] = useState('loading...');

    const getContent = async () => {
        let details = await req('GET', `/contest/get_content/?contest_id=${contest.contest_id}&t=details`);
        let rules = await req('GET', `/contest/get_content/?contest_id=${contest.contest_id}&t=rules`);
        setDetailsContent(details);
        setRulesContent(rules);
    }


    const updateDetailsTitle = (event) => {
        setDetailsTitle(event.target.value);
        setContest('layout.tab_details', event.target.value);
    }
    const updateRulesTitle = (event) => {
        setRulesTitle(event.target.value);
        setContest('layout.tab_rules', event.target.value);

    }
    const updateDetailsContent = (value) => {
        //todo show some saving stuff
        set_contest_tab('details', value,'body');
        req('POST', `/contest/set_content/?contest_id=${contest.contest_id}&type=details`, {
            content: value,
            contest_id: contest.contest_id
        });
    }
    const updateRulesContent = (value) => {
        set_contest_tab('rules', value,'body');
        req('POST', `/contest/set_content/?contest_id=${contest.contest_id}&type=rules`, {
            content: value,
            contest_id: contest.contest_id
        });
    }
    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        //send to preview iframe
        console.log("send to preview iframe",newValue);
        send_to_preview('flip-tab', {tab: newValue});
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="Details tab" value="1" />
                        <Tab label="Rules tab" value="2" />\
                    </TabList>
                </Box>
                <TabPanel value="1"  sx={{paddingLeft:0,paddingRight:0}}>
                    <TextField className={"mb-4"} variant={'outlined'} fullWidth={true} label="Details tab label"
                               value={detailsTitle} onChange={updateDetailsTitle}/>
                    <ContestWysiwyg initial_value={detailsContent} change_handler={updateDetailsContent}
                                    contest_id={contest.contest_id}/>

                </TabPanel>
                <TabPanel value="2" sx={{paddingLeft:0,paddingRight:0}}>
                    <TextField className={"mb-4"} variant={'outlined'} fullWidth={true} label="Rules tab label"
                               value={rulesTitle} onChange={updateRulesTitle}/>
                    <ContestWysiwyg initial_value={rulesContent} change_handler={updateRulesContent}
                                    contest_id={contest.contest_id}/>
                </TabPanel>

            </TabContext>
        </Box>
    );

}


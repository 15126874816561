import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {form_obj} from "../../CommonLibrary";
const C = require("../../CommonLibrary")

export default function LoginFeatures() {
    const [oldPassWrong, setOldPassWrong] = React.useState(false);
    const [name,setName] = React.useState("");
    const [email,setEmail] = React.useState("");
    const get_login = async () => {
        let result = await C.req("GET","/account/get_login");
        setName(result.name);
        setEmail(result.email);
    }
    const updateLogin = async (e) => {
        e.preventDefault()
        let data = form_obj(e.target);
        let response = await C.req("POST","/account/update_login",data);
        if(!response?.result){
            toast.error(response?.message);
            return;
        }
    }
    const handlePassUpdate = async (event) => {
        event.preventDefault();
        const data =  C.form_obj(event.currentTarget);
        //verify passwords match
        if(data.new_pass.length <8 || data.new_pass_confirm.length<8 || data.old_pass.length<8){
            toast("Passwords must be at least 8 characters long");
            return;
        }
        if (data.new_pass !== data.new_pass_confirm){
            toast("Passwords do not match");
            return;
        }
        //todo show loader
        let result = await C.req("POST",`/auth/update_pass/`,data);
        if(!result.result){
            toast(result.message);
            setOldPassWrong(true);
            return;
        }
        else {
            toast("Password updated");
            setOldPassWrong(false);
        }
    }
    const forgot_password = async ()=>{
        let result = await C.req("POST",`/auth/forgot_password/`);
        if(!result.result){
            toast(result.message);
            return;
        }
        else {
            toast("Email sent");
        }
    }
    let mounted = false;
    useEffect(() => {
        if(mounted)return;
        mounted = true;
        get_login();
        document.title = "Account: your login";
    },[]);
    return (

            <Grid spacing={2} container={true}>
                <Grid item={true} sm={6}>
                    <Paper elevation={3}>
                        <form className={'p-4'} onSubmit={updateLogin}>
                            <h4>Update your details</h4>
                            <TextField fullWidth={true} sx={{mb: 3}} value={name} onChange={(e)=>setName(e.target.value)} type={"text"} name={"name"} required={1} label="your name"
                                       variant="outlined"/>
                            <TextField fullWidth={true} sx={{mb: 3}}  value={email} onChange={(e)=>setEmail(e.target.value)} type={"email"} name={"email"} required={1} label="your email"
                            variant="outlined"/>
                            <Button fullWidth={true} variant={"outlined"} type={"submit"}>Update your details</Button>
                        </form>
                    </Paper>
                </Grid>
                <Grid item={true} sm={6}>
                    <Paper elevation={3}>
                        <form className={'p-4'} onSubmit={handlePassUpdate}>
                            <h4>Update password</h4>
                            <TextField fullWidth={true} sx={{mb: 3}} type={"password"} name={"old_pass"} min={8} label="Current password"
                                       variant="outlined"/>
                            {oldPassWrong && <div className={"mt-2 mb-4"}><Button fullWidth={true} onClick={forgot_password} variant={"outlined"} type={"button"}>Forgot your password?</Button></div>}
                            <TextField fullWidth={true} sx={{mb: 3}} type={"password"} name={'new_pass'} min={8}
                                       label="New password" variant="outlined"/>
                            <TextField fullWidth={true} sx={{mb: 3}} type={"password"} name={'new_pass_confirm'} min={8}
                                       label="Confirm new password" variant="outlined"/>
                            <Button fullWidth={true} variant={"outlined"} type={"submit"}>Change password</Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
    );
}

import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ContestWysiwyg from "../ContestWysiwyg";
import Button from "@mui/material/Button";

export default function WinnersSettings(props) {
    const {contest, setContest} = props;
    const [contestantsCanOnlyWinOnce, setContestantsCanOnlyWinOnce] = useState(contest.winners_settings.contestantsCanOnlyWinOnce);
    const [automaticallyPickWinners, setAutomaticallyPickWinners] = useState(contest.winners_settings.automaticallyPickWinners);
    const [winnersEmail, setWinnersEmail] = useState(contest.winners_settings.winnersEmail);
    const [sendFrom, setSendFrom] = useState(winnersEmail.fromEmail);
    const [winnerEmailTemplate, setWinnerEmailTemplate] = useState(winnersEmail.useTemplate);
    const [writingOwnEmail, setWritingOwnEmail] = useState(winnersEmail.useTemplate === 0);
    const [emailMessage, setEmailMessage] = useState(winnersEmail.message);
    const [emailSubject, setEmailSubject] = useState(winnersEmail.subject);
    const [displayWinners, setDisplayWinners] = useState(contest.show_winners);
    const handleEmailSubjectUpdate=(event)=>{
        setEmailSubject(event.target.value);
        setContest("winners_settings.winnersEmail.subject", event.target.value);
    }
    const handleUpdateWinnerEmailTemplate = (event) => {
        setWinnerEmailTemplate(event.target.value);
        setWritingOwnEmail(event.target.value === 0);
        setContest("winners_settings.winnersEmail.useTemplate", event.target.value);
    }
    const handleEmailMessageChange = (content) => {
        console.log("handleEmailMessageChange",content);
        setEmailMessage(content);
        setContest("winners_settings.winnersEmail.message", content);
    }
    const handleSendFromChange = (event) => {
        setSendFrom(event.target.value);
        setContest("winners_settings.winnersEmail.fromEmail", event.target.value);
    }
    const updateCanOnlyWin1 = (event) => {
        setContestantsCanOnlyWinOnce(event.target.checked);
        setContest("winners_settings.contestantsCanOnlyWinOnce", event.target.checked);
    }
    const updateAutomaticallyPickWinners = (event) => {
        setAutomaticallyPickWinners(event.target.checked);
        setContest("winners_settings.automaticallyPickWinners", event.target.checked);
    }
    const sendWinnerPreviewEmail = () => {
        //TOD BUILD
        console.log("sendWinnerPreviewEmail");
    }
    const updateDisplayWinners = (event) => {
        setDisplayWinners(event.target.checked);
        setContest("show_winners", event.target.checked);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch onChange={updateCanOnlyWin1} checked={contestantsCanOnlyWinOnce}/>}
                        label="Contestants win once" labelPlacement="bottom"/>
                </FormGroup>
            </Grid>
            <Grid item xs={4}>
                <FormGroup>
                    <FormControlLabel labelPlacement="bottom"
                        control={<Switch onChange={updateAutomaticallyPickWinners}  checked={automaticallyPickWinners}/>}
                        label="Auto pick winners"/>
                </FormGroup>
            </Grid>
            <Grid item xs={4}>
                <FormGroup>
                    <FormControlLabel labelPlacement="bottom"
                        control={<Switch onChange={updateDisplayWinners} checked={displayWinners}/>}
                        label="Display winners"/>
                </FormGroup>
            </Grid>
            <Grid item className={"pt-4"} xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography flex={.6}>Winners email</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}><Typography>Winners mail settings</Typography></Grid>
                                <Grid item xs={6}><Typography><Button type={'button'} onClick={sendWinnerPreviewEmail} variant={'text'}>preview</Button></Typography></Grid>
                                <Grid item xs={6}><FormControl fullWidth={true} >
                                    <InputLabel id="demo-simple-select-label">Send from</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sendFrom}
                                        label="Send from"
                                        onChange={handleSendFromChange}
                                        fullWidth={true}
                                    >
                                        <MenuItem value={'winners@rewardsfuel.com'}>winners@rewardsfuel.com</MenuItem>
                                        <MenuItem value={0}>add your email address</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth={true} value={emailSubject} onChange={handleEmailSubjectUpdate} id="outlined-basic" name={'subject'} label="Subject" variant="outlined"/>
                                </Grid>
                                <Grid item xs={12}>
                                <Select
                                    fullWidth={true}
                                    id="demo-simple-select"
                                    value={winnerEmailTemplate}
                                    label="Email message"
                                    onChange={handleUpdateWinnerEmailTemplate}
                                >
                                    <MenuItem value={'default'}>Default email</MenuItem>
                                    <MenuItem value={0}>Write your own</MenuItem>
                                </Select>
                                </Grid>
                                {writingOwnEmail &&
                                <Grid item xs={12}>
                                    <ContestWysiwyg  initial_value={emailMessage} change_handler={handleEmailMessageChange} contest_id={contest.contest_id}/>
                                </Grid>}
                            </Grid>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Prize claim settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Contestants will get a link in their winner notication email. Are there any questions you'd
                            like to ask them before they claim their prize?
                            If the prize they win is a physical item, do you want to ask them for their shipping
                            address?
                            If the prize they are claiming is a digital item, do you want to ask them anything?
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Grid>
        </Grid>);
}

import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import CardActions from "@mui/material/CardActions";

export function Title(props) {
    const {contest, setContest,setIframeFocus} = props;
    const [title, setTitle] = React.useState(contest.contest_name);
    const onChange = async (event) => {
        setTitle(event.target.value)
        setContest('contest_name', event.target.value);
    }



    return (<>
        <TextField variant={'outlined'} fullWidth={true} label={'Contest title'} value={title}
                   onChange={onChange}/>

        <CardActions>
            <div>Title is the top of the contest area, updated are automatically saved</div>
        </CardActions>
    </>);
}

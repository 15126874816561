import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {form_obj, req} from "../../../../CommonLibrary";
import ContestWysiwyg from "../../ContestWysiwyg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/*
Embed click and go
--facebook like
--facebook engage post
--follow on Tumblr
--Subscribe on Youtube
--follow on IG
--follow on pateron
--follow on mixCloud
--follow on SoundCloud
--follow on SnapChat
--follow on TikTok
--visit entry
--post engagement
--follow on pinterest
--follow on Shoptify
 */

export default function WatchToWin(props) {
    let {entry_type, entry_method, update_entry_method,contest} = props;
    let form_properties = entry_type.form_properties;
    let editing = false
    let default_description = '';
    let default_url = entry_type.form_properties.default_input_value;
    let default_points = 5;
    if(entry_method===undefined)
        entry_method = false;
    if (entry_method !== false) {
        editing = true;
        default_description = entry_method.description;
        default_url = entry_method.url;
        default_points = parseInt(entry_method.entry_points);
    }
    const [url, set_url] = React.useState(default_url);
    const [editDescription, setEditDescription] = React.useState(String(entry_method.description) !=="");
    const [entry_points, set_entry_points] = React.useState(default_points);
    const handle_description_change=(new_value)=>{
        default_description = new_value;
    }
    const submit_entry_method = async (event) => {
        event.preventDefault();
        let data = form_obj(event.target);
        data.description = default_description
        update_entry_method(data);
        //go back to previous tab
    }
    let expandDescription = default_description.trim() !== "";
    return (<div>
        <form className={'add-edit-entry-method-form'} onSubmit={submit_entry_method}>
            {editing && <input type={'hidden'} name={'entry_method_id'} value={entry_method.entry_method_id}/>}
            <input type={'hidden'} name={'entry_type'} value={entry_type.id}/>
            <h3 className={'mb-4'}>Add {entry_type.name}</h3>
            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={8}>
                    <TextField
                        required
                        id="outlined-number"
                        label={form_properties.url_label}
                        defaultValue={url}
                        name={form_properties.input_name}
                        type="url"
                        onChange={(e) => set_url(e.target.value)}
                        className={'w-100'}
                        helperText="Please make sure the url is correct"
                    />
                </Grid>
                <Grid item={true} xs={12} sm={4}>
                    <TextField
                        required
                        name={'entry_points'}
                        id="outlined-number"
                        label="Entry points"
                        type="number"
                        value={entry_points}
                        onChange={(e) => set_entry_points(e.target.value)}
                        className={'w-100'}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Accordion defaultExpanded={expandDescription}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Add/Edit Description</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={'mt-2 mb-2 description-area'}>Add content here that will appear once a
                                contestant has clicked the enter button
                            </div>
                            <ContestWysiwyg contest_id={contest.contest_id} initial_value={default_description} change_handler={handle_description_change}/>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
            </Grid>
            <Button className={'w-100 mt-3'} variant="outlined"
                    type={'submit'}>{editing ? 'Edit' : 'Add'} {form_properties.name}</Button>
        </form>
    </div>);
}

import Grid from "@mui/material/Grid";
import {FormControlLabel} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import ContestWysiwyg from "../../ContestWysiwyg";
import {useState} from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import {form_obj} from "../../../../CommonLibrary";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function NewsletterEntry(props){
    const {entry_type, entry_method, update_entry_method,contest} = props;
    //todo prefill all defaults and use entry method if update
    let editing = false;
    let default_points = entry_method?.entry_points ? parseInt(entry_method?.entry_points) : 5;
    let addEdit = "Add";
    let default_description =  entry_method?.description ? entry_method?.description : entry_type.form_properties.description;
    let default_email_body =  entry_method?.body ? entry_method?.body : entry_type.form_properties.email_message;
    let default_subject = entry_method?.subject ? entry_method?.subject : entry_type.form_properties.subject;
    let default_sending_email = entry_method?.sending_email_id ? entry_method?.sending_email_id : entry_type.form_properties?.sending_email_id;
    if (entry_method) {
        editing = true;
        addEdit = "Edit";
    }



    const [entry_points, set_entry_points] = React.useState(default_points);
    const [newsletterDescription, setNewsletterDescription] = useState(default_description);
    const [subject, setSubject] = useState(default_subject);
    const [emailBody, setEmailBody] = useState(default_email_body);
    const [requireCheckbox, setRequireCheckbox] = useState(entry_method?.require_checkbox ? entry_method?.require_checkbox : false);
    const [sendingEmail, setSendingEmail] = useState(default_sending_email);
    const [emailTemplateId, setEmailTemplateId] = useState(entry_method?.email_template_id ? entry_method?.email_template_id : entry_type.form_properties?.email_template_id);
    const wysChangeHandler = (value) => {
        setNewsletterDescription(value);
    }
    const wysChangeEmailBody = (value) => {
        setEmailBody(value);
    }

    const handleFormSubmit = (event) => {
        /* data we need to save
{
  "sending_email_id": "sender_0",
  "subject": "Confirm your subscription",
  "body": "Thank you for subscribing to our newsletter. Please click the link below to confirm your subscription. [CONFIRMATION_LINK]",
  "email_template_id":false,
  "description": "Sign up for our newsletter and enter to win.",
  "entry_points": 5,
  "button_label": "Subscriber to our newsletter",
  "entry_type": 2,
  "sort_order": 0,
  "renderer": "newsletterEntry",
  "require_checkbox":false,
  "export_data": []
}
            */
        event.preventDefault();
        let data = form_obj(event.target);
        data.description = newsletterDescription;
        data.body = emailBody;
        data.require_checkbox = (requireCheckbox);
        data.export_data = [];//todo this part is UGLY
        console.log("submit newsletter method", data);
        update_entry_method(data);
    }

    /** data needed to create newsletter entry method
     * --what fields they want to collect todo
     * --double opt-in email (choose from list or create your own email) including sending email address
     * --auto export subscribers to lists
     * --show checkbox to opt in to newsletter (show or not)
     */
    return(<>

       <form onSubmit={handleFormSubmit}>
           {editing && <input type={'hidden'} name={'entry_method_id'} value={entry_method.entry_method_id}/>}
           <input type={'hidden'} name={'entry_type'} value={entry_type.id}/>


        <Grid container>
            <Grid item={true} xs={12} sm={8}>
                <h3 className={"p-0 m-0"}>{addEdit} Newsletter Entry</h3>
            </Grid>
            <Grid item={true} xs={12} sm={4}>

                <TextField
                    required
                    name={'entry_points'}
                    id="outlined-number"
                    label="Entry points"
                    type="number"
                    value={entry_points}
                    onChange={(e) => set_entry_points(e.target.value)}
                    className={'w-100'}
                />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid className={'pt-3'} item xs={12}>

            </Grid>

            <Grid item className={"pt-4 pb-4"} xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Description to join newsletter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ContestWysiwyg initial_value={newsletterDescription} change_handler={wysChangeHandler} contest_id={contest.contest_id}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Double opt in email</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={"pt-3 pb-3"}>
                            All newsletter subscribers will receive an email to confirm their subscription. You can customize the email address its sent from and the message that is sent.
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item className={"pt-3 pb-3"} xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">Select email</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select email"
                                        name={'email_template_id'}
                                        value={emailTemplateId}
                                    >
                                        <MenuItem value={0}>None - use email below</MenuItem>
                                        <MenuItem value={1}>Template #1</MenuItem>
                                        <MenuItem value={2}>Template #2</MenuItem>
                                        {/* todo add email templates from account here */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item  className={"pt-3 pb-3"} xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">From email</InputLabel>
                                    <Select
                                        value={sendingEmail}
                                        onChange={(e) => setSendingEmail(e.target.value)}
                                        name={'sending_email_id'}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="From email"
                                    >
                                        <MenuItem value={0}>noReply@rewardsfuel.com</MenuItem>
                                        <MenuItem value={-1}>Add a sending email</MenuItem>
                                        {/* todo add on select of -1 show modal to let ch add method */}
                                    </Select>
                                </FormControl>
                                </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name={'subject'}
                                    id="outlined-number"
                                    label="Email subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    className={'w-100'}
                                />
                            </Grid>
                        </Grid>
                        <ContestWysiwyg className={"pt-3 pb-3"} initial_value={emailBody} change_handler={wysChangeEmailBody} contest_id={contest.contest_id}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Export subscribers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Once a contestant has subscribed to your newsletter, we can automatically export them to a list(s) in your email marketing software.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid className={"pt-4 pb-4"} item xs={12}>
                <FormGroup className={"pt-3 pb-3 d-block w-100 text-center"}>
                    <FormControlLabel control={<Switch checked={requireCheckbox} onChange={(e)=>{setRequireCheckbox(e.target.checked)}}  name={"require_checkbox"} />} label="Require check box for opting in." />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth={true} type={'submit'} variant={'outlined'} color={'primary'}>{addEdit} Newsletter Entry</Button>
            </Grid>
        </Grid>
       </form>

    </>);
}

import { req } from "../../src/CommonLibrary"
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function VerifySendingEmail(props) {
    //get querystring variable variable of env
    const query = new URLSearchParams(window.location.search);
    const env = query.get('env');
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            validate_link();
        }
    }, [])
    if(!env) {
        return (<div className="container p-4"><h1>Invalid link</h1></div>);
    }
    const validate_link = async () => {
        const response = await req("GET", "/account/validate_sending_email?env="+env);
        console.log("validate_link", response);
        if(response){
            toast.success("Email verified");
            setTimeout(() => {
                window.location = "/s/account/#email_preferences";
            }, 2000);
        }
    }
    return (<div className="container p-4"><h1>Verifying sending email</h1></div>);
} 
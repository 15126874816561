import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {req, upload} from "../../CommonLibrary";
import Grid from "@mui/material/Grid";
import {ButtonGroup} from "@mui/material";
import ImageUploading from "react-images-uploading";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";


export default function ThemesAndColors(props) {
    const {themes_or_colors} = props;
    const [colors, setColors] = React.useState([]);
    const [css, setCss] = React.useState("loading...");
    const [editingTheme, setEditingTheme] = React.useState({
        name: 'Loading...',
        description: 'Loading..',
        css: 'Loading..',
    });
    const new_style ={
        id: 0,
        name: 'New '+themes_or_colors,
        description: 'New '+themes_or_colors,
        type: themes_or_colors,
        image: '',
        font: '',
        url: false,
    };
    const update_theme_props = (prop, value) => {
        setEditingTheme({
            ...editingTheme,
            [prop]: value,
        });
    }
    const load_sheet = async (url) => {
        let response = await req("GET", `/contest/get_css/?contest_id=1&key=${url}`);
        setCss(response);

    }
    const getColors = async () => {
        let results = await req("GET", "/tank/get_styles/?type="+themes_or_colors);
        console.log("Get colors", results);
        results.unshift(new_style)
        setEditingTheme(results[0]);
        setColors(results);
    }
    const deleteTheme = async () => {
        if (editingTheme.id === 0)
            return false;
        let confirm = window.confirm("Are you sure you want to delete this theme?");
        if (confirm === true) {
            let response = await req("POST", "/tank/delete_style", {
                id: editingTheme.id,
                type: themes_or_colors,
            });
            response.unshift(new_style);
            setColors(response);
        }
    }

    const handleSave = async (event) => {
        //if new vs update
        event.preventDefault();
        let post_data = editingTheme;
        post_data.css = css;
        post_data.type = themes_or_colors;
        post_data.image= images[0]?.data_url;
        let result = await req("POST", "/tank/set_style/", post_data);
        result.unshift(new_style);
        setColors(result);

    };
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            getColors();
            mounted = true;
        }
    }, []);

    const columns = [
        {
            field: 'name', headerName: themes_or_colors, minWidth: 160, flex: 1,
            onClick: (e) => {
                console.log("onRowsSelected", e);
                setEditingTheme(e.row);
            }
        }
    ]
    const [images, setImages] = React.useState([]);
    const uploadImage = async (img_obj) => {
        let theme_id = editingTheme.id;
        if(!theme_id)
            theme_id = new Date().getTime();
        let url = '/tank/upload_image/?theme_id='+theme_id;
        return await upload(url, img_obj.file,0,themes_or_colors+'_image');
    }
    const onChangeImage = async (imageList, addUpdateIndex) => {
        // data for submit
        let new_file = false;
        if (imageList[addUpdateIndex] && typeof imageList[addUpdateIndex] === "object" && imageList[addUpdateIndex]?.data_url.includes('data:image'))
            new_file = await uploadImage(imageList[addUpdateIndex]);
        if (new_file)
            imageList[addUpdateIndex].data_url = new_file.location;
        setImages(imageList);
    }
    const preventDefault = (event) => event.preventDefault();
    return (<>
            <Grid container={true} spacing={2}>
                <Grid item xs={4}>
                    <Box sx={{height: 400, width: '100%'}}>
                        <DataGrid
                            onCellClick={(e) => {
                                setEditingTheme(e.row);
                                load_sheet(e.row.url);
                                let images = [];
                                if (e.row.image)
                                    images.push({data_url: e.row.image});
                                setImages(images);
                            }}
                            rows={colors}
                            columns={columns}
                        />
                    </Box>
                </Grid>
                <Grid item xs={8} className={"pt-0 mt-0"}>{editingTheme && <form onSubmit={preventDefault} >
                    <TextField
                        variant={"outlined"}
                        label="Name"
                        onChange={(event) => {
                            update_theme_props("name", event.target.value)
                        }}
                        value={editingTheme.name}
                        fullWidth
                        margin="normal"
                    />
                    <Grid container={true} spacing={2}>
                        <Grid item xs={9}>
                            <TextField
                                label="Description"
                                onChange={(event) => {
                                    update_theme_props("description", event.target.value)
                                }}
                                value={editingTheme.description}
                                fullWidth
                                margin="normal"
                                rows={4}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={3}>
                                <ImageUploading
                                    value={images}
                                    onChange={onChangeImage}
                                    maxNumber={1}
                                    dataURLKey="data_url"
                                >
                                    {({
                                          imageList,
                                          onImageUpload,
                                          onImageUpdate,
                                          onImageRemove,
                                          isDragging,
                                          dragProps,

                                      }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">

                                            <button className={'btn-uploader'}
                                                    style={isDragging ? {color: 'red'} : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                            >
                                                <FileUploadIcon/>
                                                <div>Theme/color preview image.</div>
                                            </button>

                                            <div className='position-relative'>

                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <Grid container  spacing={2}>
                                                            <Grid item xs={9}>
                                                                <img src={"//cdn-rf3.rewardsfuel.com"+image['data_url']} onClick={() => onImageUpdate(index)} alt="" width="50"/>
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <Button variant={'outlined'} title={"Remove image"} onClick={() => onImageRemove(index)}><DeleteIcon/></Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>
                        </Grid>
                    </Grid>

                    <TextField
                        label="CSS"
                        value={css}
                        onChange={(event) => {
                            setCss(event.target.value)
                        }}
                        multiline
                        rows={6}
                        fullWidth
                        margin="normal"
                    />


                    <ButtonGroup fullWidth={true}>
                        <Button style={{width: '25%'}} type={"button"} color={"error"}
                                onClick={deleteTheme}>Delete</Button>
                        <Button style={{width: '75%'}} type={"submit"} color={"primary"}
                                onClick={handleSave}>Save</Button>
                    </ButtonGroup>


                </form>}
                </Grid>
            </Grid>


        </>
    );
}

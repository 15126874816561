import React, {useState} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function MobileResolutionSelector(props) {
    const {setIframeDimensions} = props;
    const [device, setDevice] = useState('iphone-x');
    const [resolution, setResolution] = useState('portrait');

    const devices = {
        'iphone-x': {
            portrait: {
                width: 375,
                height: 812
            },
            landscape: {
                width: 812,
                height: 375
            }
        },
        'iphone-11': {
            portrait: {
                width: 414,
                height: 896
            },
            landscape: {
                width: 896,
                height: 414
            }
        },
        'samsung-galaxy-s20': {
            portrait: {
                width: 360,
                height: 740
            },
            landscape: {
                width: 740,
                height: 360
            }
        },
        'google-pixel-4': {
            portrait: {
                width: 360,
                height: 740
            },
            landscape: {
                width: 740,
                height: 360
            }
        },
        'oneplus-8': {
            portrait: {
                width: 402,
                height: 690
            },
            landscape: {
                width: 690,
                height: 402
            }
        },
        'huawei-p40': {
            portrait: {
                width: 360,
                height: 676
            },
            landscape: {
                width: 676,
                height: 360
            }
        }
    };

    const handleDeviceChange = (event) => {
        setDevice(event.target.value);
        let dimensions = devices[event.target.value][resolution];
        setIframeDimensions(dimensions);
    };

    const handleResolutionChange = (event) => {
        setResolution(event.target.value);
        let dimensions = devices[device][event.target.value];
        setIframeDimensions(dimensions);
    };

    return (
        <Box className={'p-1'}>
                <FormControl  size="small" variant="standard" sx={{marginRight:'1rem'}}>
                    <InputLabel id="device-label">Device</InputLabel>
                    <Select
                        labelId="device-label"
                        id="device-select"
                        value={device}
                        label="Device"
                        onChange={handleDeviceChange}
                    >
                        <MenuItem value="iphone-x">iPhone X</MenuItem>
                        <MenuItem value="iphone-11">iPhone 11</MenuItem>
                        <MenuItem value="samsung-galaxy-s20">Samsung Galaxy S20</MenuItem>
                        <MenuItem value="google-pixel-4">Google Pixel 4</MenuItem>
                        <MenuItem value="oneplus-8">OnePlus 8</MenuItem>
                        <MenuItem value="huawei-p40">Huawei P40</MenuItem>
                    </Select>
                </FormControl>
                <FormControl  size="small"  variant="standard">
                    <InputLabel id="resolution-label">Resolution</InputLabel>
                    <Select
                        label={resolution}
                        labelId="resolution-label"
                        id="resolution-select"
                        value={resolution}
                        onChange={handleResolutionChange}
                    >
                        <MenuItem value="portrait">Portrait</MenuItem>
                        <MenuItem value="landscape">Landscape</MenuItem>
                    </Select>
                </FormControl>
    </Box>)
};

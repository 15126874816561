import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {form_obj, req} from "../../../CommonLibrary";
import PrizePickers from "./PrizePickers";
import * as C from "../../../CommonLibrary";
import {toast} from 'react-toastify';
import PrizeUploader from "./PrizeUploader";
import {FileIcon} from 'react-file-icon';
import ContestWysiwyg from "../ContestWysiwyg";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

export default function AddPrizeForm(props) {
    const {contest, load_prizes, prize,setAddPrizeOpened} = props;
    const [prizeType, setPrizeType] = React.useState('physical');
    const [prizeDescription, setPrizeDescription] = React.useState('');
    const [prizeName, setPrizeName] = React.useState('');
    const [prizePickers, setPrizePickers] = React.useState([]);
    const [prizePickersData, setPrizePickersData] = React.useState([]);
    const [downloadable_prize, set_downloadable_prize] = React.useState('');
    const [downloadable_prize_extension, set_downloadable_prize_extension] = React.useState('');
    const [short_file_name, set_short_file_name] = React.useState('');
    let initialPrizeDescription = '';
    useEffect(() => {
        initialPrizeDescription = prizeDescription;
        if (prize) {
            setPrizeType(prize.prize_type);
            setPrizeDescription(prize.description);
            setPrizeName(prize.name);
            initialPrizeDescription = prize.description;//hack because the WYSIWYG needs an initial value that isn't updated by state
        }
        return () => {
        };
    }, []);

    const handlePrizeTypeChange = (event) => {
        setPrizeType(event.target.value);
    }
    const handle_wysiwyg_change = (content) => {
        setPrizeDescription(content);
    }
    const addPrizePicker = () => {
        let new_index = prizePickers.length;

        let new_prizes = [...prizePickers,
            <PrizePickers updatePicker={updatePicker} picker={{
                nbrWinners: 1,
                pickAt: "end",
                pickDate: new Date().getTime()
            }}
                          removePicker={removePicker} key={new_index}
                          index={new_index}/>];
        setPrizePickers(new_prizes);
        setPrizePickersData([...prizePickersData, {}]);
    }
    const savePrize = async (event) => {
        event.preventDefault();
        let data = {
            contest_id: contest.contest_id,
            prize_data: form_obj(event.target)
        };
        data.prize_data.prize_description = prizeDescription;
        data.prize_data.pickers = prizePickersData;
        setAddPrizeOpened(false);
        await C.req('POST', '/contest/set_prize/', data);
        load_prizes();
        toast('Prize saved');
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        document.getElementById('add-prize-form').reset();
        setPrizePickers([]);
        setPrizePickersData([]);
        setPrizeType('physical');

    }
    const updatePicker = (index, data) => {
        let updated_pickers = prizePickersData;
        updated_pickers[index] = data;
        setPrizePickersData(updated_pickers);
    }
    const update_downloadable_prize = (file_location) => {
        let file_name = file_location.split('/').pop();
        file_name = file_name.split('-').pop();
        let file_extension = file_name.split('.').pop();
        set_downloadable_prize(file_location);
        set_downloadable_prize_extension(file_extension);
        set_short_file_name(file_name);
    }
    const delete_downloadable_prize = (file) => {
        req("POST", "/contest/delete_prize_file/", {file: file, contest_id: contest.contest_id});
        update_downloadable_prize('');
    }
    const removePicker = (index) => {
        let new_pickers = prizePickers;
        new_pickers.splice(index, 1);
        setPrizePickers(new_pickers);
        let pickers = prizePickersData;
        pickers.splice(index, 1);
        setPrizePickersData(pickers);

    }
    if (!prizePickers.length) {
        addPrizePicker();
    }

    return (
        <>
            <form id="add-prize-form" onSubmit={savePrize}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={8}>
                        <TextField id="outlined-basic" defaultValue={prizeName} name={'name'}
                                   className={'w-100'}
                                   label="Prize name"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="prize-type-label">Physical type</InputLabel>
                            <Select
                                name={'prize_type'}
                                labelId="prize-type-label"
                                label="Age"
                                defaultValue={prizeType}
                                onChange={handlePrizeTypeChange}
                            >
                                <MenuItem value={'physical'}>Physical</MenuItem>
                                <MenuItem value={'digital'}>Digital</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {prizeType === 'digital' &&
                        <>
                            <Grid item={true} xs={12} md={8}>
                                {downloadable_prize ?
                                    <div className={'downloadable-file-area'}>

                                        <FileIcon extension={downloadable_prize_extension}/>
                                        <Button type={'button'}
                                                onClick={() => delete_downloadable_prize(downloadable_prize)}
                                                color="error">Delete {short_file_name}</Button>
                                        <input type={'hidden'} name={'downloadable_prize'}
                                               value={downloadable_prize}/>
                                    </div>
                                    :
                                    <PrizeUploader update_downloadable_prize={update_downloadable_prize}
                                                   contest_id={contest.contest_id}
                                                   url={"http://127.0.0.1:8787/contest/upload/"}/>
                                }
                            </Grid>
                            <Grid item={true} xs={12} md={4}>
                                <TextField label="Max nbr downloads" name={'max_nbr_downloads'}
                                           variant="outlined" minVal={1} defaultValue={3}
                                           type="number"/>
                            </Grid>
                        </>
                    }
                    <Grid item={true} xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"

                            >
                                <Typography sx={{width: '66%', flexShrink: 0}}>
                                    Add prize description
                                </Typography>
                                <Typography sx={{
                                    color: 'text.secondary',
                                    width: '33%',
                                    flexShrink: 0,
                                    paddingRight: '1rem'
                                }}
                                            align="right">
                                    Optional
                                </Typography>


                            </AccordionSummary>
                            <AccordionDetails>
                                <ContestWysiwyg contest_id={contest.contest_id}
                                                change_handler={handle_wysiwyg_change} initial_value={''}/>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <div id={'prize-pickers'}></div>
                </Grid>
                {prizePickers}
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                        <Button fullWidth={true} variant={"outlined"} onClick={addPrizePicker} type={"button"} >Add
                             prize picker
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button fullWidth={true} variant={"outlined"} type={'submit'}>Add prize</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

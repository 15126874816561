import {form_obj, req} from "../../CommonLibrary";
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Modal, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function Accounts() {
    const [rfAccounts, setRfAccounts] = useState([]);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const get_accounts = async () => {
        let url = "/tank/get_accounts";
        let result = await req("GET", url);
        setRfAccounts(result);
    }
    const getAccountProperties = async (event) => {
        let account_id = event.target.dataset.id;
        let url = `/tank/get_account_properties/?account_id=${account_id}`;
        let result = await req("GET", url);
        console.log("getAccountProperties",result);
    }
    const columns = [
        {field: 'account_id', headerName: 'ID', width: 250},
        {
            field: 'type',
            headerName: 'Account type',
            width: 150,
            editable: true,
        },
        {
            field: 'name',
            headerName: 'Account name',
            width: 150,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 110,
            editable: true,
        },
        {
            field: 'subscription',
            headerName: 'Subscription',
            width: 160,

        },
        {
            field: 'created_at',
            headerName: 'Created',
            width: 260,
            valueGetter: (params) => new Date(params.row.created_at).toLocaleString()
        },{
            field: 'updated_at',
            headerName: 'Actions',
            width: 260,
            renderCell: (params) => {
                return (
                    <Button variant="contained" color="primary" data-id={params.row.account_id} onClick={getAccountProperties}>Get properties</Button>
                )
            }
        }

    ];
    useEffect(() => {
        get_accounts();
    }, []);
    const [modalOpen, setModalOpen] =useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const create_account = async (event) => {
        event.preventDefault();
        let data =  form_obj(event.target);
        await req("POST","/tank/create_account/",data);
        handleClose();
        get_accounts();
    }
    const deleteSelected= async () => {
        if(prompt("Type 'delete' to delete selected accounts(s)") === "delete"){
            let result = await req("POST","/tank/delete/",{delete:selectedRow});
            get_accounts();
        }
    }
    return(<><Button type={'button'} onClick={handleOpen}>Create account</Button>
        {selectedRow && <Button className={'btn-danger'} type={'button'} onClick={deleteSelected}>Delete selected</Button>}
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        ><Box sx={modalStyle}>
            <form onSubmit={create_account}>
                <TextField fullWidth={true} id="account-name" name="name" className={"mb-3"} label="Account name" variant="outlined" />
                <TextField fullWidth={true} id="account-email" name="email" className={"mb-3"} label="Account email" variant="outlined" />
                <FormControl fullWidth={true}  className={"mb-3"}>
                    <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
                    <Select
                        name="subscription"
                    >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="free">Free</MenuItem>
                        <MenuItem value="basic">Basic</MenuItem>
                        <MenuItem value="premium">Premium</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth  className={"mb-3"}>
                    <InputLabel id="demo-simple-select-label">Account type</InputLabel>
                    <Select name="type">
                        <MenuItem value="contest_holder">Contest holder</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                </FormControl>
                <Button fullWidth variant={'outlined'} type={'submit'} >Create account</Button>
            </form>
            </Box>
        </Modal>
        {rfAccounts.length > 0  &&<Box sx={{height: '100vh', width: '100%'}}>
                <DataGrid
                    rows={rfAccounts}
                    columns={columns}
                    pageSize={100}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectedRow(newSelectionModel);
                    }}
                    experimentalFeatures={{newEditingApi: true}}
                /></Box>
        }</>);
}

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {form_obj, req} from "../../CommonLibrary";
import Button from "@mui/material/Button";
import {useState} from "react";

export default function AccountSettings(props) {
    const {account,get_account} = props;
    const handleAccountUpdate = async (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        console.log("handleAccountUpdate",data)
        await req("POST","/account/update",data);
        get_account();//makes update
        //hide changes submit button
    }
    const[name,setName] = useState(account.name);
    const[account_contact,setAccount_contact] = useState(account.account_contact);
    const[account_email,setAccount_email] = useState(account.email);
    const[account_domain,setAccount_domain] = useState(account.domain);
    return (
        <Grid container={true}>
            <form className={'account-form'} onSubmit={handleAccountUpdate}>
                <Grid item={true} xs={12}>

                    <TextField value={name} onChange={(e)=>setName(e.target.value)} fullWidth={true} required={1} id="outlined-basic" name={"account_name"} type={'text'}
                               label="Account name"
                               variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField  value={account_contact} onChange={(e)=>setAccount_contact(e.target.value)} fullWidth={true} required={1} id="outlined-basic" name={"account_contact"} type={'text'}
                               label="Main contact name"
                               variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField  value={account_email} onChange={(e)=>setAccount_email(e.target.value)}  fullWidth={true} required={1} id="outlined-basic" name={"account_email"} type={'email'}
                               label="Main contact email"
                               variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField  value={account_domain} onChange={(e)=>setAccount_domain(e.target.value)}  fullWidth={true} required={1} helperText="This is the domain name contests will be allowed on"
                               id="outlined-basic" name={"account_domain"} type={'url'} label="Domain"
                               variant="outlined"/>
                </Grid>
                <Grid item={true} xs={12}>
                    <Button fullWidth={1} variant={'contained'} type={"submit"}>Update account</Button>
                </Grid>
            </form>
        </Grid>
    );
}

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {SvgIcon} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, {useEffect} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import Button from "@mui/material/Button";

export default function PrizePickers(props) {

    const {index, removePicker,updatePicker,picker} = props;
    let show_date_condition = picker.pickAt==='date';
    const [showDatePicker, setShowDatePicker] = React.useState(picker.pickAt==='time');
    let col_num = 5;
    let col_num2 = 0;
    let col_num3 = 4;
    if(picker.pickAt==='time'){
        col_num = 2;
        col_num3 = 2;
        col_num2 = 5;
    }

    const [nbr_of_columns, setNbr_of_columns] = React.useState(col_num);
    const [nbr_of_columns_picker, setNbr_of_columns_picker] = React.useState(col_num2);

    const[pickDate,setPickDate] = React.useState(picker.pickDate);
    const[pickAt,setPickAt] = React.useState(picker.pickAt);
    const[nbrWinners,setNbrWinners] = React.useState(picker.nbrWinners);
    const handlePickerUpdates = (name,value) => {
        let update = {
            nbrWinners: nbrWinners,
            pickAt: pickAt,
            pickDate: pickDate
        }
        if(name==="pickAt"){
            setPickAt(value);
            let show_picker = value === 'time';
            if(show_picker) {
                setNbr_of_columns(2);
                setNbr_of_columns_picker(4);
            }else{
                setNbr_of_columns(5);
                setNbr_of_columns_picker(0);
            }
            update.pickAt = value;
            setShowDatePicker(show_picker);
        }
        if(name==="nbrWinners"){
            setNbrWinners(value);
            update.nbrWinners = value;
        }
        if(name==="pickDate"){
            setPickDate(value);
            update.pickDate = Math.floor(new Date(value).getTime()/1000);
        }
        updatePicker(index,update);
    }
    useEffect(() => {
        let update = {
            nbrWinners: nbrWinners,
            pickAt: pickAt,
            pickDate: Math.floor(new Date(pickDate).getTime()/1000)
        }
        updatePicker(index,update);
        return () => {

        };
    }, []);

    return (<Grid container={true} spacing={2} className={'text-center pt-3 pb-3'}>
        <Grid item={true} xs={nbr_of_columns}>
            <TextField id="outlined-basic" className={'w-100'} label="Pick # winners"
                        defaultValue={nbrWinners}
                       onChange={(e)=>handlePickerUpdates('nbrWinners',e.target.value)}
                       variant="outlined"/>
        </Grid>
        <Grid item={true} xs={col_num3}>
            <FormControl fullWidth>
                <InputLabel id="prize-type-label">At</InputLabel>
                <Select
                    defaultValue={pickAt}
                    variant="outlined"
                    labelId="prize-type-label"
                    label="At"
                    name={'prize_at'}
                    onChange={(e)=>handlePickerUpdates('pickAt',e.target.value)}
                >
                    <MenuItem value={'end'}>End of contest</MenuItem>
                    <MenuItem value={'time'}>Date</MenuItem>
                </Select>
            </FormControl>
        </Grid>

            <Grid item={true} xs={nbr_of_columns_picker}>
                {(showDatePicker ||show_date_condition) ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Pick at"

                        onChange={(newValue) => {
                            handlePickerUpdates('pickDate',newValue)
                        }}
                    />
                </LocalizationProvider>:''}
            </Grid>

        <Grid item={true} xs={1}>
            <Button color="error" type={'button'} onClick={() => {
                removePicker(index)
            }} className={'btn btn-danger btn-sm'}>
                <SvgIcon>
                    <HighlightOffIcon/>
                </SvgIcon>
            </Button>
        </Grid>
    </Grid>);
}

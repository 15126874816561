import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {req} from "../../../CommonLibrary";
import CssEditorsAccordion from "./CssEditors/CssEditorsAccordion";
import Button from "@mui/material/Button";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Grid from "@mui/material/Grid";
import StylesAndColorsAccordion from "./StylesAndColors/StylesAndColorsAccordion";
import StyleElementsAccordionDND from "./elementsForEditing/StyleElementsAccordionDND";
import VisibilityIcon from '@mui/icons-material/Visibility';
function TabPanel(props) {

    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            key={index}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{paddingTop: '1rem'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default function StyleSettings(props) {
    const {contest, minimize_style_editor, toggleMobilePreview} = props;

    const [value, setValue] = useState(0);
    const minimize = () => {
        minimize_style_editor(true);
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [styles, setStyles] = useState([]);
    const [colors, setColors] = useState([]);
    const initStylesAndColors = async () => {
        let themes = await req("GET", `/contest/get_themes/?contest_id=${contest.contest_id}`);
        setStyles(themes.styles);
        setColors(themes.colors);
    }

    const [tabTitle, setTabTitle] = useState({
        'items':'Contest Layout',
        'styles':'Style & Colors',
        'css':'Edit CSS'
    });
    const [previewIcons, setPreviewIcons] = useState([<><Button key={0} onClick={minimize}><KeyboardDoubleArrowLeftIcon/></Button>
        <Button key={1}  onClick={toggleMobilePreview}><PhoneAndroidIcon/></Button></>]);
    const handleMobileLayout = () => {
        if(window.innerWidth < 600){
            setPreviewIcons([ <Button key={0} onClick={minimize}><VisibilityIcon/></Button>]);
            setTabTitle({
                'items':'Layout',
                'styles':'Style',
                'css':'CSS'
            });
        }
    }
    let styleSettingsMounted = false;
    useEffect(() => {
        if (styleSettingsMounted)
            return
        handleMobileLayout();
        initStylesAndColors();
        document.title = 'Edit styles: ' + contest.contest_name;
    }, []);

    return (<>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs className={"contest-style-tabs"} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab key={0} label={tabTitle.items} />
                        <Tab  key={0} label={tabTitle.styles}  />
                        <Tab key={0} label={tabTitle.css}  />
                        <Grid container justifyContent="flex-end">
                            {previewIcons}
                        </Grid>
                    </Tabs>
                </Box>
                <TabPanel key={0} value={value} index={0}>
                    {/*<LayoutItems {...props} />*/}
                    <StyleElementsAccordionDND {...props} />
                </TabPanel>
                <TabPanel key={1} value={value} index={1}>
                    <StylesAndColorsAccordion {...props} styles={styles} colors={colors} setStyles={setStyles} setColors={setColors}/>
                </TabPanel>
                <TabPanel key={2} value={value} index={2}>
                    <CssEditorsAccordion {...props}/>
                </TabPanel>
            </Box></>
    );
}

import React, {useEffect, useState} from "react";
import EditPrizeForm from "./EditPrizeForm";
import {req} from "../../../CommonLibrary";
import AddPrizeForm from "./AddPrizeForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import WinnersSettings from "../settings/WinnersSettings";
import WinnersTable from "./WinnersTable";
import Divider from "@mui/material/Divider";

export default function WinnersPrizesTab(props) {
    const {contest} = props;
    const [prizes, setPrizes] = useState([]);
    const [tab, setTab] = useState('1');
    const [inPagePrizes, setInPagePrizes] = useState([]);
    const [addPrizeOpened, setAddPrizeOpened] = useState(false);
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };


    const removePrize = async (prizeId) => {
        document.getElementById('prize-' + prizeId).remove();
        await req('POST', '/contest/remove_prize/', {contest_id: contest.contest_id, prize_id: prizeId})
        await load_prizes();
    }

    const load_prizes = async () => {
        let loaded_prizes = await req('GET', '/contest/get_prizes/?contest_id=' + contest.contest_id);
        setPrizes(loaded_prizes);
    }
    useEffect(() => {
        let prize_list = prizes.map((p, index) => {
            return (<Accordion key={index}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id={"prize-"+p.prize_id}
                >
                    <Typography sx={{width: '66%', flexShrink: 0}}>
                        Edit: {p.name}
                    </Typography>
                    <Typography sx={{color: 'text.secondary', width: '33%', flexShrink: 0, paddingRight: '1rem'}}
                                align="right">
                        <Button fullWidth type={'button'} color="error" onClick={(event) => {
                            event.stopPropagation();
                            removePrize(p.prize_id)
                        }}>Delete
                        </Button>
                    </Typography>


                </AccordionSummary>
                <AccordionDetails>
                    <EditPrizeForm load_prizes={load_prizes} setPrize={setPrizes} prizes={prizes}
                                   removePrize={removePrize} key={index} prize={p} {...props}/>
                </AccordionDetails>
            </Accordion>)

        })

        setInPagePrizes(prize_list);
    }, [prizes]);
    let mounted = false
    useEffect(() => {
        if (mounted) return;
        mounted = true;
        load_prizes();

    }, []);
    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={tab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label="Prizes" value="1"/>
                        <Tab label="Winners" value="2"/>
                    </TabList>
                </Box>
                <TabPanel className={"no-side-padding"} value="1">
                    <Accordion onChange={()=>setAddPrizeOpened(!addPrizeOpened)} expanded={addPrizeOpened}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"

                        >
                            <Typography sx={{width: '66%', flexShrink: 0}}>
                                Add prize
                            </Typography>
                            <Typography sx={{color: 'text.secondary', width: '33%', flexShrink: 0, paddingRight: '1rem'}}
                                        align="right">

                            </Typography>


                        </AccordionSummary>
                        <AccordionDetails>
                            <AddPrizeForm setAddPrizeOpened={setAddPrizeOpened} load_prizes={load_prizes} {...props}/>
                        </AccordionDetails>
                    </Accordion>

                    {inPagePrizes}


                </TabPanel>
                <TabPanel className={"no-side-padding"} value="2">
                    <WinnersTable  {...props}/>
                    <h4 className={"pt-4 pb-2 text-center"}>Winners settings</h4>
                    <Divider />
                   <WinnersSettings {...props}/>

                </TabPanel>
            </TabContext>
        </Box>

    );
}

import Entries from './Entries';
import ContestLog from "./ContestLog";
import Lists from "./Lists";
import ContestJobs from "./ContestJobs";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactGA from "react-ga4";
import {useEffect, useState} from "react";
import {req} from "../../../CommonLibrary";
import Grid from "@mui/material/Grid";
import Referrers from "./Referrers";
import Devices from "./Devices";
import VisitorsOverTime from "./VisitorsOverTime";
import StatsMap from "./StatsMap";
import ContestSummary from "./ContestSummary";
import EntryMethodReport from "./EntryMethodReport";
import DemoReport from "./DemoReport";
import NewsletterEntries from "./NewsletterEntries";
const viewId = "4145919326";
const projectId = "337294783";
ReactGA.initialize("G-Y2T36CDB4W",{
    'projectId': projectId,
    'viewId': viewId,
    'debug': true,
    'property_id': viewId //todo test GA4 setting custom dimensions
});

export default function StatsTab(props) {
    const {contest} = props;
    const [otherData, setOtherData] = useState(false);
    const [entries, setEntries] = useState([]);
    const [newsletterEntries,setNewsletterEntries] = useState([]);
    const get_stats_other_data = async () => {
        let data = await req("GET","/contest/stats_other_data/?contest_id="+contest.contest_id);
        setOtherData(data);
    }
    const get_entries = async function() {
        let data = await req("get","/contest/stats_get/?type=contest_entry_&contest_id="+contest.contest_id);
        data = data.map((elm,ind) => {
            elm.id = ind;
            return elm;
        });
        setEntries(data);
        //setLoading(false);
    }
    const getNewsletterEntries = async function() {
        let data = await req("get","/contest/stats_get/?type=newsletter_entry_&contest_id="+contest.contest_id);
        data = data.map((elm,ind) => {
            elm.id = ind;
            return elm;
        });
        console.log("getNewsletterEntries",data);
        setNewsletterEntries(data);
        //setLoading(false);
    }
    useEffect(() => {
        document.title = 'Stats: '+contest.contest_name;
        get_stats_other_data();
        get_entries();
        getNewsletterEntries();

    },[]);
    props = {entries,get_entries,otherData,...props};
    console.log("entries",entries);
    return (<>

        <Accordion expanded={1}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>Contest entries</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {otherData &&

                <Grid  fullWidth={true} container={true} spacing={2}>
                    <Grid id={'stats-over-time'} item={true} xs={12} >
                        <VisitorsOverTime {...props} />
                    </Grid>
                    <Grid item={true}  md={6} xs={12}>
                        <ContestSummary {...props} />
                    </Grid>
                    <Grid item={true}  md={6} xs={12}>
                        <EntryMethodReport {...props} />
                    </Grid>
                    <Grid item={true}  md={6} xs={12}>
                        <DemoReport {...props} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Entries {...props} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <NewsletterEntries newsletterEntries={newsletterEntries} {...props} />
                    </Grid>
                    <Grid  item={true} xs={12} md={6}>
                       <StatsMap {...otherData} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Referrers {...otherData} />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Devices {...otherData} />
                    </Grid>
                </Grid>
                }

                {/**/}
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>DEBUG INFO (WILL REMOVE)</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Lists {...props}/>
                <hr/>
                {/*<ContestJobs {...props}/>*/}
            </AccordionDetails>
        </Accordion>
</>)
}

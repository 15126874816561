import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import React, {useEffect, useState} from "react";
import Switch from '@mui/material/Switch';
import ContestWysiwyg from "../ContestWysiwyg";
import {req} from "../../../CommonLibrary";
import Button from "@mui/material/Button";
import {ButtonGroup} from "@mui/material";
import {send_to_preview} from "../ContestEditorFunctions";
export default function ContestTermsFirst(props) {
    const {contest,setContest} = props;
    let terms_first = contest?.restrictions?.agreed_to_terms ||false;
    const [terms,set_terms] = useState('');
    const [updated_terms,set_updated_terms] = useState(terms);
    const [terms_to_save,set_terms_to_save] = useState(false);
    const [termsFirst, setTermsFirst] = useState(terms_first);
    const handleChange = (event)=>{
        setTermsFirst(event.target.checked);
        setContest("restrictions.agreed_to_terms",event.target.checked);
    }
    const change_handler=(content)=>{
        set_updated_terms(content);
        set_terms_to_save(true);
    }
    const get_terms = async ()=>{
        let t = await req("GET",`/contest/get_content/?contest_id=${contest.contest_id}&t=terms`);
        set_terms(t);
    }
    const save_terms = async ()=>{
        await req("POST",`/contest/set_content/?contest_id=${contest.contest_id}&type=terms`,{contest_id:contest.contest_id, content:updated_terms});
        set_terms_to_save(false);
    }
    const showPopup = ()=>{
        send_to_preview('show-settings-popup',{popup:'terms-verify',action:'show'});
    }
    const hidePopup = ()=>{
        send_to_preview('show-settings-popup',{popup:'terms-verify',action:'hide'});
    }
    useEffect(() => {
        get_terms();
        return () => {
        };
    }, []);

    return (<>
            <div className={"text-center"}>
                Require terms first <Switch  onChange={handleChange} checked={termsFirst} />
            </div>

                {termsFirst ?
                    <>
                    <ContestWysiwyg initial_value={terms} change_handler={change_handler} contest_id={contest.contest_id} />
                    {terms_to_save && <Button  fullWidth={true} type={'button'} className={'w-100 mt-3'} variant={'outlined'} color={'primary'}  onClick={save_terms}>Save</Button> }
                    </>
                    :
                    <h5>No terms will be shown.</h5>
                }
                <ButtonGroup fullWidth={true} className={"mt-3"}  variant="outlined" aria-label="outlined primary button group">
                    <Button onClick={showPopup}>Show popup</Button>
                    <Button  onClick={hidePopup}>Hide popup</Button>
                </ButtonGroup>


            <CardActions>
                This will add an agree to terms popup.
            </CardActions>
       </>
    );
}

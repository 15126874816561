import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {ConfirmProvider} from "material-ui-confirm";
import AddEntryMethods from "./AddEntryMethods";
import EditEntryMethods from "./EditEntryMethods";
import {req} from "../../../CommonLibrary";
import {useEffect, useState} from "react";
import NewsletterEntry from "./CrudForms/NewsletterEntry";
import EmbedAndGo from "./CrudForms/EmbedAndGo";
import {useConfirm} from "material-ui-confirm";
import ShareEntry from "./CrudForms/ShareEntry";
import WatchToWin from "./CrudForms/WatchToWin";
import FormCrud from './CrudForms/FormCrud';
export default function EntryMethodsTab(props) {
    //todo update server on delete and set of entry methods to return available entry methods
    const {contest, setContest} = props;
    const [selectTab, setSelectTab] = useState(1);
    const handleTabChange = (event, newValue) => {
        setSelectTab(newValue);
    };
    const [entry_methods, set_entry_methods_state] = useState([]);
    const [entry_types, set_entry_types] = useState([]);
    const [nbr_of_entry_methods, set_nbr_of_entry_methods] = useState(0);
    const get_entry_methods = async () => {
        let entry_m = await req("GET", "/contest/get_entry_methods/?contest_id=" + contest.contest_id);
        //entry_m = entry_m.filter((entry_method) => parseInt(entry_method.entry_type) > 0)
        set_entry_methods(entry_m);
    }
    const get_entry_types = async () => {
        try {
            let values = await req("GET", "/contest/get_entry_types/?contest_id=" + contest.contest_id);
            if (values.length)
                set_entry_types(values);
        } catch (e) {
            console.log(e);
        }
    }
    const update_entry_method = async (entry_method) => {
        let post = {
            contest_id: contest.contest_id,
            entry_method: entry_method
        };
        let new_entry_methods = await req("POST", "/contest/set_entry_method/", post);
        //new_entry_methods = new_entry_methods.filter((entry_method) => parseInt(entry_method.entry_type) > 0)
        //returns updated entry methods array
        set_entry_methods(new_entry_methods);
    }
    const confirm = useConfirm();
    const delete_entry_method = async (entry_method_id) => {
        //confirm({description: `Delete entry method?`})
            //.then(async () => {
                let new_entry_methods = await req("POST", "/contest/remove_entry_method/", {
                    contest_id: contest.contest_id,
                    entry_method_id: entry_method_id
                });
                //new_entry_methods = new_entry_methods.filter((entry_method) => parseInt(entry_method.entry_type) > 0)
                setContest("entry_methods", new_entry_methods);
                if (new_entry_methods) {
                    set_entry_methods(new_entry_methods); // remove from in page entry methods
                }

            //})
            //.catch(() => console.log("Deletion cancelled."));
    }
    let entry_methods_in_order = [];
    const update_entry_method_order = async (ids_in_order) => {
        //reorder entry methods by ids in order
        let new_entry_methods = [];
        for(let i = 0; i < ids_in_order.length; i++){
            try {
                let entry_method = entry_methods.filter(obj => {
                    return String(obj.entry_method_id) === String(ids_in_order[i]);
                })[0];
                if(entry_method !== undefined) {
                    entry_method.sort_order = i;
                    new_entry_methods.push(entry_method);
                }
            }catch (e) {
                console.log("error update entry methods",e)
            }
        }
        //prevent updates from firing if no changes

        if(entry_methods_in_order.length === 0){
            entry_methods_in_order = new_entry_methods;
        }
        //moved to hear so the preview updates
        setContest("entry_methods", entry_methods_in_order);
        if(entry_methods_in_order===new_entry_methods)
            return;
        let post_data = {
            contest_id: contest.contest_id,
            ordered_ids: ids_in_order
        }
        await req("POST", "/contest/set_entry_methods_order/", post_data);

        //update entry methods in page
    }
    const [editing_form, set_editing_form] = useState([]);
    const show_entry_method_editor = async (entry_type, entry_method = false) => {
        console.log("show_entry_method_editor", entry_type, entry_method);
        //chooses the type of form to show
        if (entry_type.crud_form === 'NewsletterEntry') {
            set_editing_form([<NewsletterEntry key={0} entry_type={entry_type} entry_method={entry_method} {...args}/>]);
        }
        if (entry_type.crud_form === 'EmbedAndGo') {
            set_editing_form([<EmbedAndGo  key={1} entry_type={entry_type} entry_method={entry_method}  {...args}/>]);
        }
        if (entry_type.crud_form === 'ShareEntry') {
            set_editing_form([<ShareEntry  key={2} entry_type={entry_type} entry_method={entry_method}  {...args}/>]);
        }
        if (entry_type.crud_form === 'WatchToWin') {
            set_editing_form([<WatchToWin  key={3} entry_type={entry_type} entry_method={entry_method}  {...args}/>]);
        }
        if (entry_type.crud_form === 'FormCrud') {
            set_editing_form([<FormCrud  key={4} entry_type={entry_type} entry_method={entry_method}  {...args}/>]);
        }
        setSelectTab(2);
    }
    let args = {update_entry_method,delete_entry_method,entry_methods,entry_types,contest,update_entry_method_order,show_entry_method_editor};





    //sets in page entry methods
    const set_entry_methods = (new_entry_methods) => {
        let nbr_of_legit_methods  = new_entry_methods.filter(element => parseInt(element.entry_type) > 0);
        set_nbr_of_entry_methods(nbr_of_legit_methods.length);
        let init_selected_tab = 0;
        if (new_entry_methods.length)
            init_selected_tab = 1;
        setSelectTab(init_selected_tab);
        set_entry_methods_state(new_entry_methods);
        setContest("entry_methods", new_entry_methods,false);
    }
    let entry_methods_tab_mounted = false;
    useEffect(() => {
        if (entry_methods_tab_mounted)
            return
        entry_methods_tab_mounted = true;
        get_entry_types();//load entry types
        get_entry_methods();//load entry methods
    }, []);

    return (<>
        <ConfirmProvider>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectTab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab key={0} label="Add entry method" {...a11yProps(0)} />
                        <Tab key={1} label={"Edit entry methods (" + nbr_of_entry_methods + ")"} {...a11yProps(1)} />
                        <Tab key={2} className={"d-none"} label={""} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel className={"no-side-padding-panel"} value={selectTab} index={0}>
                    <AddEntryMethods {...args}/>
                </TabPanel>
                <TabPanel  className={"no-side-padding-panel"} value={selectTab} index={1}>
                    <EditEntryMethods {...args}/></TabPanel>
                <TabPanel  className={"no-side-padding-panel"} value={selectTab} index={2}>
                    {editing_form}
                </TabPanel>

            </Box>
        </ConfirmProvider>
    </>);
}


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box sx={{p: 3}}>
                {children}
            </Box>)}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

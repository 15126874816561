import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useState} from "react";
import {form_obj, req} from "../../CommonLibrary";

export default function  EntryMethods(){
    let editableEntryTypes =[];
    const[editing,setEditing]=useState(false);
    const[entryTypeName,setEntryTypeName]=useState('');
    const[crud_form,setCrud_form]=useState('');
    const[sanitize_definition,setSanitize_definition]=useState('');
    const[render_type,setRender_type]=useState('react');
    const[form_properties,set_form_properties]=useState('');
    const [icon,setIcon] = useState('');
    const handleCreateEntryType = async (event) => {
        event.preventDefault();
        let data =  form_obj(event.target);
        let result = await req("POST","/tank/set_entry_type",data);
    }
    const [entry_types,set_entry_types] = useState([]);
    const  editEntryType = (entry_type_id) => {
        //set editor with data
        const entry_method = editableEntryTypes.filter(obj => {
            return String(obj.id) === String(entry_type_id);
        });
        setEntryTypeName(entry_method[0].name);
        setCrud_form(entry_method[0].crud_form);
        setSanitize_definition(JSON.stringify(entry_method[0].sanitize_definition));
        setEditing(entry_method[0].id);
        set_form_properties(JSON.stringify(entry_method[0].form_properties));
        setIcon(entry_method[0].icon);
        //setRender_type(entry_method[0]?.render_type);
    }
    const get_entry_types = async () => {
        editableEntryTypes = await req("GET","/tank/get_entry_types");
        let entry_type_list = editableEntryTypes.map((elm,ind)=> {
            return <Button key={ind} variant="outlined" className={'w-100 mb-3'} onClick={()=>{editEntryType(elm.id)}}>Edit entry type #{elm.id} => {elm.name}</Button>;
        });
        set_entry_types(entry_type_list);
    }
    const setCreateNewEntryType = () => {
        setEntryTypeName('');
        setCrud_form('');
        setSanitize_definition('');
        setEditing(false);
    }
    const launchFormEditor = () => {
        window.open('/s/form_editor','_blank');
    }



    useEffect(()=>{
        get_entry_types();
    },[]);
    return(    <div className={'container'}>
        {editing!==false && <Button  variant="outlined" className={'w-100 mb-3'} onClick={()=>{setCreateNewEntryType()}}>Create new type</Button>}
        <Grid container={true}  spacing={2}>
            <Grid item={true} xs={12} sm={6}>

                <h3>{(editing===false) ? <>Create entry type</> : <>Edit entry type</> }</h3>
                <form onSubmit={handleCreateEntryType}>
                    {editing!==false && <input type={'hidden'} name={'id'} value={editing}/>}
                    <TextField name="name" value={entryTypeName} onChange={(e)=>{setEntryTypeName(e.target.value)}} label="Entry type name"  className={'mb-4 w-100'} variant="outlined"/>
                    <Button type={'button'} variant="outlined" onClick={launchFormEditor} className={'w-100 mt-3 mb-1'}>Add/Edit form</Button>
                    <FormControl fullWidth className={'mt-1 mb-1'}>
                        <InputLabel id="render-type-label">Render type</InputLabel>
                        <Select
                            labelId="render-type-label"
                            id="render-type-select"
                            name={'render_type'}
                            value={render_type}
                            label="Render type"

                        >
                            <MenuItem value={'form_builder'}>Form Builder</MenuItem>
                            <MenuItem value={'react'}>React</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        name="crud_form"
                        label="Create form definition"
                        multiline
                        value={crud_form}
                        onChange={(e)=>{setCrud_form(e.target.value)}}
                        rows={6}
                        className={'mb-4 w-100'}
                    />
                    <TextField
                        name="form_properties"
                        label="Form properties"
                        multiline
                        value={form_properties}
                        onChange={(e)=>{set_form_properties(e.target.value)}}
                        rows={6}
                        className={'mb-4 w-100'}
                    />

                    <TextField
                        name="sanitize_definition"
                        label="Definition of data structure"
                        multiline
                        value={sanitize_definition}
                        onChange={(e)=>{setSanitize_definition(e.target.value)}}
                        rows={6}
                        className={'mb-4 w-100'}
                    />
                    
                    <TextField
                        name="icon"
                        label="SVG icon"
                        multiline
                        value={icon}
                        onChange={(e)=>{setIcon(e.target.value)}}
                        rows={6}
                        className={'mb-4 w-100'}
                    />
                    <button type={"submit"} className={'btn btn-primary w-100'}>{(editing===false) ? <>Create entry type</> : <>Edit entry type</> }</button>
                </form>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
                <h3>Entry types</h3>
                {entry_types}
            </Grid>
        </Grid>
    </div>)
}

import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import React, {useEffect, useState} from "react";

import {form_obj, req} from "../../../CommonLibrary";
import PrizePickers from "./PrizePickers";
import * as C from "../../../CommonLibrary";
import {toast} from 'react-toastify';
import PrizeUploader from "./PrizeUploader";
import {FileIcon} from "react-file-icon";
import ContestWysiwyg from "../ContestWysiwyg";

export default function EditPrizeForm(props) {

    const {contest, prize, removePrize, load_prizes, setPrize, prizes} = props;
    const prize_id = prize.prize_id;
    const [prizeType, setPrizeType] = React.useState(prize.prize_type);
    const [prizeDescription, setPrizeDescription] = React.useState(prize.description);
    const [prizeName, setPrizeName] = React.useState(prize.name);
    const [prizePickers, setPrizePickers] = React.useState([]);
    const [prizePickersData, setPrizePickersData] = React.useState(prize.pickers);
    const [downloadable_prize, set_downloadable_prize] = React.useState(prize.description);
    const [downloadable_prize_extension, set_downloadable_prize_extension] = React.useState('');
    const [short_file_name, set_short_file_name] = React.useState('');
    const handlePrizeTypeChange = (event) => {
        setPrizeType(event.target.value);
    }
    const handle_wysiwyg_change = (content) => {
        setPrizeDescription(content);
    }
    const addPrizePicker = () => {
        let new_index = prizePickers.length;

        let new_prizes = [...prizePickers,
            <PrizePickers updatePicker={updatePicker} picker={{
                nbrWinners: 1,
                pickAt: "end",
                pickDate: new Date().getTime()
            }}
                          removePicker={removePicker} key={new_index}
                          index={new_index}/>];
        setPrizePickers(new_prizes);
        setPrizePickersData([...prizePickersData, {}]);
    }
    const savePrize = async (event) => {
        event.preventDefault();

        let data = {
            contest_id: contest.contest_id,
            prize_data: form_obj(event.target)
        };
        data.prize_data.prize_description = prizeDescription;
        console.log("prize to save", data.prize_data, prizeDescription);
        ;
        data.prize_data.pickers = prizePickersData;
        data.prize_data.prize_id = prize_id;
        set_show_prize_editor(false);
        await C.req('POST', '/contest/set_prize/', data);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        load_prizes();
        toast("Prize saved");

    }
    const updatePicker = (index, data) => {
        let updated_pickers = prizePickersData;
        updated_pickers[index] = data;
        setPrizePickersData(updated_pickers);
    }
    const removePicker = (index) => {
        let new_pickers = prizePickers;
        new_pickers.splice(index, 1);

        setPrizePickers(new_pickers);
        let pickers = prizePickersData;
        pickers.splice(index, 1);
        setPrizePickersData(pickers);
    }

    const init_prize_pickers = () => {
        let prize_pickers = [];
        for (let i = 0; i < prizePickersData.length; i++) {

            prize_pickers.push(<PrizePickers updatePicker={updatePicker}
                                             removePicker={removePicker} key={i}
                                             picker={prizePickersData[i]}
                                             index={i} data={prize.pickers[i]}/>);
        }
        setPrizePickers(prize_pickers);
        if (!prize_pickers.length) {
            addPrizePicker();
        }
    }

    useEffect(() => {
        init_prize_pickers();
        update_downloadable_prize(prize.downloadable_prize);
    }, [])
    const [show_prize_editor, set_show_prize_editor] = useState(false);
    const toggle_edit_prize = () => {
        set_show_prize_editor(!show_prize_editor);
    }
    const update_downloadable_prize = (file_location) => {
        if (!file_location || file_location === '')
            return;
        if (file_location === 'delete')
            file_location = '';
        let file_name = file_location.split('/').pop();
        file_name = file_name.split('-').pop();
        let file_extension = file_name.split('.').pop();
        set_downloadable_prize(file_location);
        set_downloadable_prize_extension(file_extension);
        set_short_file_name(file_name);
    }
    const delete_downloadable_prize = async (file) => {
        await req("POST", "/contest/delete_prize_file/", {
            file: file,
            contest_id: contest.contest_id,
            prize_id: prize_id
        });
        let temp_prizes = prizes;
        for (let i = 0; i < temp_prizes.length; i++) {
            if (temp_prizes[i].prize_id === prize_id) {
                temp_prizes[i].downloadable_prize = '';
            }
        }
        setPrize(temp_prizes);
        update_downloadable_prize('delete');
    }
    return (
        <>
            <form onSubmit={savePrize}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={8}>
                        <TextField id="outlined-basic" onChange={(event) => {
                            setPrizeName(event.target.value)
                        }} defaultValue={prizeName} name={'name'} className={'w-100'}
                                   label="Prize name"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="prize-type-label">Prize type</InputLabel>
                            <Select
                                name={'prize_type'}
                                labelId="prize-type-label"
                                label="Prize type"
                                defaultValue={prizeType}
                                onChange={handlePrizeTypeChange}
                            >
                                <MenuItem value={'physical'}>Physical</MenuItem>
                                <MenuItem value={'digital'}>Digital</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {prizeType === 'digital' &&
                    <Grid container={true} className={'pt-3 pb-3'} spacing={2}>
                        <Grid item={true} xs={8}>
                            {downloadable_prize ?
                                <div className={'downloadable-file-area'}>

                                    <FileIcon extension={downloadable_prize_extension}/>
                                    <Button type={'button'}
                                            onClick={() => delete_downloadable_prize(downloadable_prize)}
                                            color="error">Delete {short_file_name}</Button>
                                    <input type={'hidden'} name={'downloadable_prize'} value={downloadable_prize}/>
                                </div>
                                :
                                <PrizeUploader update_downloadable_prize={update_downloadable_prize}
                                               contest_id={contest.contest_id}
                                               url={"http://127.0.0.1:8787/contest/upload/"}/>
                            }
                        </Grid>
                        <Grid item={true} xs={4}>
                            <TextField label="Max nbr downloads" name={'max_nbr_downloads'}
                                       variant="outlined"
                                       className={'max-downloads'}
                                       defaultValue={3}
                                       type="number"/>
                        </Grid>
                    </Grid>
                }

                <Grid container={true} className={'pt-3 pb-3'} spacing={2}>
                    <Grid item={true} xs={12}>
                        <ContestWysiwyg contest_id={contest.contest_id} change_handler={handle_wysiwyg_change}
                                        initial_value={prize.prize_description}/>
                    </Grid>
                    <div id={'prize-pickers'}></div>
                </Grid>
                {prizePickers}
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                        <button onClick={addPrizePicker} type={"button"}
                                className={'btn btn-link btn-sm'}>Add
                            another prize picker
                        </button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button fullWidth={true} variant={"outlined"} type={'submit'}>Update prize</Button>
                    </Grid>
                </Grid>

            </form>


        </>
    );
}

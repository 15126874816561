import React from 'react';
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";

export function BottomBar(props) {
    const {contest, setContest} = props;
    const [showLogin, setShowLogin] = React.useState(contest.layout.show_login);
    const [showPrivacy, setShowPrivacy] = React.useState(contest.layout.show_privacy );
    const [showBug, setShowBug] = React.useState(contest.layout.show_bug);

    const updatePrivacy = (event) => {
        let value = event.target.checked;
        setContest('layout.show_privacy', value);
        setShowPrivacy(value)
    }
    const updateLogin = (event) => {
        let value = event.target.checked;
        setContest('layout.show_login', value);
        setShowLogin(value)
    }
    const updateBug = (event) => {
        let value = event.target.checked;
        setContest('layout.show_bug', value);
        setShowBug(value)
    }
    return (
        <Grid container={1}>
            <Grid item={1} xs={12} sm={4}>
                <FormGroup>
                    <FormControlLabel labelPlacement={"bottom"}
                                      control={<Switch onChange={updateLogin}
                                                       checked={showLogin}/>} label="Show Login/Logout"/>
                </FormGroup>
            </Grid>
            <Grid item={1} xs={12} sm={4}>
                <FormGroup>
                    <FormControlLabel labelPlacement={"bottom"}
                                      control={<Switch onChange={updatePrivacy}
                                                       checked={showPrivacy} label="Show Privacy"/>} label="Show Privacy"/>
                </FormGroup>
            </Grid>
            <Grid item={1} xs={12} sm={4}>
                <FormGroup>
                    <FormControlLabel labelPlacement={"bottom"} control={<Switch  onChange={updateBug}
                                      checked={showBug} defaultChecked/>} label="Show Bug"/>
            </FormGroup>
        </Grid>

</Grid>)
    ;
}

import './App.css';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider, RequireAuth} from "react-auth-kit";
import refreshApi from "./refreshApi";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import SignIn from "./pages/SignInSide";
import Nav from "./components/Nav";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Account from "./pages/signed_in/Account";
import ClassicContest from "./pages/signed_in/ClassicContest";
import Tank from "./pages/signed_in/Tank";
import HomePage from "./pages/signed_in/HomePage";
import {ToastContainer} from "react-toastify";
import PassReset from "./pages/PassReset";
import {Storage} from "./CommonLibrary";
import VerifySendingEmail from './pages/VerifySendingEmail';

//todo use system setting or cookie value
//theme stuff
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    name: 'dark',
});
const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    name: 'light',
});
const getActiveTheme = () => {
    let store = new Storage();
    function getDeviceTheme() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'dark';
        } else {
            return 'light';
        }
    }
    let stored_theme = store.get("rf_theme");
    return stored_theme ? stored_theme: getDeviceTheme(darkTheme);
}


function App() {
    let active_theme = getActiveTheme();
    const [theme, setTheme] = React.useState(darkTheme);
    useEffect(() => {
        if(active_theme === 'dark'){
            setTheme(darkTheme);
        }else{
            setTheme(lightTheme);
        }
    },[]);
    return (
        <AuthProvider authType={'localstorage'}
                      authName={'_auth'}
                      refresh={refreshApi}
        >
            <ThemeProvider  theme={theme}>
                <CssBaseline />
                <header className="App-header">
                    <Nav darkTheme={darkTheme} lightTheme={lightTheme} theme={theme} setTheme={setTheme}/>
                </header>
                <Router >
                    <div className="App">
                        <Routes>
                            {/* playing with auth, not complete yet */}
                            <Route path="/login"
                                   element={<SignIn theme={darkTheme}
                                       title="Sign into Rewards Fuel."/>}/>
                            <Route path={'/s/account'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Account/>
                                </RequireAuth>
                            }/>
                            <Route path={'/s/home'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <HomePage/>
                                </RequireAuth>
                            }/>
                            <Route path={'/s/cc/:id'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <ClassicContest  />
                                </RequireAuth>
                            }/>
                            <Route path={'/s/tank/'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Tank/>
                                </RequireAuth>
                            }/>
                            {/* end playing with auth, not complete yet */}
                            <Route path={'/verify_sending_address'} element={
                            <RequireAuth loginPath={'/login'}>
                                <VerifySendingEmail/>
                            </RequireAuth>
        
                            }/>
                            <Route path="/reset-password"
                                   element={<PassReset
                                       title="Reset your password."/>}/>
                                       
                        </Routes>
                    </div>
                </Router>
            </ThemeProvider>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme={theme.name}
            />
        </AuthProvider>
    );
}

export default App;

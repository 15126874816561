import {form_obj, req} from "../../CommonLibrary";
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {ButtonGroup, Modal, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function Contests() {
    const [rfContests, setRfContests] = useState([]);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const get_contests = async () => {
        let url = "/tank/get_contests";
        let result = await req("GET", url);
        setRfContests(result);
    }
    const handleDeleteContest = async (id) => {

    }
    const handleEditContest = async (id) => {

    }
    const handleVisitContest = async (id) => {

    }
    const handleGetContestData = async (event) => {
        let id = event.currentTarget.dataset.id
        console.log("handleGetContestData",id);
        let contest_data = await req("GET", `/contest/get_do_props/?contest_id=${id}`);
        console.log("contest_data",contest_data);
    }
    const ContestButtonsColumnRenderer = (data) => {
        let id = data.row.contest_id;
        return (
            <ButtonGroup variant="outlined" fullWidth={true}>
                <Button  data-id={id}  onClick={handleDeleteContest}>
                    Delete
                </Button>
                <Button  data-id={id}  onClick={handleGetContestData}>
                    Get
                </Button>
                <Button   data-id={id} onClick={handleVisitContest}>
                    Visit
                </Button>
                <Button   data-id={id}  onClick={handleEditContest}>
                    Edit
                </Button>
            </ButtonGroup>
        );
    };
    const columns = [
        {field: 'contest_id', headerName: 'ID', flex: 0.25,
            minWidth: 50,},
        {
            field: 'contest_name',
            headerName: 'Contest name',
            flex: 0.2,
            minWidth: 50,
            editable: true,
        },
        {
            field: 'contest_type',
            headerName: 'Contests type',
            flex: 0.2,
            minWidth: 50,
            editable: true,
        },

        {
            field: 'created_at',
            headerName: 'Created',
            flex: 0.2,
            minWidth: 50,
            valueGetter: (params) => new Date(params.row.created_at*1000).toLocaleString()

        },
        {
            field: 'tracking_codes',
            headerName: 'Actions',
            flex: 0.2,
            minWidth: 50,
            renderCell: ContestButtonsColumnRenderer,

        },

    ];
    let mounted = false;
    useEffect(() => {
        if(!mounted){
            get_contests();
            mounted = true;
        }
    }, []);
    const [modalOpen, setModalOpen] =useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const create_contest = async (event) => {
        event.preventDefault();
        let data =  form_obj(event.target);
        let result = await req("POST","/tank/set_contest/",data);
        handleClose();
        get_contests();
    }
    const deleteSelected= async () => {
        if(prompt("Type 'delete' to delete selected contest(s)") === "delete"){
            let result = await req("POST","/tank/delete/",{delete:selectedRow});
            get_contests();
        }
    }
    return(<>
        <Button type={'button'} onClick={handleOpen}>Create contest</Button>
        {selectedRow && <Button className={'btn-danger'} type={'button'} onClick={deleteSelected}>Delete selected</Button>}
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
            <form onSubmit={create_contest}>
                <TextField id="outlined-basic" name="contest_name" className={"mb-3 w-100"} label="Contest name" variant="outlined" />
                <FormControl fullWidth  className={"mb-3 w-100"}>
                    <InputLabel id="demo-simple-select-label">Contest Type</InputLabel>
                    <Select
                        name="contest_type"
                        labelId="demo-simple-select-label"
                    >
                        <MenuItem value="classic_contest">Classic</MenuItem>
                        <MenuItem value="listen_and_win">Listen and win</MenuItem>
                        <MenuItem value="form">Form</MenuItem>
                    </Select>
                </FormControl>

                <Button type={'submit'} >Create contest</Button>
            </form>
            </Box>
        </Modal>
        {rfContests.length > 0  &&
            <Box sx={{height: '100vh', width: '100%'}}>
            <DataGrid
                rows={rfContests}
                columns={columns}
                pageSize={100}
                autoWidth={true}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectedRow(newSelectionModel);
                }}
                experimentalFeatures={{newEditingApi: true}}
            />
        </Box>
    }</>);
}





import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Flag from 'react-flagkit';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import * as React from 'react';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import {Chip} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HoverPop from "../HoverPop";
export default function NewsletterEntries(props) {

    const {contest,newsletterEntries} = props;
    const [newsletterEntriesData,setNewsletterEntriesData] = React.useState(newsletterEntries);
    const format_contestant = (data) => {
        let name = data.row?.name
        let email = data.row?.email
        let letter  = name?.charAt(0).toUpperCase();
        let hover = `Name: ${name} Email: ${email}`;
        return (<HoverPop hoverValue={hover}><Chip  avatar={<Avatar>{letter}</Avatar>} label={name} /></HoverPop>);
    }


    const rederDoubleOpt = (data) => {

        let double_opt = <RemoveDoneIcon color="error" />;
        if(data.row.double_opt_in)
            double_opt = <><DoneAllIcon color="success" /><Flag country={data.row.double_opt_in_location.country
            }/></>;
        let hover = (<div>
            <div className={"p-2"}>Confirmed: {new Date(data.row.verification_time).toLocaleString()}</div>
            <div className={"p-2"}>Location: {data.row.double_opt_in_location.city}, {data.row.double_opt_in_location.region
            }, {data.row.double_opt_in_location.country}</div>
        </div>);
        return (<HoverPop hoverValue={hover}><Chip  avatar={<Avatar>{double_opt}</Avatar>} /></HoverPop>);
    }
    const renderExported = (data) => {
        return data.row.exported ? <CheckCircleOutlineIcon color="success" /> : <OpenInNewOffIcon color="error" />;
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Contestant', flex: 0.3,
            minWidth: 50,
            renderCell: format_contestant,
            align: 'left',
        },
        { field: 'double_opt_in',align: 'left', headerName: 'Double Opt', renderCell: rederDoubleOpt,minWidth: 80, flex: 0.35},
        { field: 'exported', align: 'left',headerName: 'Exported',minWidth: 50, flex: 0.1, renderCell: renderExported },
    ];



    return( <>{newsletterEntriesData.length ?
        <DataGrid
            rows={newsletterEntries}
            components={{ Toolbar: GridToolbar }}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
        />:<h1>No entries yet</h1>}

    </>)
}







import React from 'react';
import {upload} from '../../../../CommonLibrary';
import ImageUploading from 'react-images-uploading';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//todo style it  like this  https://dribbble.com/shots/14147113-Upload-Files-App/attachments/5772136?mode=media
export function Images(props) {
    const {contest, setContest} = props;
    let formattedImages = [];
    if (contest?.layout?.header_images && typeof contest.layout.header_images === "object") {
        formattedImages = contest?.layout?.header_images?.map((elm, ind) => {
            return {data_url: elm};
        })
    }

    const [images, setImages] = React.useState(formattedImages);
    const maxNumber = 6;
    const uploadImage = async (img_obj) => {
        let url = '/contest/upload/?contest_id=' + contest.contest_id;
        ;
        return await upload(url, img_obj.file, contest.contest_id, 'image');
    }
    const onChange = async (imageList, addUpdateIndex) => {
        // data for submit
        let new_file = false;
        if (imageList[addUpdateIndex] && typeof imageList[addUpdateIndex] === "object" && imageList[addUpdateIndex]?.data_url.includes('data:image'))
            new_file = await uploadImage(imageList[addUpdateIndex]);
        if (new_file)
            imageList[addUpdateIndex].data_url = new_file.location;
        setImages(imageList);
        setContest('layout.header_images', imageList?.map((elm, ind) => {
            return elm.data_url;
        }))
    };


    const [editing, setEditing] = React.useState(false);
    const [checked, setHideTitle] = React.useState(contest.layout.hide_title);


    return (
        <>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,

                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">

                        <button className={'btn-uploader'}
                                style={isDragging ? {color: 'red'} : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                        >
                            <FileUploadIcon/>
                            <div>Upload your files here, click or drop.</div>
                        </button>
                        <div className='position-relative'>
                            {imageList?.map((image, index) => (
                                <div key={index} className="image-item">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <img src={image['data_url']} onClick={() => onImageUpdate(index)} alt=""
                                                 width="50"/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant={'outlined'} title={"Swap image"}
                                                    onClick={() => onImageUpdate(index)}><SwitchAccountIcon/></Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant={'outlined'} title={"Remove image"}
                                                    onClick={() => onImageRemove(index)}><DeleteIcon/></Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                            <Button className={"mt-3"} variant={'outlined'} onClick={onImageRemoveAll}>Remove all
                                images</Button>

                        </div>
                    </div>
                )}
            </ImageUploading>

            <div>If you upload multiple banners they are turned into a slide show.</div>
        </>)

}

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormHelperText, ListItemText} from "@mui/material";
import ContestWysiwyg from "../../ContestWysiwyg";
import Button from "@mui/material/Button";
import {form_obj} from "../../../../CommonLibrary";
import * as React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const share_options = [
    {key:0, name:"Email", react: "EmailShareButton"},
    {key:1, name:"Facebook", react: "FacebookShareButton"},
    {key:3, name:"Line", react: "LineShareButton"},
    {key:4, name:"Linkedin", react: "LinkedinShareButton"},
    {key:5, name:"Livejournal", react: "LivejournalShareButton"},
    {key:7, name:"Ok", react: "OKShareButton"},
    {key:8, name:"Pinterest", react: "PinterestShareButton"},
    {key:9, name:"Pocket", react: "PocketShareButton"},
    {key:10, name:"Reddit", react: "RedditShareButton"},
    {key:11, name:"Telegram", react: "TelegramShareButton"},
    {key:12, name:"Tumblr", react: "TumblrShareButton"},
    {key:13, name:"Twitter", react: "TwitterShareButton"},
    {key:14, name:"Viber", react: "ViberShareButton"},
    {key:16, name:"Whatsapp", react: "WhatsappShareButton"},
    {key:17, name:"Workplace", react: "WorkplaceShareButton"},
    {key:18, name:"Twitter", react: "TwitterIcon"},
    {key:19, name:"Line", react: "LineIcon"},
    {key:20, name:"Telegram", react: "TelegramIcon"},

];
export default function ShareEntry(props) {
    const {entry_type, entry_method, update_entry_method, contest} = props;
    //todo prefill all defaults and use entry method if update
    let editing = false;
    let addEdit = "Add";

    //let default_email_body =  entry_method?.body ? entry_method?.body : entry_type.form_properties.email_message;
    //let default_subject = entry_method?.subject ? entry_method?.subject : entry_type.form_properties.subject;
    //let default_sending_email = entry_method?.sending_email_id ? entry_method?.sending_email_id : entry_type.form_properties?.sending_email_id;
    if (entry_method) {
        editing = true;
        addEdit = "Edit";
    }
    let defaults = entry_type.form_properties;

    const [share_title, setShareTitle] = useState(entry_method?.share_title ? entry_method?.share_title : defaults.share_title);
    let shareDescription = entry_method?.share_body ? entry_method?.share_body : defaults.share_body;
    let description =entry_method?.description ? entry_method?.description : defaults.description;
    let howItWorks = entry_method?.how_it_works ? entry_method?.how_it_works : defaults.how_it_works;
    const [points, setPoints] = useState(entry_method?.entry_points ? entry_method?.entry_points : defaults.entry_points);
    const updateShareDescription = (value) => {
        shareDescription = value;
    }
    const updateDescription = (value) => {
        description =value;
    }
    const updateHowItWorks = (value) => {
        howItWorks =value;
    }


    let form_data_needed = {
        "description": "Sharing improves your odds of winning a prize. The more you share, the more you win!",
        "how_it_works": "When you share your link we track any new contestants who enter the contest.  For every contestant who enters the contest from your link we'll give you entries into the contest.",
        "entry_points": 5,
        "button_label": "Share to win",
        "entry_type": 47,
        "sort_order": 0,
        "renderer": "ShareEntry",
        "share_icons": [],
        "share_title": "Enter our contest and win",
        "share body": "Win amazing prizes with our contest",
        "share_image": "",
        "rules": ["is_live"]
    }



    const handleAddEditShareEntry = (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        data.contest_id = contest.contest_id;
        data.description = description;
        data.how_it_works = howItWorks;
        data.share_body = shareDescription;
        data.share_icons = get_share_services();
        update_entry_method(data);
    }
    const get_share_services = () => {
        let returndata = [];
        for(const share of shareServicesSelected) {
            //get full element from share_options
            returndata.push(share_options.find((element) => element.name === share));
        }
        return returndata;
    }


    let initalValue = entry_method?.share_icons ? entry_method?.share_icons : defaults.share_icons;
    const [shareServicesSelected, setShareServicesSelected] = useState(initalValue.map(elm=>elm.name));

    const [shareIcons,setShareIcons] = useState(share_options.map(elm=>elm.name));
    const handleUpdateShareIcons = (event) => {
        setShareServicesSelected(event.target.value);
    }
    return (<form onSubmit={handleAddEditShareEntry}>
        {editing && <input type={'hidden'} name={'entry_method_id'} value={entry_method.entry_method_id}/>}
        <input type={'hidden'} name={'entry_type'} value={entry_type.id}/>
        <Grid container={1} spacing={2}>
            <Grid item={1} xs={8}> <FormControl fullWidth={1} >
                <InputLabel id="share-icons-select-label">Share buttons to include</InputLabel>
                <Select
                    labelId="share-icons-select-label"
                    id="share-icons-select"
                    multiple
                    value={shareServicesSelected}
                    onChange={handleUpdateShareIcons}
                    input={<OutlinedInput label="Share buttons to include" />}
                    renderValue={(selected) =>{console.log("selected",selected); return selected.join(', ')}}
                    MenuProps={MenuProps}
                >
                    {shareIcons.map((elm) => (
                        <MenuItem key={elm} value={elm}>
                            <Checkbox checked={shareServicesSelected.indexOf(elm) > -1} />
                            <ListItemText primary={elm} />
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>The share buttons displayed under your share link</FormHelperText>
            </FormControl>
            </Grid>
            <Grid item={1} xs={4}><TextField type={"number"} fullWidth={1} defaultValue={points} name={"entry_points"}
                                             label={"Entries per share"} variant={"outlined"}/> </Grid>
            <Grid item={1} xs={12}><TextField fullWidth={1} name={"share_title"} label={"Share title"}
                                              value={share_title} onChange={(e) => setShareTitle(e.target.value)}
                                              helperText="The main headline that will appear when they share your link"
                                              variant={"outlined"}/> </Grid>
            <Grid item={1} xs={12}>Share image </Grid>
            <Grid item={1} xs={12}>
                <Accordion>
                    <AccordionSummary>Share description</AccordionSummary>
                    <AccordionDetails><ContestWysiwyg initial_value={shareDescription}
                                                      change_handler={updateShareDescription}/> </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item={1} xs={12}>
                <Accordion>
                    <AccordionSummary>Above share box description</AccordionSummary>
                    <AccordionDetails><ContestWysiwyg initial_value={description}
                                                      change_handler={updateDescription}/> </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item={1} xs={12}>
                <Accordion>
                    <AccordionSummary>Describe how sharing works</AccordionSummary>
                    <AccordionDetails><ContestWysiwyg initial_value={howItWorks}
                                                      change_handler={updateHowItWorks}/> </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item={1} xs={12}>
                <Button variant={"outlined"} fullWidth={1} type={"submit"}>{addEdit} Share entry</Button>
            </Grid>
        </Grid>
    </form>);
}


import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {form_obj, req} from "../../../CommonLibrary";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Chip, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import HoverPop from "../HoverPop";
import {toast} from "react-toastify";

export default function WinnersTable(props) {
    const {contest,contestants} = props;
    const [rows,setRows] =useState([]);
    const [winnerModalOpen, setWinnerModalOpen] = React.useState(false);
    const [pickingMethod, setPickingMethod] = React.useState('random');
    const [pickedWinners, setPickedWinners] = React.useState([]);

    /*data we get back from winners data
{
    "contestant_id": "contestant_rwkemp@gmail.com",
    "contestant":{name: "Robert Kemp", email: "rwkemp@gmail.com", contestant_id: "contestant_rwkemp@gmail.com"},
    "time_picked": 1672612887434,
    "winning_entry": {
      "contestant_id": "contestant_rwkemp@gmail.com",
      "entry_points": "5",
      "time_of_entry": 1672355566384,
      "entry_method_id": "entry_method_1672331300764",
      "all_data": {
        "longitude": "-105.21600",
        "latitude": "20.67070",
        "tlsCipher": "AEAD-AES128-GCM-SHA256",
        "continent": "NA",
        "asn": 8151,
        "clientAcceptEncoding": "gzip, deflate, br",
        "country": "MX",
        "tlsClientAuth": {
          "certIssuerDNLegacy": "",
          "certIssuerSKI": "",
          "certSubjectDNRFC2253": "",
          "certSubjectDNLegacy": "",
          "certFingerprintSHA256": "",
          "certNotBefore": "",
          "certSKI": "",
          "certSerial": "",
          "certIssuerDN": "",
          "certVerified": "NONE",
          "certNotAfter": "",
          "certSubjectDN": "",
          "certPresented": "0",
          "certRevoked": "0",
          "certIssuerSerial": "",
          "certIssuerDNRFC2253": "",
          "certFingerprintSHA1": ""
        },
        "tlsExportedAuthenticator": {  },
        "tlsVersion": "TLSv1.3",
        "colo": "DFW",
        "timezone": "America/Mexico_City",
        "city": "Puerto Vallarta",
        "edgeRequestKeepAliveStatus": 1,
        "requestPriority": "",
        "httpProtocol": "HTTP/3",
        "region": "Jalisco",
        "regionCode": "JAL",
        "asOrganization": "Telmex",
        "postalCode": "48290"
      }
    },



     */
    const contestantRederrer = (params) => {
        let contestant = params.row.contestant;
        let name =contestant?.name || contestant?.email;
        let letter = name.charAt(0).toUpperCase();
        return (<Chip avatar={<Avatar>{letter}</Avatar>} label={name} />);
    }
    const timePickedRederrer = (params) => {
        let time_picked = params.row.time_picked;
        let date = new Date(time_picked);
        const shortDate = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            ordinal: 'suffix'
        });
       return(<>
           <HoverPop hoverValue={date.toLocaleString()} >{shortDate}</HoverPop>
       </>);
    }
    const winningEntryRederrer = (params) => {
        let winning_entry = params.row.winning_entry_method;
        //return winning_entry.button_label;
        return (<Chip label={winning_entry.button_label} />);
    }
    const prizeRederrer = (params) => {
        //return (<Chip label={"name"} />);
        let prize = params.row.prize;
        return prize?.name || "";
        let prize_name = prize?.prize || "";
        //return prize_name;
        //let prize_value = prize?.prize_value || "";
        return (<Chip label={prize_name} />);
    }
    const columns = [
        {field: 'contestant_id', headerName: 'Winner', minWidth: 70, flex: .2 , renderCell:contestantRederrer},
        {field: 'time_picked', headerName: 'Time Picked',  minWidth: 70, flex: .2, renderCell:timePickedRederrer},
        {field: 'picking_method', headerName: 'Winning Entry',  minWidth: 70, flex: .2, renderCell:winningEntryRederrer},
        {field: 'id', headerName: 'Prize',  minWidth: 70, flex: .2, renderCell:prizeRederrer},
    ];
    const sendWinnerEmail = async () => {
        let data = {
            winners:selectedWinners,
            contest_id:contest.contest_id,
        }
        let url = `/contest/send_prize_claim_email/`
        //todo add loading
        console.log("sending email",data);
        let response = await req("POST",url, data);
        console.log(response);
        if(response)
            toast.success("Emails Sent");
        else
            toast.error("Error Sending Emails");
        //get selected winners
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };//start here working on modal for picking winners

    const handleCloseModalClose = () => setWinnerModalOpen(false);
    const getWinners = async () => {
        let winners = await req('GET', '/contest/get_winners/?contest_id=' + contest.contest_id);
        console.log("winners", winners);
        setRows(winners);
    }
    const pickWinner = async () => {
        setWinnerModalOpen(true);
    }
    const handePickingWinners = async (event) => {
        event.preventDefault();
        let form_data = form_obj(event.target);//data we get from the form {nbr_winners,picking_method,prizes}
        form_data.picked_winners = pickedWinners;
        if (!Array.isArray(form_data.picked_winners)) {
            form_data.picked_winners=[form_data.picked_winners]
        }
        form_data.contest_id=contest.contest_id;
        //show loading screen
        //hide modal
        let result = await req('POST', '/contest/pick_winners/', form_data);
        //show popup with result and a thing saysing winners have been picked, you can  choose what they win or leave the random asignments
        //then notify the winners
    }
    const autoCompleteProps = {
        options: contestants,
        getOptionLabel: (option) => option.name,
    };
    let mounted = false;
    useEffect(() => {
        if (mounted) return;
        mounted = true;
        getWinners();
    }, []);
   const [selectedWinners,setSelectedWinners] = useState([]);
    useEffect(() => {
      let winners = rows.filter((row) => selectedWinners.includes(row.id));
      console.log(winners);
    }, [selectedWinners]);



    return (<>
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}><Button onClick={pickWinner} variant={"outlined"}>Pick winner</Button></Grid>
            <Grid item={true} xs={6}><Button onClick={sendWinnerEmail} variant={"outlined"}>Send prize claim email</Button></Grid>
        </Grid>
        {rows.length > 0 &&
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectedWinners(newSelectionModel);
                }}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
            />
        </div>}
        <Modal
            open={winnerModalOpen}
            onClose={handleCloseModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handePickingWinners}>
                <Grid container={true} spacing={2} sx={style}>
                    <Grid item={true} xs={12}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Pick winners
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-multiple-name-label">Picking method</InputLabel>
                            <Select
                                onChange={(event) => setPickingMethod(event.target.value)}
                                name="picking_method" value={pickingMethod} fullWidth={true}
                                label="Picking method"
                            >
                                <MenuItem value={'random'}>Random</MenuItem>
                                <MenuItem value={'choose'}>I will choose</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {pickingMethod ==="random" ?
                        <Grid item={true} xs={12}>
                            <TextField name="nbr_winners" defaultValue={"1"} fullWidth={true} label="number of winners to pick"
                                       min={1}
                                       type={"number"} variant="outlined"/></Grid>
                        :
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                onChange={(event, value) => setPickedWinners(value)}
                                {...autoCompleteProps}
                                name={"winner222"}
                                disablePortal
                                id="combo-box-demo"
                                options={contestants}
                                multiple={true}
                                fullWidth={true}
                                renderInput={(params) => <TextField {...params} name={"winners"} label="Contestant" />}
                            />
                        </Grid>}
                    <Grid item={true} xs={12}>
                        <Button fullWidth={true} type={"submit"} className={"mt-2 mb-3"} variant={"outlined"}>Pick
                            winner(s)</Button>
                        <Typography id="modal-modal-title" component="p">
                            Click this button to select and assign contestants to the chosen prize(s). You can then
                            update the winners or send notifications by email.
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    </>);
}

import React, {useEffect, useState} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import Grid from "@mui/material/Grid";
import LoginFeatures from "../../components/Account/LoginFeatures";
import AccountSettings from "../../components/Account/AccountSettings";
import ShieldIcon from '@mui/icons-material/Shield';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {req} from "../../CommonLibrary";
//includes from components
import EmailPreferences from '../../components/Account/EmailPreferences';
import AccountLogins from '../../components/Account/AccountLogins.';
import AccountContestants from '../../components/Account/AccountContestants';
import PaymentsTab from '../../components/Account/PaymentsTab';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
export default function Account(args) {
    const [activeTab, setActiveTab] = useState('login');
    const [account,setAccount] = useState(false);
    const [orientation,setOrientation] =useState('vertical');
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }
    const get_account = async () => {
        let result = await req("GET","/account/get");
        console.log("get_account",result);
        setAccount(result);
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const getTabValueFromUrl = () => {
        const hash = window.location.hash.substring(1);
        if (hash.includes("style")) {
            return 0;
        }
        if (hash.includes("settings")) {
            return 1;
        }
        if (hash.includes("entryMethods")) {
            return 2;
        }
        if (hash.includes("winners")) {
            return 3;
        }
        if (hash.includes("stats")) {
            return 4;
        }
        return 0;
    }
    const getTabValueFromId = (id) => {
        if (id === 0) {
            return "style";
        }
        if (id === 1) {
            return "settings";
        }
        if (id === 2) {
            return "entryMethods";
        }
        if (id === 3) {
            return "winners";
        }

        if (id === 4) {
            return "stats";
        }
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            const bookmark = getTabValueFromUrl();
            //if mobile, set orientation to horizontal
            if (window.innerWidth < 600) {
                setOrientation('horizontal');
            }
            get_account();
            if (bookmark > 0)
                setActiveTab(bookmark);
        }
    }, []);

    const buttons = [
        <ToggleButton key="0" value={'account'} onClick={handleTabChange} label="Style" {...a11yProps(0)}><ManageAccountsIcon/>
            <div className={'vert-tab-title'}>Account</div>
        </ToggleButton>,
        <ToggleButton key="5" value={'login'} onClick={handleTabChange} label="Style" {...a11yProps(0)}><ShieldIcon/>
            <div className={'vert-tab-title'}>Login</div>
        </ToggleButton>,
        <ToggleButton key="1" value={'plan'} onClick={handleTabChange}
                      label="Settings" {...a11yProps(1)}><LoyaltyIcon/>
            
        </ToggleButton>,
        <ToggleButton key="2" value={'team'} onClick={handleTabChange} label="Settings" {...a11yProps(2)}><GroupsIcon/>
            <div className={'vert-tab-title'}>Team</div>
        </ToggleButton>,
        <ToggleButton key="3" value={'email'} onClick={handleTabChange}
                      label="Item One" {...a11yProps(3)}><EmojiEventsIcon/>
            <div className={'vert-tab-title'}>Email Preferences</div>
        </ToggleButton>,
        <ToggleButton key="4" value={'contestants'} onClick={handleTabChange}
                      label="Item One" {...a11yProps(4)}><AssignmentIndIcon/>
            <div className={'vert-tab-title'}>Contestants</div>
        </ToggleButton>,
    ];
    if(account===false){
        return(<>loading...</>);
    }
    let props = {
        account,
        get_account,
        ...args
    }
    return (
        <Grid container={true}>
            <Grid item={true} className="text-center w-100" xs={0} sm={1}>
                <ToggleButtonGroup
                    value={activeTab}
                    orientation={orientation}
                    aria-label="vertical outlined button group"
                    className={'vert-tab, mb-3'}

                >
                    {buttons}
                </ToggleButtonGroup>
            </Grid>
            <Grid item={true} xs={0} sm={11}>
                <div className={'container'}>
                    {activeTab === 'account' && <AccountSettings {...props}/>}
                    {activeTab === 'login' && <div><LoginFeatures  {...props}/></div> }
                    {activeTab === 'plan' && <div><PaymentsTab/></div>}
                    {activeTab === 'team' && <div><AccountLogins {...props}/></div> }
                    {activeTab === 'email' && <EmailPreferences {...props}/> }
                    {activeTab === 'contestants' && <AccountContestants {...props}/> }
                </div>
            </Grid>
        </Grid>
    )
}

import FontPicker from "font-picker-react";
import {useState} from "react";

export default function FontSelector(props){
    return (<></>);//causes site to crash
    const [activeFontFamily, setActiveFontFamily] = useState("Arial");
    return(
            <div>
                <FontSelector
                    apiKey="AIzaSyCFHhezXbPfmu0V96NJNOlj4cS9H7Mhobs"
                    onChange={(nextFont) =>setActiveFontFamily({
                            activeFontFamily: nextFont.family,
                        })}
                />
                <p className="apply-font">The font will be applied to this text.</p>
            </div>
        );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import {base_contest_host, req} from "../../CommonLibrary";


export default function ContestMenu(props) {
    const {contest,get_properties} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const delete_contest = async (event) => {
        let contest_id = event.target.getAttribute("data-id");
        if (!contest_id)
            return;
        let result = window.confirm("Are you sure you want to delete this contest?");
        if (result) {
            await req("POST", `/home/delete_contest/?contest_id=${contest_id}`, {property: contest_id});
            get_properties();
        }
    }
    return ( <><Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
    >
        <MenuIcon/>
    </Button>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
            'aria-labelledby': 'basic-button',
        }}
    >



        <Button fullWidth={1} href={`/s/cc/${contest.contest_id}`}>Edit</Button>
        <Button taget="_blank" fullWidth={1} href={`${base_contest_host}${contest.contest_id}`}>Visit</Button>
        <Button fullWidth={1} href={`/s/cc/${contest.contest_id}#stats`}>Stats</Button>
        <Button fullWidth={1} data-id={contest.contest_id}>Copy</Button>
        <Button fullWidth={1} color={"error"} data-id={contest.contest_id} onClick={delete_contest}>Delete</Button>
    </Menu></>)
}

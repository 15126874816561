import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CssEditor from "./CssEditor";
import ColorEditor from "./ColorEditor";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {send_to_preview} from "../../ContestEditorFunctions";
import {req} from "../../../../CommonLibrary";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

export default function CssEditorsAccordion(props) {
    const {contest} = props;
    const handleBsToggle = (event) => {
        if (!event.target.checked)
            send_to_preview('set-css', {remove_bs: true});
        else
            send_to_preview('set-css', {add_bs: true});
        req("POST", "/contest/toggle_bootstrap/", {contest_id: contest.contest_id, add_bs: event.target.checked});
    }
    return (
        <div>
            <Typography className={'mb-3'}>If you want to edit the CSS and Colors CSS directly this is the place to do it. Changes will show up immediately on the preview to the right. Make sure to save your changes</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>CSS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                   <CssEditor {...props}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>CSS Colors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                   <ColorEditor {...props}/>
                </AccordionDetails>
            </Accordion>
            <Divider/>
            <Card className={'mt-3 p-2'}>
                {!contest.layout.remove_bootstrap ?
                    <FormControlLabel control={<Switch defaultChecked onChange={handleBsToggle}/>}
                                      label="Import Bootstrap"/> :
                    <FormControlLabel control={<Switch  onChange={handleBsToggle}/>}
                                      label="Import Bootstrap"/>
                }
                <p>We use two style sheets for contests, one that defines style like borders, shadows, spacing etc. The
                    second style sheet lets you edit colors.</p></Card>
        </div>
    );
}

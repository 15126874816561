import {Container} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import React from "react";
import {toast} from "react-toastify";
const C = require("../CommonLibrary");
export default function PassReset (){
    let url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    let email = url.searchParams.get("email");
    const handlePassUpdate = async (event) => {
        event.preventDefault();
        const data =  C.form_obj(event.currentTarget);
        //verify passwords match
        if(data.new_pass.length <8 || data.new_pass_confirm.length<8){
            toast("Passwords must be at least 8 characters long");
            return;
        }
        if (data.new_pass !== data.new_pass_confirm){
            toast("Passwords do not match");
            return;
        }
        //todo show loader
        console.log("pass reset from token",token);
        let result = await C.req("POST",`/auth/pass_reset_from_token/`,data);
        if(!result.result){
            toast(result.message);
            return;
        }
        else {
            toast("Password updated");
            setTimeout(()=>{window.location.href="/login"},2000);
        }
    }
    if(!token || !email){
        return <div>Invalid token</div>
    }
    return(
        <Container>
            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <h1>Update password</h1>
                </Grid>
                <Grid item={true} s={12}>
                    <Paper elevation={3}>
                        <form className={'p-4'} onSubmit={handlePassUpdate}>
                            <input type={"hidden"} name={"token"} value={token}/>
                            <input type={"hidden"} name={"email"} value={email}/>

                            <TextField fullWidth={true} sx={{mb: 3}} type={"password"} name={'new_pass'} min={8}
                                       label="New password" variant="outlined"/>
                            <TextField fullWidth={true} sx={{mb: 3}} type={"password"} name={'new_pass_confirm'} min={8}
                                       label="Confirm new password" variant="outlined"/>
                            <Button fullWidth={true} variant={"outlined"} type={"submit"}>Update password</Button>
                        </form>
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    );
}

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
export default function PrizeUploader(params){
    const {url,contest_id,update_downloadable_prize} = params;
    // specify upload params and url for your files
    const getUploadParams = ({ file, meta }) => {
        const body = new FormData()
        body.append('contest_id', contest_id);
        body.append('use', 'prize');
        body.append('file', file);
        return { url: url, body }
    }
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file,xhr }, status) => {
        if (status === 'done'){
            update_downloadable_prize(JSON.parse(xhr.response).location);
        }
    }
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    return (
        <Dropzone
            maxFiles={1}
            classNames={'prize-uploader'}
            multiple={false}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxSizeBytes={20971520} /*20mb*/
            onSubmit={handleSubmit}
            accept="image/*,audio/*,video/*,application/pdf,zip/*"
        />
    )
}

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { useState } from "react";
import { form_obj, req } from "../../CommonLibrary";
import { useEffect } from "react";
import { Button } from "@mui/material";

export default function EmailPreferences(props) {
    const [sendingEmailsUx, setSendingEmailsUx] = useState([<h4>You don't currently have any emails to send from.</h4>]);
    const [receivingEmailUx, setReceivingEmailUx] = useState([]);
    const [receviverChanges, setReceviverChanges] = useState(false);
    const getEmailPeferences = async () => {
        const response = await req("GET", "/account/get_email_preferences");
        console.log("getEmailPeferences", response);
        createSendingEmailsUx(response.sendingEmails);
        createReceivingEmailUx(response.receivingEmails);
    }
    const saveReceivingEmails = async (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        console.log("saveReceivingEmails", data);
        const response = await req("POST", "/account/save_receiving_emails", data);
    }
    const addSendingEmail = async (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        console.log("addSendingEmail", data);
        const response = await req("POST", "/account/add_sending_email", data);
        //if(response)
            //getEmailPeferences();
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            getEmailPeferences();
        }
    }, [])
    const createReceivingEmailUx = (receivingEmails) => {
        let receivingEmailsUx = [];
        for (const Receiver of receivingEmails) {
            receivingEmailsUx.push(<TextField onChange={() => setReceviverChanges(1)} name={Receiver.purpose} fullWidth={true} sx={{ mb: 3 }}
                defaultValue={Receiver.email} type={"email"} required={1} label={Receiver.purpose.replace("_", " ")}
                variant="outlined" />);
        }
        console.log("receivingEmailsUx", receivingEmailsUx);
        setReceivingEmailUx(receivingEmailsUx);
    }
    const sendVerification = async (e) => {
        const email = e.target.dataset.email;
        console.log("sendVerification", email);
        const response = await req("POST", "/account/send_email_verification", { email });
        console.log("sendVerification", response);
    }
    const createSendingEmailsUx = (sendingEmails) => {
        let sendingUx=[];
        for(const sender of sendingEmails){
            console.log("sender", sender);
            sendingUx.push(<Grid container={1}>
                <Grid item={1} xs={8}>{sender.name} &lt;{sender.email}&gt;</Grid>
                {sender.verified ? <Grid item={1} xs={4}>Verified</Grid> : <Grid item={1} xs={4}><Button data-email={sender.email} onClick={sendVerification}>Not verifiend, send email</Button></Grid>}
            </Grid>)
        }
        setSendingEmailsUx(sendingUx);
    }

    return (<Grid container className="d-block container mt-1" spacing={2}>
        <Paper className="p-4" elevation={3}>
            <Grid className="pb-4" item xs={12}>
                <h4>Sending addresses</h4>
               <div className="mb-4">
               {sendingEmailsUx}
               </div>
                <form onSubmit={addSendingEmail}>
                    <h4>Add sending email address</h4>
                    <TextField name={"name"} fullWidth={true} sx={{ mb: 3 }}
                        type={"text"} required={1} label={"from name"}
                        variant="outlined" />
                    <TextField name={"email"} fullWidth={true} sx={{ mb: 3 }}
                        type={"email"} required={1} label={"from email"}
                        variant="outlined" />

                    <Button fullWidth={1} variant="contained" color="primary" type="submit">Add sending address</Button>
                </form>
            </Grid>
            <Grid item xs={12}>

                <h4>Receiving addresses</h4>
                <form onSubmit={saveReceivingEmails}>
                    {receivingEmailUx}
                    {receviverChanges && <Button fullWidth={1} variant="contained" color="primary" type="submit">Save changes</Button>}
                </form>
            </Grid>
        </Paper >
    </Grid >)
}

import { Button } from "@mui/material";

export default function Crypto(props){
    //using now payements using this linkk for now but will need to creat a dynamic link
    //todo use popup modal setup ipn etc
    let link = `https://nowpayments.io/payment/?iid=5024307945`;//todo add real BTC address
  return (
    <div>
      <Button href={link} className="w-100" variant="outlined" color="primary">Pay with Crypto</Button>
    </div>
  )
 
}
import { DataGrid } from '@mui/x-data-grid';
import Flag from 'react-flagkit';
import Button from "@mui/material/Button";
import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import {form_obj, req, upload} from "../../../CommonLibrary";
import {toast} from "react-toastify";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import {Chip} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import HoverPop from "../HoverPop";
export default function Entries(props) {

    const {contest,entries,get_entries} = props;
    const [addEntriesOpen, setAddEntriesOpen] = React.useState(false);
    const [tabValue,setTabValue] = React.useState("1");
    const [addEntryLoading,setAddEntryLoading] = useState(false);
    const handleAddEntriesClose = () => setAddEntriesOpen(false);
    const handleContestantClick = (e) => {
        e.preventDefault();
        setAddEntriesOpen(true);
    }
    const format_contestant = (data) => {
        let contestant = data.row.contestant;
        //todo add contestant modal
        let name = contestant?.name
        let letter  = name?.charAt(0).toUpperCase();
        let hover = "Click to view contestant details";
        return (<HoverPop hoverValue={hover}><Chip onClick={handleContestantClick} avatar={<Avatar>{letter}</Avatar>} label={name} /></HoverPop>);
    }
    const showAddEntry = () => {
        //popup modal
        setAddEntriesOpen(true);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const format_entry_method = (data) => {
        let entry = data.row.entry_method_used;
        console.log("format_entry_method",entry);
        let hover = (<div>
            <div className={"p-2"}>{entry.button_label}</div>
            <div className={"p-2"}>Entry points: {entry.entry_points}</div>
        </div>);
        return (<HoverPop hoverValue={hover}>{entry.button_label}</HoverPop>);

    }
    const format_location = (data) => {
        let location = data.row.all_data;
        let city = location?.city;
        let country = location?.country || false;
        //todo add hover pop with more info
        if(!country)
            return <>Not tracked</>
        let popLocation = (<><div className={"p-2"}>{city}, {location.region}, {country} <Flag country={country}/></div><div className={"p-2"}>Timezone: {location.timezone}</div></>);
        return (<HoverPop hoverValue={popLocation}><Chip variant="outlined" avatar={<Avatar><Flag country={country}/></Avatar>} label={city} /></HoverPop>);

    }
    const format_time = (data) => {
        let time = data.row.time_of_entry;
        const date = new Date(time);
        const shortDate = date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            ordinal: 'suffix'
        });
        return (<HoverPop hoverValue={new Date(time).toLocaleString()}>{shortDate}</HoverPop>);

    }
    const columns = [
        {
            field: 'contestant_id',
            headerName: 'Contestant', flex: 0.3,
            minWidth: 50,
            renderCell: format_contestant,
            align: 'left',
        },
        { field: 'entry_points',align: 'left', headerName: 'Entry', renderCell: format_entry_method,minWidth: 80, flex: 0.35,},
        { field: 'time_of_entry', align: 'left',headerName: 'Time',minWidth: 50, flex: 0.1, renderCell: format_time },
        { field: 'all_data', align: 'left',headerName: 'Location',minWidth: 80, flex: 0.25,  renderCell: format_location, },
    ];
    const handleEntryAddChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleAddEntry = async (event) => {
        event.preventDefault();
        let data = form_obj(event.target);
        data.contest_id = contest.contest_id;
        setAddEntryLoading(true);
        let result = await req("POST","/contest/add_contestant_entry/",data);
        setAddEntryLoading(false);
        if(result.name) {
           document.getElementById('add-entry-form').reset()//reset form
            toast.success("Entry added");
            get_entries();
        }
    }
    const handleUploadContestants = async (event) => {
        event.preventDefault();
        let file = document.getElementById('contestant-file').files[0];
        setAddEntryLoading(true);
        let result = await upload('/contest/upload_contestants/', file,contest.contest_id,'contestant-file');
        setAddEntryLoading(false);
        if(result.name) {
            document.getElementById('add-entry-form').reset()//reset form
            toast.success("Entries added");
            get_entries();
        }
    }
    return( <><Button onClick={showAddEntry}>Add entry(s)</Button>{entries.length >0 ? <div style={{ height: 400, width: '100%' }} >
        <DataGrid
            rows={entries}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
        />
    </div> :<h1>No entries yet</h1>}
        <Modal
            open={addEntriesOpen}
            onClose={handleAddEntriesClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {addEntryLoading && <div className={'loader'}><img src="https://app.rewardsfuel.com/assets/images/loader.svg"/></div>}
                <TabContext  value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleEntryAddChange} aria-label="lab API tabs example">
                            <Tab label="Add a few entries" value="1" />
                            <Tab label="Import from CSV" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <form id={'add-entry-form'} onSubmit={handleAddEntry}>
                            <TextField id="outlined-basic"  className={"mb-3"} fullWidth={true} name={"name"} label="Name" required={true} variant="outlined" />
                            <TextField id="outlined-basic"  className={"mb-3"} fullWidth={true} name={"email"} label="email" type={"email"} required={true} variant="outlined" />
                            <TextField id="outlined-basic" className={"mb-3"} fullWidth={true} name={"nbr_entries"} required={true} label="nbr of entries" type={"number"} min={1} defaultValue={1} variant="outlined" />
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={4}>
                                    <Button type={"button"} onClick={handleAddEntriesClose} fullWidth={true} variant={"outlined"}>Close</Button>
                                </Grid>
                                <Grid item={true} xs={8}>
                                    <Button type={"submit"} fullWidth={true} variant={"outlined"}>Add entry</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </TabPanel>
                    <TabPanel value="2">
                        <form onSubmit={handleUploadContestants}>
                            <div>Upload contestants.  Download this template CSV.  The file consists of 3 columns (email,name, entryPoints)</div>
                            <Button
                                variant="outlined"
                                component="label"
                                fullWidth={true}
                                className={"mb-5 mt-3"}
                            >
                                Select CSV
                                <input
                                    id={"contestant-file"}
                                    name={"file"}
                                    type="file"
                                    accept=".csv"
                                    hidden
                                />
                            </Button>
                            <Button type={"submit"} fullWidth={true} variant={"outlined"}>Upload</Button>
                    </form>
                    </TabPanel>
                </TabContext>
            </Box>

        </Modal>
    </>)
}







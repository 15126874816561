import React from 'react';
import TextField from '@mui/material/TextField';
import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {FormControlLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";

export function Score(props) {
    const {contest, setContest} = props;
    const [yourEntries, setYourEntries] = React.useState(contest.layout.your_entries_label);
    const [totalEntries, setTotalEntries] = React.useState(contest.layout.total_entries_label);
    const [hideTotalEntries, setHideTotalEntries] = React.useState(contest.layout.hide_total_entries)
    const [hideYourEntries, setHideYourEntries] = React.useState(contest.layout.hide_your_entries)
    const [checked, setHideTitle] = React.useState(contest.layout.hide_title);
    const onChangeYourEntries = async (event) => {
        setYourEntries(event.target.value)
        setContest('layout.your_entries_label', event.target.value);
    }
    const onChangeTotalEntries = async (event) => {
        setTotalEntries(event.target.value)
        setContest('layout.total_entries_label', event.target.value);
    }
    const onSwitchChange = async (event) => {
        setHideTitle(event.target.checked);
        setContest('layout.hide_score', event.target.checked);
    }
    const onSwitchChangeShowYourEntries = async (event) => {
        setHideYourEntries(event.target.checked);
        setContest('layout.hide_your_entries', event.target.checked);
    }
    const onSwitchChangeShowTotalEntries = async (event) => {
        setHideTotalEntries(event.target.checked);
        setContest('layout.hide_total_entries', event.target.checked);
    }


    return (<>


        <Grid container={true}>
            <Grid item xs={9} className={'mb-3'}>
                <TextField variant={'outlined'} fullWidth={true} label={'Your entries label'} value={yourEntries}
                           onChange={onChangeYourEntries}/>
            </Grid>
            <Grid item xs={3} className={'mb-3'} display="flex" justifyContent="flex-end">
                <FormGroup display="flex" justifyContent="flex-end">
                    <FormControlLabel align="right" justifyContent="flex-end"
                                      control={<Switch checked={hideYourEntries}/>} label={"Hide"}
                                      onChange={onSwitchChangeShowYourEntries}/>
                </FormGroup>
            </Grid>
            <Grid item xs={9} className={'mb-3'}>
                <TextField variant={'outlined'} fullWidth={true} label={'Total entries label'} value={totalEntries}
                           onChange={onChangeTotalEntries}/>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end" className={'mb-3'}>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={hideTotalEntries}/>} label={"Hide"}
                                      onChange={onSwitchChangeShowTotalEntries}/>
                </FormGroup>
            </Grid>
        </Grid>

    </>);
}

import StyleSettings from './Style/StyleSettings';
import ContestSettingsAccordion from './ContestSettingsAccordion';
import WinnersPrizesTab from "./winnersPrizes/WinnersPrizesTab";
import EntryMethodsTab from "./entryMethods/EntryMethodsTab";
import StatsTab from "./stats/StatsTab";

export default function SettingsPanels(props) {
    const {SelectedVerticalTab} = props;
    return(<>
        {SelectedVerticalTab === 0 && <div className={'settings-area'}><StyleSettings {...props}/></div>}
        {SelectedVerticalTab === 1 && <div className={'settings-area'}><ContestSettingsAccordion  {...props}/></div>}
        {SelectedVerticalTab === 2 && <div className={'settings-area'}><EntryMethodsTab {...props}/></div>}
        {SelectedVerticalTab === 3 && <div className={'settings-area'} {...props}><WinnersPrizesTab {...props}/></div>}
        {SelectedVerticalTab === 4 && <div className={'settings-area'} {...props}><StatsTab {...props}/></div>}
    </>);
}

import { Button, TextField } from "@mui/material";
import { form_obj, req } from "../../CommonLibrary";
import { useState } from "react";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";

export default function AccountLogins(props){
    const {account_logins    } = props.account;
    const auth = useAuthUser()
    const [loginUx,setLoginUx] = useState([<h4>You have no addition logins currently</h4>]);
    const addAccountLoginEmail = async (e) => {
        e.preventDefault();
        let data = form_obj(e.target);
        console.log("addAccountLoginEmail", data);
        const response = await req("POST", "/account/add_account_login_email", data);
    }
    const removeAccountLoginEmail = async (e) => {
        e.preventDefault();
        let login = e.target.dataset.email;
        let data = {login};
        const response = await req("POST", "/account/remove_account_login_email", data);
    }
    let mounted = false;
    useEffect(() => {
        if (!mounted) {
            mounted = true;
            formatAccountLogins();
        }
    }, [])
    const formatAccountLogins=()=>{
        console.log("formatAccountLogins", auth())
        let accountLoginsUx = [];
        let authEmail;
        for(const login of account_logins){
            authEmail = login.replace("login_", "");
            if(authEmail !== auth().email){
                console.log("authEmail", authEmail)
                accountLoginsUx.push(
                    <div  sx={{ mb: 3 }} >{authEmail} <Button variant="outlined" 
                    data-email={authEmail} onClick={removeAccountLoginEmail} >Remove</Button></div>
                );
            }
            else{
                accountLoginsUx.push(
                    <div  sx={{ mb: 3 }} >YOU: {authEmail}</div>
                );
            }
        }
        if(accountLoginsUx.length === 0)
            accountLoginsUx =[<h4>You have no addition logins currently</h4>]
        setLoginUx(accountLoginsUx);
    }
    return(
        <>
        <form onSubmit={addAccountLoginEmail}>
            <h3>Add account login</h3>
            <TextField variant="outlined" fullWidth={true} sx={{ mb: 3 }} type="email" name="email" label="Email" required={1} />
            <Button type="submit" sx={{ mb: 3 }} fullWidth={true} variant="contained" color="primary">Add</Button>
        </form>
        <h4>Emails with access to your account</h4>
        {loginUx}
        </>
    );
}
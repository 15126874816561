import React, {useEffect} from 'react';
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const COLORS = {
    male: ['#3366CC', '#003399', '#000066'],
    female: ['#FF99CC', '#FF3366', '#CC0033'],
};

export default function DemoReport(props) {
    //todo handle no data
    //todo add real data here
    const [pieData,setPieData] = React.useState([]);
    const [width, setWidth] = React.useState(0);
    const [renderLabel,setRenderLabel] = React.useState(false);
    function getColor(name) {
        const COLORS = {
            male: ['#3366CC', '#003399', '#000066', '#333399', '#0066CC', '#0033CC'],
            female: [
                '#FF99CC',
                '#FF3366',
                '#CC0033',
                '#FF6699',
                '#FF0033',
                '#CC3366',
            ],
        };
        const [gender, ageGroup] = name.split(', ');
        const shade =
            ageGroup === '18-24'
                ? 0
                : ageGroup === '25-34'
                    ? 1
                    : ageGroup === '35-44'
                        ? 2
                        : ageGroup === '45-54'
                            ? 3
                            : ageGroup === '55-64'
                                ? 4
                                : ageGroup === '65+'
                                    ? 5
                                    : 0;
        return COLORS[gender][shade];
    }

    let data = [
        [
            "female",
            "18-24",
            "23"
        ],
        [
            "female",
            "25-34",
            "83"
        ],
        [
            "female",
            "35-44",
            "84"
        ],
        [
            "female",
            "45-54",
            "84"
        ],
        [
            "female",
            "55-64",
            "70"
        ],
        [
            "female",
            "65+",
            "156"
        ],
        [
            "male",
            "25-34",
            "23"
        ],
        [
            "male",
            "35-44",
            "44"
        ],
        [
            "male",
            "45-54",
            "51"
        ],
        [
            "male",
            "55-64",
            "35"
        ],
        [
            "male",
            "65+",
            "33"
        ]
    ];
    useEffect(() => {
        let containerWidth = document.getElementById('demo-data').offsetWidth;
        setWidth(containerWidth);
        setPieData(data.map(([gender, ageGroup, count]) => ({
            name: `${gender}, ${ageGroup}`,
            value: parseInt(count),
        })));
        const labels = ({name}) => name;
        setRenderLabel(labels);
    }, []);
    return (
        <div id="demo-data">
                <PieChart width={width} height={300}>
                    <Pie
                        data={pieData}
                        dataKey="value"
                        label={renderLabel}
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                    >
                        {pieData.map(({name, value}) => {
                            return (
                                <Cell key={name} fill={getColor(name)}/>
                            );
                        })}
                    </Pie>
                    <Tooltip/>
                    <Legend/>
                </PieChart>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Age Group</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Number of Visitors</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(([gender, ageGroup, count]) => (
                        <TableRow key={`${gender}, ${ageGroup}`}>
                            <TableCell>{ageGroup}</TableCell>
                            <TableCell>{gender}</TableCell>
                            <TableCell>{count}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};


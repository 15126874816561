import Card from "@mui/material/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import React,{useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {set_contest_item} from "../ContestEditorFunctions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
export default function ContestLanguages(props) {
    const {contest,setContest} = props;
    //todo get available languages from the server
    let languages = [{value:"eng",name:"English"},{value:"fr",name:"French"},{value:"esp",name:"Spanish"},{value:"it",name:"Italian"},{value:"de",name:"German"}];
    let languages_items = languages.map(function(item, i){
        return <MenuItem key={i} value={item.value}>{item.name}</MenuItem>;
    })
    const [changes_to_save,set_changes_to_save] = useState(false);
    let lang = contest?.language || "eng";
    const [language,setLanguage] =useState(lang);
    const set_contest_language = async () => {
        set_changes_to_save(false);
        await setContest("language",language)
    }
    const handleChange = (event)=>{
        set_changes_to_save(true);
        setLanguage(event.target.value);

    }
    return (<><Card>

            <Divider/>
            <CardContent>
                <FormControl fullWidth={true}>
                    <InputLabel id="demo-simple-select-label">Contest Language</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        label="Contest language"
                        onChange={handleChange}
                    >
                        {languages_items}
                    </Select>
                </FormControl>
                {changes_to_save &&
                    <Grid  className={'pt-3 w-100'} item xs={12}>
                        <Button fullWidth={true} type={'button'} className={'w-100'} variant={'outlined'} color={'primary'} onClick={set_contest_language}>Update Language</Button>
                    </Grid>}
            </CardContent>

            <CardActions>
                This includes emails and contest
            </CardActions>
        </Card></>
    );
}

import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import VerticalTabs from "../../components/ClassicContestSettings/VerticalTabs";
import SettingsPanels from "../../components/ClassicContestSettings/SettingsPanels";
import {
    get_contest,
    send_to_preview,
    set_contest_item
} from "../../components/ClassicContestSettings/ContestEditorFunctions";
import {useParams} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import MobileResolutionSelector from "../../components/ClassicContestSettings/MobileResolutionSelector";
import {base_contest_host, base_url, req} from "../../CommonLibrary";
import MobileHorizontalNav from "../../components/ClassicContestSettings/MobileHorizontalNav";

let sentAuthData = false;
export default function ClassicContest() {
    let {id} = useParams();
    const contest_id = id;
    let iframe_url = `${base_contest_host}${contest_id}`;//'http://localhost:3001/c/' + contest_id;//;
    const [contest, setContestState] = useState(false);
    const [contestants, setContestants] = useState([]);
    const [SelectedVerticalTab, SetSelectedVerticalTab] = useState(0);
    const [middleColumn, setMiddleColumn] = useState(5);
    const [rightColumn, setRightColumn] = useState(6);
    const [hideMiddleColumn, setHideMiddleColumn] = useState(false);
    const [mobilePreview, setMobilePreview] = useState(false);
    const setContest = async (name, value, update_server = true) => {
        let new_contest = await set_contest_item(name, value, contest, update_server);//makes update to object passed and saves on server
        setContestState(new_contest);//updates in page state
        return new_contest;
    }
    const getContestants = async () => {
        let c = await req('GET', '/contest/get_contestants/?contest_id=' + contest_id);
        setContestants(c);
    }
    const minimize_style_editor = (activate) => {
        if (activate) {
            setMiddleColumn(0);
            setRightColumn(11);
            setHideMiddleColumn(true);

        } else {
            setHideMiddleColumn(false)
        }
    }
    const maximize_style_editor = () => {
        setMiddleColumn(5);
        setRightColumn(6);
        setHideMiddleColumn(false);
    }

    const load_contest = async function (contest_id) {
        if (contest_id === undefined)
            return
        let data = await get_contest(contest_id);
        setContestState(data);
    }
    const setIframeDimensions = (dimensions) => {
        const css = `.full-height-preview-frame-rounded {
                      width: ${dimensions.width}px !important;
                      height: ${dimensions.height}px !important;
                      margin:0 auto;
                    }`;
        let styleElement = document.getElementById('mobile-preview-style');
        if (!styleElement) {
            const style = document.createElement('style');
            style.id = 'mobile-preview-style';
            style.appendChild(document.createTextNode(css));
            document.head.appendChild(style);
        } else {
            styleElement.innerHTML = css;
        }

    }
    const toggleMobilePreview = () => {
        let current = mobilePreview;
        setMobilePreview(!mobilePreview);
        if (current) {
            document.getElementById('mobile-preview-style').remove();
        }

    }
    let ClassicContestMounted = false;
    const handleMobileLayout = () => {
        if(window.innerWidth < 600){
            setRightColumn(0);
            setMiddleColumn(11);
            setPageNav(  <Grid  sx={{ display: { xs: 'block', sm: 'block', md:'none' } }} xs={12}>
                <MobileHorizontalNav  hideMiddleColumn={hideMiddleColumn} setMiddleColumn={setMiddleColumn}
                                      setRightColumn={setRightColumn}
                                      contest={contest} maximize_style_editor={maximize_style_editor}
                                      minimize_style_editor={minimize_style_editor} setContest={setContest}
                                      SelectedVerticalTab={SelectedVerticalTab}
                                      SetSelectedVerticalTab={SetSelectedVerticalTab}/>

            </Grid>)
            try {
                document.querySelector(".preview-frame-holder").style.display = 'none';
            }catch (e) {
                console.log("hiding iframe issue",e);
            }
        }
    }
    useEffect(() => {
        if (ClassicContestMounted)
            return
        ClassicContestMounted = true;
        document.title = 'Contest loading...';
        load_contest(contest_id);
        getContestants();
        handleMobileLayout();

        const hash =  window.location.hash.substring(1);
        if (hash === 'stats') {
            setRightColumn(0);
            setMiddleColumn(11);
        }
    }, []);

    window.addEventListener('message', event => {
        if (event.data === 'ContestLoaded' && !sentAuthData) {
            sentAuthData = true;
            let auth_data = localStorage.getItem('_auth');
            setInterval(() => {
                send_to_preview("iframe-in-editor", {auth_data, contest_id});
            }, 1000);
            send_to_preview("set-contest-state", 'live');
        }
    });
    const [pageNav, setPageNav] = useState( <Grid sx={{ display: { xs: 'none', sm: 'none', md:'block' } }} xs={1}>
        <VerticalTabs  hideMiddleColumn={hideMiddleColumn} setMiddleColumn={setMiddleColumn}
                       setRightColumn={setRightColumn}
                       contest={contest} maximize_style_editor={maximize_style_editor}
                       minimize_style_editor={minimize_style_editor} setContest={setContest}
                       SelectedVerticalTab={SelectedVerticalTab}
                       SetSelectedVerticalTab={SetSelectedVerticalTab}/>
    </Grid>);
    return (
        <>{contest ? <Grid container>
            {pageNav}


            {!hideMiddleColumn && <Grid xs={middleColumn} className={"contest-settings-panel"} >
                <SettingsPanels  toggleMobilePreview={toggleMobilePreview} minimize_style_editor={minimize_style_editor}
                                contestants={contestants} contest={contest} setContest={setContest} SelectedVerticalTab={SelectedVerticalTab}/>
            </Grid>}
            {rightColumn > 0 &&<Grid xs={rightColumn} className={"preview-frame-holder"}>
                    {mobilePreview && <MobileResolutionSelector setIframeDimensions={setIframeDimensions}/>
                    }
                    <iframe
                        src={iframe_url}
                        id={"preview-frame"}
                        className={'full-height-preview-frame-rounded'}
                    />
                </Grid>}
        </Grid> : <div>Loading...</div>}
        </>
    );
}


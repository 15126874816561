import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import {Storage} from '../CommonLibrary'
import {FormControlLabel} from "@mui/material";
export default function  ThemeToggle(props){
    const {setTheme, darkTheme, lightTheme} = props;
    const [checked, setChecked] = useState(false);
    let store = new Storage();
    function getDeviceTheme() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'dark';
        } else {
            return 'light';
        }
    }
    const handleToggle = (event) => {
        setChecked(event.target.checked);
        //dark is checked
        let newTheme = event.target.checked ? 'dark': 'light' ;
        store.set("rf_theme",newTheme);
        if(!checked){
            setTheme(darkTheme);
        }else{
            setTheme(lightTheme);
        }
    };
    let mounted = false;
    useEffect(() => {
       if(!mounted){
          mounted = true;
           let stored_theme = store.get("rf_theme");
           let selectedTheme = stored_theme ? stored_theme: getDeviceTheme();
          if(selectedTheme === 'dark'){
              setChecked(true);
              setTheme(darkTheme);
              document.body.classList.add('dark');
              document.body.classList.remove('light');
          }else{
                setTheme(lightTheme);
                setChecked(false);
              document.body.classList.add('light');
              document.body.classList.remove('dark');
          }
       }
    }, []);

    return (
        <FormControlLabel control={<Switch checked={checked} label={"Dark theme"} onChange={handleToggle} />} label="Dark" />
    );
};


import * as React from "react";
import {useEffect, useState} from "react";
import EditEntryMethodSortRow from "./EditEntryMethodSortRow";

import { List, arrayMove } from 'react-movable';

export default function EditEntryMethods(props) {
    //this tab adds editor rows where you can update points, order, names, delete entry method
    let {edit_entry_method, entry_methods, entry_types, update_entry_method,delete_entry_method,update_entry_method_order,show_entry_method_editor} = props;
    const [editor_rows, set_editor_rows] = useState([]);//editor row elements
    const getEntryTypeFromEntryMethod = (entryTypeId) => {
        return entry_types.find((entryType) => String(entryType.id) === String(entryTypeId)) || false;
    };
    //creates an array of editor row components
    const create_entry_method_edit_rows = (entry_methods) => {
        //puts rows into array sorted by sort order
        entry_methods = entry_methods.filter((entry_method) => parseInt(entry_method.entry_type) > 0)
        let entry_editor_list = entry_methods.map((elm, ind) => {
                let entry_type = getEntryTypeFromEntryMethod(elm.entry_type);
                return <EditEntryMethodSortRow key={elm.entry_method_id}
                            update_entry_method={update_entry_method}
                                               edit_entry_method={edit_entry_method}
                                               show_entry_method_editor={show_entry_method_editor}
                            entry_type={entry_type}
                            delete_entry_method={delete_entry_method}
                            entry_method={elm} />;

        });
        set_editor_rows(entry_editor_list);
    }

    //on update of entry methods
    useEffect(() => {
        create_entry_method_edit_rows(entry_methods);
    }, [entry_methods,entry_types]);
    //on update of sorting order
    useEffect(() => {
        if(editor_rows.length===0 )
            return;
        let ids_in_order = editor_rows.map((elm, ind) => {
            return elm.key;
        })
        update_entry_method_order(ids_in_order);//in entryMethodsTab
    }, [editor_rows]);
    //on mount
    let entryPanelMounted = false
    useEffect(() => {
        if (entryPanelMounted)
            return;
        entryPanelMounted = true;
        create_entry_method_edit_rows(entry_methods);
    }, []);
    return (<List
            values={editor_rows}
            horizontal={false}
            onChange={({ oldIndex, newIndex }) =>
                set_editor_rows(arrayMove(editor_rows, oldIndex, newIndex))
            }
            renderList={({ children, props }) =><ul {...props}>{children}</ul>}
            renderItem={({ value, props }) =><span {...props}>{value}</span>}
        />);
}

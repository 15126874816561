import Button from '@material-ui/core/Button';
import {req} from "../../CommonLibrary";
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import ContestMenu from "../../components/HomePage/ContestMenu";

export default function HomePagePropertiesTable(props) {
    const {properties, get_properties} = props;
    if(!properties.length){
        get_properties();
    }

    const formatDateTime = (date) => {
        if (!date)
            return "";
        let d = new Date(date.row.created_at);
        return d.toLocaleString();
    }
    const createActions = (value) => {
        return (<ContestMenu contest={value.row} {...props}/>);
    }
    const columns = [
        {field: 'contest_name', headerName: 'Name', flex: .2},
        {field: 'created_at', headerName: 'Created', flex: .2, valueGetter: formatDateTime,options: { sortDirection: 'asc' } },
        {field: 'state', headerName: 'State', flex: .2},
        {field: 'contest_id', headerName: 'Actions', flex: .4, renderCell: createActions},
    ]
    const options= {
        autoHeight: true,
        sortOrder: {
            name: 'Created',
            sort: 'asc',
        }
    }
    return (<>
            {properties.length > 0 && <>
                <h1>Your contests</h1>
                <Box sx={{height: 400, width: '100%'}}>
                    <DataGrid options={options} rows={properties} columns={columns}/>
                </Box>
            </>}</>
    );
}



import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useIsAuthenticated } from 'react-auth-kit';
import { useSignOut } from 'react-auth-kit'
import ThemeSwitch from "./ThemeSwitch";

export default function Nav(props) {
    const isAuthenticated = useIsAuthenticated();
    //console.log("user authenticated",isAuthenticated());
    const signOut = useSignOut();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        signOut();
        handleClose();
        window.location.href = "/";
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {!isAuthenticated() ? (
                            <Button color="inherit" href={"/login"}>Login</Button>
                        ) : (<div sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
                            <Button sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }} color="inherit"
                                href={"/s/home"}>Home</Button>
                            <Button sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }} color="inherit"
                                href={"/s/tank"}>Tank</Button>
                            <Button sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }} color="inherit"
                                href={"/s/account"}>Account</Button>
                            <Button onClick={logout} sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }} color="inherit"
                            >Logout</Button>


                        </div>
                        )}
                        <MenuItem><ThemeSwitch {...props} /></MenuItem>
                    </Menu>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Rewards Fuel

                    </Typography>

                </Toolbar>
            </AppBar>
        </Box>
    );
}

import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import {useTheme} from '@mui/material/styles';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {send_to_preview} from "../../ContestEditorFunctions";
import {req} from "../../../../CommonLibrary";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";


export default function CssEditor(props) {
    /**todo start here tomorrow
     * ---get saving working right
     * ---double check reset
     * ---get colors css working the same as above and make sure preview is working for both
     * ---add effect to save button (if there is something to save)
     *
     */
    const{contest} = props;
    const [CSS_changes_to_save,set_CSS_change_to_save] = useState(false);
    const [css,setCss] = useState('');
    let temp_css = '';//because the ACE editor doesn't have work well with the useState css
    const theme = useTheme();
    let isMounted = false;
    const get_css = async () => {
        let url = `/contest/get_css/?contest_id=${contest.contest_id}&t=style`;
        let result = await req("GET", url);
        temp_css = result;
        setCss(result)
        addCssEditors();
    }
    const reset_css = async () => {
        let url = `/contest/reset_css/`;
        let post = {contest_id: contest.contest_id, type: 'style'};
        let result = await req("POST", url,post);
        temp_css = result.css;
        addCssEditors();
        onChange(result.css);
    }
    const onChange = (newValue) => {
        //if live editing is enabled, send the new value to the preview
        temp_css =newValue;
        let message = {
            preview_css: newValue
        }
        setCss(newValue);
        send_to_preview('set-css', message);
        set_CSS_change_to_save(true);
    }
    let editor_root = null;
    useEffect(() => {
        if (isMounted)
            return;
        isMounted = true;
        get_css();
    }, []);
    useEffect(() => {
        temp_css = css;
    }, [css]);

    const save_css = async () => {
        let url = `/contest/set_css/`;
        let result = await req("POST", url, {content: css,type:'style',contest_id:contest.contest_id});
        if(result){
                set_CSS_change_to_save(false);
        }
    }

    const addCssEditors = () => {
        let editor_theme = "monokai";
        if (theme.palette.mode !== "dark")
            editor_theme = "github";
        if(!editor_root)
            editor_root = ReactDOM.createRoot(document.getElementById("css-editor"));

        editor_root.render(
            <AceEditor
                placeholder="Edit CSS changes will show live."
                mode="css"
                theme={editor_theme}
                name="css"
                onChange={onChange}
                showLineNumbers={true}
                wrapEnabled={true}
                fontSize={14}
                showPrintMargin={true}
                showGutter={false}
                highlightActiveLine={true}
                width={'100%'}
                value={temp_css}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                }}/>
        );

    }
    return (<div className={"p-2"}>
                <div className="text-right">
                    <ButtonGroup   aria-label="outlined primary button group" className={"mb-3"}>
                        <Button type={"button"} className={"btn btn-primary"} onClick={reset_css}>Reset CSS</Button>
                        {CSS_changes_to_save ? <Button variant="contained" type={"button"} className={"btn btn-primary outlined"} onClick={save_css}>Save</Button> :
                            <Button type={"button"} variant="outlined" className={"btn btn-primary"} onClick={save_css}>Save</Button>
                        }
                    </ButtonGroup>
                </div>


        <div id="css-editor">editor</div>


    </div>)
}

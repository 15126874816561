import * as React from "react";
import {Title} from "./Title";
import {Images} from "./Images";
import {Countdown} from "./Countdown";
import DetailsRulesEditor from "./DetailsRulesEditor";
import {Score} from "./Score";
import {BottomBar} from "./BottomBar";
import { List, arrayMove } from 'react-movable';
import {useEffect, useState} from "react";
import ElementDraggableAccordion   from "./ElementDraggableAccordion";
import {isArray} from "lodash";
import {EntryMethods} from "./EntryMethods";

export default function StyleElementsAccordionDND(props) {
    const {contest, setContest} = props;
    const sortContestElements = (sortOrder, contestElements) => {
        // Sort the contest elements using the sort order
        contestElements.sort((a, b) => {
            // Get the sort names for each element
            const sortNameA = a.props.sortName;
            const sortNameB = b.props.sortName;

            // Find the index of each sort name in the sort order
            const indexA = sortOrder.indexOf(sortNameA);
            const indexB = sortOrder.indexOf(sortNameB);

            // Compare the indices to determine the sort order
            if (indexA < indexB) {
                return -1;
            }
            if (indexA > indexB) {
                return 1;
            }
            return 0;
        });

        return contestElements;
    };
    const handleAccordionExpandChange = (panel) => (event, newExpanded) => {
        console.log("handleAccordionExpandChange");
        //todo add one or none expanded
    }
    let  sortOrder = contest.layout?.elements_order ? contest.layout?.elements_order : ["title","images","entryMethods","countdown","tabs","score","bottomBar"];
    let elements = [
        <ElementDraggableAccordion {...props} sortName={"title"} elementToFocus={".contest-title"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={0} key={"0"} title={"Title"} elementToShowHide={"layout.hide_title"} content={<Title   {...props}/>} />,
        <ElementDraggableAccordion {...props} sortName={"images"} elementToFocus={".header-image"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={1} key={"1"} title={"Banner/Images"} elementToShowHide={"layout.hide_images"} content={<Images {...props} />} />,
        <ElementDraggableAccordion {...props} sortName={"entryMethods"} elementToFocus={".entry-methods-container"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={2} key={"2"} title={"Entry methods"} elementToShowHide={"layout.hide_entry_methods"} content={<EntryMethods {...props} />} />,
        <ElementDraggableAccordion {...props} sortName={"countdown"} elementToFocus={"#countdown-clock"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={2} key={"2"} title={"Countdown clock"} elementToShowHide={"layout.hide_clock"} content={<Countdown {...props} />} />,
        <ElementDraggableAccordion {...props} sortName={"tabs"}  elementToFocus={".tabs-details-area"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={3} key={"3"} title={"Rules/Description"} elementToShowHide={"layout.hide_tabs"} content={<DetailsRulesEditor {...props} />} />,
        <ElementDraggableAccordion {...props} sortName={"score"} elementToFocus={".entries-summary-row"}  handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={4} key={"4"} title={"Scores"} elementToShowHide={"layout.hide_score"} content={<Score {...props} />} />,
        <ElementDraggableAccordion {...props} sortName={"bottomBar"} elementToFocus={".bottom-bar"} handleAccordionExpandChange={handleAccordionExpandChange} expanded={true} index={5} key={"5"} title={"Bottom Bar"} elementToShowHide={"layout.hide_bottom_bar"} content={<BottomBar {...props} />} />,
    ]
    elements = sortContestElements(sortOrder, elements);
    const [accordionItems, setAccordionItems] = React.useState(elements);
    //todo add one or none expansion function here

    // Swap the positions
    useEffect(() => {
        if(accordionItems.length===0 )
            return;
        let ids_in_order = accordionItems.map((elm, ind) => {
            return elm.props.sortName;
        })
        update_element_order(ids_in_order);//in entryMethodsTab
    }, [accordionItems]);
    const update_element_order = async (ids_in_order) => {
        function isDifferentOrder(array1, array2) {
            // Compare the lengths of the arrays
            if(isArray(array1) || isArray(array2))
                return true;
            if (array1.length !== array2.length) {
                return true;
            }
            for (let i = 0; i < array1.length; i++) {
                if (array1[i] !== array2[i]) {
                    return true;
                }
            }
            return false;
        }
        if(!isDifferentOrder(contest.layout.elements_order,ids_in_order))
            return;
        //reorder entry methods by ids in order
        setContest('layout.elements_order', ids_in_order);
    }
    return (<List
        className="draggable-list"
        values={accordionItems}
        horizontal={false}
        onChange={({ oldIndex, newIndex }) =>{
            setAccordionItems(arrayMove(accordionItems, oldIndex, newIndex))
        }}
        renderList={({ children, props }) =><ul {...props}>{children}</ul>}
        renderItem={({ value, props }) =><span {...props}>{value}</span>}
    />);
};

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Styles from "./Styles";
import Colors from "./Colors";
import FontSelector from "./FontSelector";

export default function StylesAndColorsAccordion(props){
    return( <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Font</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FontSelector {...props}/>
            </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>Styles</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Styles {...props}/>
        </AccordionDetails>
    </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Color Palettes</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Colors {...props}/>
            </AccordionDetails>
        </Accordion>
        </>);
}




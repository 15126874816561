import React from 'react';
import Button from '@material-ui/core/Button';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function MaximizeButton({ maximize_style_editor,hideMiddleColumn }) {
    if (!hideMiddleColumn) {
        return null;
    }
    return (
        <Button className={'maximize-button'}
            onClick={() => maximize_style_editor(true)}
                variant={'outlined'}
                color={'primary'}
        >
            <KeyboardDoubleArrowRightIcon/>
        </Button>
    );
}

import React, {useEffect, useState} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BrushIcon from '@mui/icons-material/Brush';
import SettingsIcon from '@mui/icons-material/Settings';
import Filter5Icon from '@mui/icons-material/Filter5';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InsightsIcon from '@mui/icons-material/Insights';
import MaximizeButton from "./Style/MaximizeButton";

export default function MobileHorizontalNav(props) {
    const {setMiddleColumn, setRightColumn, minimize_style_editor} = props;
    const SelectedVerticalTab = props.SelectedVerticalTab;
    const SetSelectedVerticalTab = props.SetSelectedVerticalTab;

    const handleChange = (event, newValue) => {
        SetSelectedVerticalTab(newValue);
        minimize_style_editor(false);
        window.history.pushState(null, null, `#${getTabValueFromId(newValue)}`);
        if (newValue < 4) {
            setMiddleColumn(5);
            setRightColumn(6);
        } else {

            setRightColumn(0);
            setMiddleColumn(11);
        }
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const getTabValueFromUrl = () => {
        const hash =  window.location.hash.substring(1);
        if (hash.includes("style")) {
            return 0;
        }
        if (hash.includes("settings")) {
            return 1;
        }
        if (hash.includes("entryMethods")) {
            return 2;
        }
        if (hash.includes("winners")) {
            return 3;
        }
        if (hash.includes("stats")) {
            return 4;
        }
        return 0;
    }
    const getTabValueFromId = (id) => {
        if(id===0){
            return "style";
        }
        if(id===1){
            return "settings";
        }
        if(id===2){
            return "entryMethods";
        }
        if(id===3){
            return "winners";
        }

        if(id===4){
            return "stats";
        }
    }
    let mounted =false;
    useEffect(() => {
        if(!mounted){
            mounted = true;
            const bookmark =getTabValueFromUrl();
            if(bookmark>0)
                SetSelectedVerticalTab(bookmark);
        }
    }, []);

    const buttons = [
        <ToggleButton key="0" value={0} onClick={handleChange} label="Style" {...a11yProps(0)}><BrushIcon/>
            <div className={'vert-tab-title'}>Style</div>
        </ToggleButton>,
        <ToggleButton key="1" value={1} onClick={handleChange} label="Settings" {...a11yProps(1)}><SettingsIcon/>
            <div className={'vert-tab-title'}>Settings</div>
        </ToggleButton>,
        <ToggleButton key="2" value={2} onClick={handleChange} label="Settings" {...a11yProps(2)}><Filter5Icon/>
            <div className={'vert-tab-title'}>Entry</div>
        </ToggleButton>,
        <ToggleButton key="3" value={3} onClick={handleChange} label="Item One" {...a11yProps(3)}><EmojiEventsIcon/>
            <div className={'vert-tab-title'}>Winners</div>
        </ToggleButton>,
        <ToggleButton key="4" value={4} onClick={handleChange} label="Item One" {...a11yProps(4)}><InsightsIcon/>
            <div className={'vert-tab-title'}>Stats</div>
        </ToggleButton>,
    ];
    return (
        <><MaximizeButton {...props}/>
            <ToggleButtonGroup
                value={SelectedVerticalTab}
                fullWidth={true}
                orientation="horizontal"
                className={'vert-tab tabs-mobile'}
            >

                {buttons}
            </ToggleButtonGroup>
        </>
    )
}

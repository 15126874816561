import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DragHandleIcon from "@mui/icons-material/DragHandle";

let waiting_for_name_update = false;
let waiting_for_point_update = false;
export default function EditEntryMethodSortRow(props){
    const {entry_method,delete_entry_method, update_entry_method,edit_entry_method,show_entry_method_editor,entry_type} = props;
    const [entry_method_name, set_entry_method_name] = React.useState(entry_method?.button_label||'');
    const [entry_points, set_entry_points] = React.useState(entry_method?.entry_points||0);
    //methods used to limit updates to server
//console.log("EditEntryMethodSortRow",entry_type);
    let waiting_time = 500;//.5 seconds
    const update_entry_method_name = (new_value) => {
        if(waiting_for_name_update !== false){
            clearTimeout(waiting_for_name_update);
        }
        waiting_for_name_update = setTimeout(async () => {
            await update_entry_method({...entry_method,button_label:new_value});
            waiting_for_name_update = false;
        },waiting_time)
    }

    const update_entry_method_points = (new_value) => {
        if(waiting_for_point_update !== false){
            clearTimeout(waiting_for_point_update);
        }
        waiting_for_point_update = setTimeout(async () => {
            await update_entry_method({...entry_method,entry_points:parseInt(new_value)});
            waiting_for_name_update = false;
        },waiting_time)
    }
    return(
        <Box  className={'entry-method-edit-row'} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center'}}>
            <Button sx={{paddingRight:'1rem'}} data-movable-handle>
                <SvgIcon  >
                    <DragHandleIcon
                        style={{cursor: "s-resize"}}
                    />
                </SvgIcon>
            </Button>
            <Box sx={{ flexGrow: 1,flexDirection: 'row' }}>
                <TextField name="button_label" value={entry_method_name} className={"w-75"} size={'small'} onChange={(e) => {
                    set_entry_method_name(e.target.value);
                    update_entry_method_name(e.target.value);
                }} label="Entry type name"  variant="standard" />
                <TextField name="name" type={'number'}  className={"w-25"} value={entry_points} size={'small'} onChange={(e) => {
                    set_entry_points(e.target.value)
                    update_entry_method_points( e.target.value);
                }} label="Entry points"  variant="standard" />
            </Box>
            <Button aria-label="Edit" onClick={()=>show_entry_method_editor(entry_type,entry_method)}  color="primary">
                Edit
            </Button>
            <IconButton onClick={()=>{delete_entry_method(entry_method.entry_method_id)}} aria-label="delete"  color="error">
                <HighlightOffIcon />
            </IconButton>
        </Box>

    );
}

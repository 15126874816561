import CardActions from "@mui/material/CardActions";
import React, {useEffect, useState} from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {ButtonGroup} from "@mui/material";
import {send_to_preview} from "../ContestEditorFunctions";

export default function ContestTimer(props) {
    const {contest, setContest} = props;
    const [changes_to_save, set_changes_to_save] = useState(false);
    const [start_date, set_start_date] = useState(contest.start_time);//dates are stored in ms timestamp
    const [end_date, set_end_date] = useState(contest.end_time);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleString());
    const updateClock = () => {
        setInterval(() => setCurrentTime(new Date().toLocaleString()), 1000);
    }
    const set_contest_times = async () => {
        await setContest(["start_time", "end_time"], [start_date, end_date]);
        set_changes_to_save(false);
        toast("Contest times updated");
    }

    useEffect(() => {
        set_start_date(contest.start_time);//dates are stored in ms timestamp
        set_end_date(contest.end_time);
        updateClock();
    }, [contest]);
    const changeContestState = async (event) => {
        let state = event.target.dataset.state
        send_to_preview("set-contest-state", state);
    }
    return (<>

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} className={"w-100"} fullWidth={true}/>}
                            label="Contest starts"
                            value={start_date}
                            onChange={(newValue) => {
                                set_start_date(new Date(dayjs(newValue)).getTime());
                                set_changes_to_save(true);
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} className={"w-100"} fullWidth={true}/>}
                            label="Contest Ends"
                            value={end_date}
                            onChange={(newValue) => {
                                set_end_date(new Date(dayjs(newValue)).getTime());
                                set_changes_to_save(true);
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                {changes_to_save &&
                    <Grid className={'pt-3 w-100'} item xs={12}>
                        <Button fullWidth type={'button'} className={'w-100'} variant={'outlined'} color={'primary'}
                                onClick={set_contest_times}>Update Contest Times</Button>
                    </Grid>}
            </Grid>


            <Grid itme className={"pt-3 pb-2"} xs={12}>
                <div>Contests have 3 states, pre contest, live, and post contest.  Click below to preview</div>
                <ButtonGroup  fullWidth={true} variant="contained" aria-label="outlined primary button group">
                    <Button data-state={"pre"} onClick={changeContestState}>Pre</Button>
                    <Button data-state={"live"} onClick={changeContestState}>Live</Button>
                    <Button data-state={"post"} onClick={changeContestState}>Post</Button>
                </ButtonGroup>
            </Grid>
            <CardActions>
                <div><b>Dates</b> and <b>Time</b> are synced to your computer {currentTime}.</div>
            </CardActions>
           </>
    );
}

import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {send_to_preview} from "../../ContestEditorFunctions";
import {req} from "../../../../CommonLibrary";
import {toast} from "react-toastify";
export default function Styles(props) {
    const {styles,contest} = props;
    let base_url_for_css = "//cdn-rf3.rewardsfuel.com";
    const previewTheme = (event=false,themeId=false) => {
        if(event) {
            themeId = String(event.target.dataset.theme);
            toast("previewing theme ");
        }
        let theme = styles.find((theme) => String(theme.id) === themeId);
        let preview_url = base_url_for_css+theme.url;
        let topic = 'set-preview-style';
        if (props.type === "colors")
            topic = 'set-preview-color';
        send_to_preview(topic, {url: preview_url});
    }
    const setTheme = async (event) => {
        let themeId = String(event.target.dataset.theme);
        let theme = styles.find((theme) => String(theme.id) === themeId);
        //setContest({...contest, theme: theme});
        previewTheme(false,themeId);//sets colors right away
        let url = '/contest/set_theme/'
        let data = {
            contest_id: contest.contest_id,
            theme_id: themeId,
            type: props.type,
            url: theme.url
        };
        let result = await req("POST", url, data);
        if(result)
            toast("Theme updated");
        else
            toast("Error updating theme");
    }

    const columns = [
        {
            field: 'name', headerName: 'Style', flex: .3,
            renderCell: (value) => {
                return (<>
                    {value.row.image &&<img title={value.row.description} className={'style-preview-img'} src={value.row.image}/> }
                    {value.value}

                </>);
            }
        },
        {field: 'description', headerName: 'Action', flex: .7,
            renderCell: (value) => {
                return (<div className={'style-preview-buttons'}>
                    <Button data-theme={value.row.id} onClick={previewTheme} variant={"text"}>Preview</Button>
                    <Button data-theme={value.row.id} onClick={setTheme} variant={"text"}>Save to contest <ArrowForwardIosIcon/></Button></div>
            );
            }},
    ]

    return (<>
        <Box sx={{height: 400, width: '100%'}}>
            <DataGrid
                onRowHover={previewTheme}
                rows={styles}
                columns={columns}
            />
        </Box>
    </>);
}

import Button from "@mui/material/Button";
import {req} from "../../CommonLibrary";
import HomePagePropertiesTable from "./HomePagePropertiesTable";
import {useEffect, useState} from "react";
import {isArray} from "lodash";

export default function HomePage(){
    const [properties,setProperties] = useState([]);
    const create_contest = async ()=>{
        let p = await req("POST",`/home/create_contest/`);
        if(isArray(p) && p.length > 0) {
            p = p.map((property) => {
                return {...property, id: property.contest_id}
            });
            setProperties(p);
        }
    }
    const get_properties = async () => {
        let p = await req("GET", `/home/get_properties/`);
        if(isArray(p) && p.length > 0) {
            p = p.map((property) => {
                return {...property, id: property.contest_id}
            });
            setProperties(p);
        }
    }
    let mounted = false;
    useEffect(() => {
        if(!mounted) {
            get_properties();
            mounted = true;
            document.title = "Rewards Fuel home page";
        }
    }, []);
    return (
        <div className={'container'}>
            <h1>Home Page</h1>
            <Button type={'button'} onClick={create_contest} variant={'outlined'} color={'primary'}>Create new contest</Button>
            <HomePagePropertiesTable get_properties={get_properties} properties={properties} setProperties={setProperties} />
        </div>
    )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CreditCard from './CreditCard';
import PayPal from './PayPal';
import Crypto from './Crypto';
export default function PaymentsModal(props) {
    const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
    const [paymentPlan, setPaymentPlan] = React.useState(0);
    const [paymentOption, setPaymentOption] = React.useState('0');//swap visa to paypal to bitcoin
    
  
    //creates a modal that displays the payment options
    //on click on link with class payment-modal-open, the modal is opened
    //on click on link with class payment-modal-close, the modal is closed
    //popup will need a few payment options and will be populated with plan optins from data-plan attribute
    const handleTabChange = (event, newValue) => {
        setPaymentOption(newValue);
    };
    const handleClose = () => setPaymentModalOpen(false);
    let paymentModalMounted = false;
    React.useEffect(() => {
        if (paymentModalMounted)
            return;
        paymentModalMounted = true;
        document.addEventListener('click', function (e) {
            if (!e.target.classList.contains('payment-modal-open'))
                return;
            e.preventDefault();
            setPaymentPlan(e.target.getAttribute('data-plan'));//used to populate the modal with the plan options
            setPaymentModalOpen(true);
        });

    }, []);
    return (<>
        <Modal
            open={paymentModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='payment-modal'>
                <Button color="secondary" className="float-right payment-modal-close" onClick={handleClose}>
                    <HighlightOffIcon />
                </Button>
                <div className="payment-modal-plan-area">
                    <h3>Upgrade to {paymentPlan}</h3>
                    <div>Plan description and image</div>
                </div>
                <div className="payment-modal-payment-area">

                    <TabContext value={paymentOption}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList centered onChange={handleTabChange} aria-label="Select your payment method">
                                <Tab label="Credit card" value="0" />
                                <Tab label="PayPal" value="1" />
                                <Tab label="Crypto" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="0">
                           <CreditCard/>
                        </TabPanel>
                        <TabPanel value="1">
                            <PayPal/>
                        </TabPanel>
                        <TabPanel value="2">
                            <Crypto/>
                        </TabPanel>
                    </TabContext>

                </div>
            </Box>
        </Modal>
    </>);
}

import React,{useState} from "react";
import Button from "@mui/material/Button";
import {useEffect} from "react";


export default function AddEntryMethods(props) {
    let {entry_types,show_entry_method_editor} = props;

    const [in_page_entry_types, set_in_page_entry_types] = useState([]);//array that holds the add buttons
    const add_entry_types_to_page=()=> {
        //todo add sort order and segmenting
        let entry_type_list = entry_types.map((elm, ind) => {
            return <Button key={ind} variant="outlined"  className={'w-100 mb-2'} onClick={() => {
                show_entry_method_editor(elm)
            }}>Add {elm.name}</Button>;
        });
        set_in_page_entry_types(entry_type_list);
    }

    let entryPanelMounted = false
    useEffect(() => {
        if (entryPanelMounted)
            return;
        entryPanelMounted = true;
        add_entry_types_to_page();
    }, []);

    useEffect(() => {
        add_entry_types_to_page()
    }, [entry_types]);
    return (<>{in_page_entry_types}</>);
}

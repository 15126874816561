import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState } from "react";

export default function EntryMethodReport(props) {

    const { entries, contest } = props;
    const entryMethods = contest.entry_methods;
    const [rows, setRows] = useState([]);
    let mounted = false
    useEffect(() => {
        if (mounted) return;
        mounted = true;
        if (!entries)
            return;
        let formattedData = formatData(entryMethods, entries);
        setRows(formattedData);
    }, []);
    if (!entries?.length)
        return <div>No entries</div>;
    const formatData = (entryMethods, entries) => {

        try {
            console.log("formatData", entryMethods, entries);
            // Create an object with entry method IDs as keys
            // and the values being objects with properties 'entryMethodName', 'entries', 'totalPoints', and 'lastEntry'
            const output = entryMethods.reduce((acc, entryMethod) => {
                const {
                    entry_method_id,
                    button_label: entryMethodName,
                    entry_points: entryMethodPoints,
                    sort_order,
                } = entryMethod;
                acc[entry_method_id] = {
                    id: entry_method_id,
                    entryMethodName: entryMethodName || "Deleted entry method",
                    entries: 0,
                    totalPoints: 0,
                    lastEntry: null,
                    sort_order,
                    entryMethod,
                };
                return acc;
            }, {});

            // Loop through each entry and update the corresponding entry method in the output object
            if (!entries?.length) return;
            entries.forEach((entry) => {
                console.log("entry", entry);
                try {
                    const { entry_method_id, entry_points: entryPoints } = entry;
                    const { entryMethodName, entries, entryMethod, lastEntry, sort_order } = output[entry_method_id];
                    output[entry_method_id] = {
                        id: entry_method_id,
                        entryMethodName,
                        entries: entries + 1,
                        sort_order: sort_order,
                        totalPoints: (entries + 1) * entryPoints,
                    };
                } catch (e) {
                    console.log("Error in formatData", entryMethods, e);
                }
            });
            return Object.values(output).sort((a, b) => {
                const entryMethodA = entryMethods.find((entryMethod) => entryMethod.entry_method_id === a.id);
                const entryMethodB = entryMethods.find((entryMethod) => entryMethod.entry_method_id === b.id);
                return entryMethodA.sort_order - entryMethodB.sort_order;
            });
        } catch (e) {
            console.log("Error in formatData", entryMethods, e);
        }
    };


    const columns = [
        { field: 'entryMethodName', headerName: 'Entry method', minWidth: 70, flex: .6 },
        { field: 'entries', headerName: 'Entries', minWidth: 70, flex: .2 },
        { field: 'totalPoints', headerName: 'Points', minWidth: 70, flex: .2 },
    ];

    try {


        //console.log("EntryMethodReport lengthlengthlength", entries.length);
        return (
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}

                />
            </div>);
    } catch (e) {
        console.log("Error in EntryMethodReport", e);
    }
}

import React from 'react';
import ContestTimer from "../../settings/ContestTimer";

export function Countdown(props) {
    const {contest, setContest} = props;
    return (
        <>
            <ContestTimer contest={contest} setContest={setContest}/>
        </>);
}

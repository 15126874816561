import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

export default function Devices(props) {
    const {device} = props;

    const formatMobileDesktop = (params) => {
        let mobileDesktop = params.row.deviceCategory;
        if (mobileDesktop === 'desktop')
            return <span title="Desktop/Laptop"><ComputerIcon/></span>
        return <span title="Mobile"><SmartphoneIcon/></span>

    }
    const columns = [
        {field: 'deviceCategory', headerName: 'Mobile', minWidth: 70, flex: .1, renderCell: formatMobileDesktop},
        {field: 'operatingSystemWithVersion', headerName: 'OS', minWidth: 70, flex: .3},
        {field: 'browser', headerName: 'Browser', minWidth: 70, flex: .2},
        {field: 'screenResolution', headerName: 'Resolution', minWidth: 70, flex: .2},
        {field: 'count', headerName: 'Contestants', minWidth: 70, flex: .2},
    ];

    let rows = device.map((elm, index) => {
        //todo add in Facebook multiple urls etc
        return {
            id: index,
            ...elm
        }
    });

    return (
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}

            />
        </div>
    );

}
